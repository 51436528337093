import {
  Box,
  Button,
  InputAdornment,
  TextField,
  Typography
} from "@mui/material";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { createPasswordResetLink } from "../../API/customer";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import Logo from "../assets/logo.svg";

function ForgotPassword({ setFeedback }) {
  const navigate = useNavigate();

  const validate = Yup.object({
    email: Yup.string().email("Email is invalid").required("Email is required")
  });

  function onRequestResetLink(values) {
    createPasswordResetLink(values.email)
      .then((response) => {
        navigate("/email-sent", {
          state: {
            email: values.email
          }
        });
      })
      .catch((error) => {
        setFeedback({
          message: "Something went wrong",
          severity: "error",
          state: true
        });
      });
  }

  const formik = useFormik({
    initialValues: {
      email: ""
    },
    validationSchema: validate,
    onSubmit: (values, { resetForm }) => {
      onRequestResetLink(values, resetForm);
    }
  });

  return (
    <div className="auth-form" style={{ paddingRight: 50 }}>
      <img className="logo" src={Logo} />
      <br />
      <br />
      <h1 className="auth-heading">Reset Password</h1>

      <div className="auth-subheading">
        Please enter your email address. You will receive an email message with
        instructions on how to reset your password.
      </div>
      <br />
      <form className="form" onSubmit={formik.handleSubmit}>
        <TextField
          fullWidth
          required
          className="input-field"
          placeholder="johndoe@company.com"
          name="email"
          variant="outlined"
          type="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PermIdentityIcon />
              </InputAdornment>
            ),
            style: {
              fontSize: "16px"
            }
          }}
          InputLabelProps={{
            style: { color: "#848484", fontSize: "20px" }
          }}
        />
        <br />
        <Button
          className="submit-button"
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
        >
          Reset Password
        </Button>
      </form>
      <br />
      <div style={{ alignItems: "flex-start" }} className="form">
        <Button onClick={() => navigate("/login")}>
          <Box className="btns-with-icon">
            <KeyboardBackspaceIcon sx={{ fontSize: "18px" }} />
            <Typography className="btns-with-icon-text">Back to Login </Typography>
          </Box>
        </Button>
      </div>
    </div>
  );
}

export default ForgotPassword;

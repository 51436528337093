import React, { useState, useEffect } from "react";
import { Button, DialogActions, DialogContent } from "@mui/material";
import "./form-shimmer.css";

function GroupFormShimmering() {
  return (
    <div>
      <form>
        <br />
        <br />
        <DialogContent className="content">
          <label htmlFor="name" className="shimmer-label"></label>
          <div className="form-shimmer-fields" />
          <br />
          <br />
          <div className="form-shimmer-fields" />
        </DialogContent>
        <DialogActions className="actions">
          <Button className="shimmer-button"></Button>
          <Button className="shimmer-button"></Button>
        </DialogActions>
      </form>
    </div>
  );
}
export default GroupFormShimmering;

import { Outlet } from "react-router";
import Login from "../assets/login.svg";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

export default function AuthContainer() {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  return isLoggedIn ? (
    <Navigate to="/dashboard" />
  ) : (
    <div className="outer-container">
      <div className="login-container">
        <h2
          className="p-4 pt-2"
          style={{ marginTop: "30px", fontSize: "26px" }}
        >
          Securing Data At Every Turn
        </h2>
        <img src={Login} className="login-image" />
      </div>
      <div style={{ flex: "1" }}>
        <Outlet />
      </div>
    </div>
  );
}

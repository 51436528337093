import { useState } from "react";
import { Grid, IconButton, Tooltip } from "@mui/material";
import GreenCircle from "../../assets/icons/GreenCircle";
import RedCircle from "../../assets/icons/RedCircle";
import EditIcon from "@mui/icons-material/Edit";
import { calculateLastActive } from "../../../utils/Helpers";
import DeviceForm from "./DeviceForm";
import WindowsIcon from "../../assets/icons/WindowsIcon";
import { Visibility } from "@mui/icons-material";
import CustomDialog from "../../utility/Dialog";
import { DIALOG_TYPES, MESSAGES } from "../../../utils/Constants";
import { getDevicePassword } from "../../../API/devices";
import InfoIcon from "@mui/icons-material/InfoOutlined";

export default function DeviceGeneralInfo({
  deviceData,
  setFeedback,
  setDeviceData
}) {
  const [openDeviceForm, setOpenDeviceForm] = useState(false);
  const [
    isEnterCustomerPasswordDialogOpen,
    setIsEnterCustomerPasswordDialogOpen
  ] = useState(false);
  const [customerPassword, setCustomerPassword] = useState(null);
  const [devicePassword, setDevicePassword] = useState("*******");

  const handleEdit = () => {
    setOpenDeviceForm(true);
  };

  const handlePasswordView = () => {
    setIsEnterCustomerPasswordDialogOpen(true);
  };

  const handleSubmitCustomerPassword = () => {
    getDevicePassword(deviceData.id, customerPassword)
      .then(({ data }) => {
        if (data.length === 0) setDevicePassword("No password to display");
        else setDevicePassword(data);
        setIsEnterCustomerPasswordDialogOpen(false);
        setCustomerPassword("");
      })
      .catch((error) => {
        setFeedback({
          severity: "error",
          message: error.response.data || MESSAGES.GENERIC_EXCEPTION_MESSAGE,
          state: true
        });
      });
  };

  function isActive(date) {
    date = new Date(date);
    let currentDate = new Date();
    if (currentDate - date > 2 * 60000) {
      return false;
    }
    return true;
  }

  return (
    <>
      <Grid
        container
        columns={24}
        spacing={2}
        className="device-profile-header"
      >
        <Grid item md={4} className="device-profile-icon">
          <WindowsIcon />
        </Grid>
        <Grid item md={10} className="device-profile-detail">
          <div>
            <span>Display Name : </span>{" "}
            {deviceData.displayName ? deviceData.displayName : "--"}
            <IconButton onClick={handleEdit} size="small">
              <EditIcon fontSize="small" color="primary" />
            </IconButton>
          </div>
          <div>
            <span>Device Name : </span> {deviceData.deviceName}
          </div>
          <div>
            <span>Device Id : </span> {deviceData.id}
          </div>
          <div>
            <span>Password : </span> {devicePassword}
            <IconButton onClick={handlePasswordView} size="small">
              <Visibility fontSize="small" color="primary" />
            </IconButton>
            <Tooltip
              title="Displays last remotely changed password."
              placement="right"
            >
              <InfoIcon fontSize="small" />
            </Tooltip>
          </div>
        </Grid>
        <Grid item md={10} className="device-profile-detail">
          <div className="device-status">
            <span>Status : </span>
            {isActive(deviceData.lastResponse + "Z") ? (
              <>
                <GreenCircle />
                &nbsp;Active
              </>
            ) : (
              <>
                <RedCircle />
                &nbsp;Inactive &nbsp;(Last active{" "}
                {calculateLastActive(deviceData.lastResponse + "Z")})
              </>
            )}
          </div>
          <div>
            <span>Agent Version : </span> {deviceData.agentVersion}
          </div>
          <div>
            <span>Operating System : </span> {deviceData.operatingSystem}
          </div>
        </Grid>
      </Grid>
      <DeviceForm
        open={openDeviceForm}
        setOpen={setOpenDeviceForm}
        setFeedback={setFeedback}
        deviceData={deviceData}
        setDeviceData={setDeviceData}
      />
      <CustomDialog
        open={isEnterCustomerPasswordDialogOpen}
        setOpen={setIsEnterCustomerPasswordDialogOpen}
        title="Enter Your Password"
        content="Please enter your password to reverify your identity"
        handleConfirm={handleSubmitCustomerPassword}
        dialogType={DIALOG_TYPES.INPUT}
        inputField={customerPassword}
        setInputField={setCustomerPassword}
      />
    </>
  );
}

export default function Office365Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 40 40"
      width="30px"
      height="30px"
      baseProfile="basic"
    >
      <path
        fill="#fff"
        d="M16.574,1.648l-0.197,0.119c-0.313,0.189-0.607,0.401-0.882,0.632L16.062,2h4.813l0.875,6.626	L17.375,13L13,16.041v3.507c0,2.449,1.28,4.72,3.375,5.988l4.606,2.788L11.25,34H9.373l-3.498-2.117	C3.78,30.615,2.5,28.344,2.5,25.895V14.103c0-2.45,1.281-4.722,3.377-5.99l10.5-6.351C16.442,1.723,16.508,1.685,16.574,1.648z"
      />
      <path d="M11.385,34.5H9.233l-3.617-2.19C3.386,30.96,2,28.502,2,25.895V14.103c0-2.608,1.387-5.067,3.618-6.417l10.708-6.473	L16.494,1.5h4.819l0.965,7.305l-4.55,4.549L13.5,16.302v3.246c0,2.26,1.201,4.39,3.134,5.561l5.326,3.223L11.385,34.5z M9.513,33.5	h1.603l8.888-5.185l-3.887-2.352c-2.23-1.35-3.616-3.809-3.616-6.416V15.78l4.59-3.19l4.132-4.143L20.437,2.5H16.22l-0.437,0.308	l-0.054-0.069L6.136,8.542C4.201,9.711,3,11.842,3,14.103v11.792c0,2.26,1.201,4.39,3.134,5.561L9.513,33.5z" />
      <path
        fill="#8bb7f0"
        d="M16.574,1.648l-0.197,0.119c-0.313,0.189-0.607,0.401-0.882,0.632L16.062,2h4.813l0.875,6.626	L17.375,13L13,16.041v3.507c0,2.449,1.28,4.72,3.375,5.988l4.606,2.788L11.25,34H9.373l-3.498-2.117	C3.78,30.615,2.5,28.344,2.5,25.895V14.103c0-2.45,1.281-4.722,3.377-5.99l10.5-6.351C16.442,1.723,16.508,1.685,16.574,1.648z"
      />
      <path
        fill="#4e7ab5"
        d="M11.385,34.5H9.233l-3.617-2.19C3.386,30.96,2,28.502,2,25.895V14.103	c0-2.608,1.387-5.067,3.618-6.417l10.708-6.473L16.494,1.5h4.819l0.965,7.305l-4.55,4.549L13.5,16.302v3.246	c0,2.26,1.201,4.39,3.134,5.561l5.326,3.223L11.385,34.5z M9.513,33.5h1.603l8.888-5.185l-3.887-2.352	c-2.23-1.35-3.616-3.809-3.616-6.416V15.78l4.59-3.19l4.132-4.143L20.437,2.5H16.22l-0.437,0.308l-0.054-0.069L6.136,8.542	C4.201,9.711,3,11.842,3,14.103v11.792c0,2.26,1.201,4.39,3.134,5.561L9.513,33.5z"
      />
      <path
        fill="#dcd5f2"
        d="M27,15.625v3.92c0,2.449-1.28,4.72-3.375,5.988l-10.5,6.356c-2.148,1.3-4.82,1.347-7.006,0.141	l10.257,6.208c2.228,1.349,5.021,1.349,7.25,0l10.5-6.356c2.095-1.268,3.375-3.539,3.375-5.988v-2.832l-0.875-1.312L27,15.625z"
      />
      <path
        fill="#8b75a1"
        d="M20,39.754c-1.343,0-2.687-0.362-3.884-1.087L5.859,32.458l0.5-0.866	c2.029,1.119,4.522,1.07,6.507-0.13l10.5-6.356c1.933-1.17,3.134-3.301,3.134-5.561v-4.831l10.541,6.758L38,22.911v2.983	c0,2.607-1.386,5.066-3.616,6.416l-10.5,6.356C22.687,39.392,21.343,39.754,20,39.754z M9.341,33.396l7.293,4.415	c2.076,1.256,4.656,1.257,6.732,0l10.5-6.356C35.799,30.285,37,28.154,37,25.895v-2.681l-0.732-1.099l-8.768-5.58v3.01	c0,2.607-1.386,5.066-3.616,6.416l-10.5,6.356C12.148,33.064,10.745,33.425,9.341,33.396z"
      />
      <path
        fill="#dcd5f2"
        d="M27,15.625v3.92c0,2.449-1.28,4.72-3.375,5.988l-10.5,6.356c-2.148,1.3-4.82,1.347-7.006,0.141	l10.257,6.208c2.228,1.349,5.021,1.349,7.25,0l10.5-6.356c2.095-1.268,3.375-3.539,3.375-5.988v-2.832l-0.875-1.312L27,15.625z"
      />
      <path
        fill="#8b75a1"
        d="M20,39.754c-1.343,0-2.687-0.362-3.884-1.087L5.859,32.458l0.5-0.866	c2.029,1.119,4.522,1.07,6.507-0.13l10.5-6.356c1.933-1.17,3.134-3.301,3.134-5.561v-4.831l10.541,6.758L38,22.911v2.983	c0,2.607-1.386,5.066-3.616,6.416l-10.5,6.356C22.687,39.392,21.343,39.754,20,39.754z M9.341,33.396l7.293,4.415	c2.076,1.256,4.656,1.257,6.732,0l10.5-6.356C35.799,30.285,37,28.154,37,25.895v-2.681l-0.732-1.099l-8.768-5.58v3.01	c0,2.607-1.386,5.066-3.616,6.416l-10.5,6.356C12.148,33.064,10.745,33.425,9.341,33.396z"
      />
      <path
        fill="#c2e8ff"
        d="M34.123,8.114l-10.5-6.351c-2.158-1.306-4.846-1.346-7.037-0.121l-0.209,0.126	C14.281,3.035,13,5.307,13,7.757v8.298l3.377-2.043c2.227-1.347,5.018-1.347,7.246,0l10.5,6.351c2.031,1.228,3.296,3.399,3.373,5.76	c0.003-0.076,0.004-0.153,0.004-0.229V14.103C37.5,11.653,36.219,9.381,34.123,8.114z"
      />
      <path
        fill="#7496c4"
        d="M37.996,26.143l-1-0.003c-0.072-2.206-1.243-4.206-3.132-5.349l-10.5-6.351	c-2.074-1.255-4.652-1.255-6.729,0L12.5,16.942V7.757c0-2.608,1.387-5.067,3.618-6.417l0.21-0.126	c2.36-1.321,5.25-1.272,7.554,0.122l10.5,6.351C36.613,9.036,38,11.495,38,14.103L37.996,26.143z M20,12.499	c1.343,0,2.685,0.362,3.882,1.086l10.5,6.351c1.104,0.668,1.996,1.59,2.618,2.669v-8.501c0-2.26-1.201-4.392-3.136-5.562	l-10.5-6.351c-1.995-1.208-4.501-1.251-6.534-0.112l-0.195,0.118C14.701,3.365,13.5,5.497,13.5,7.757v7.412l2.618-1.584	C17.315,12.86,18.657,12.499,20,12.499z"
      />
    </svg>
  );
}

import { TableCell, styled } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import "./table.css";

export default function RowExpandButton({
  openRows,
  setOpenRows,
  recordId,
  data
}) {
  const CollapsibleTableCell = styled(TableCell)({
    cursor: "pointer"
  });

  function toggleRow(rowId) {
    setOpenRows((prevOpenRows) => {
      if (prevOpenRows.includes(rowId)) {
        return prevOpenRows.filter((id) => id !== rowId);
      } else {
        return [...prevOpenRows, rowId];
      }
    });
  }

  function handleExpandOrCollapseAllRows() {
    data.length === openRows.length
      ? setOpenRows([])
      : setOpenRows(data.map((row) => row.id));
  }

  return (
    <CollapsibleTableCell
      sx={{ borderBottom: 0, width: 15 }}
      onClick={() =>
        recordId !== undefined
          ? toggleRow(recordId)
          : handleExpandOrCollapseAllRows()
      }
    >
      {recordId !== undefined ? (
        openRows.includes(recordId) ? (
          <KeyboardArrowUpIcon />
        ) : (
          <KeyboardArrowDownIcon />
        )
      ) : data.length === openRows.length ? (
        <KeyboardArrowUpIcon />
      ) : (
        <KeyboardArrowDownIcon />
      )}
    </CollapsibleTableCell>
  );
}

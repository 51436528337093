import React, { useState, useEffect } from "react";
import { fetchApplications } from "../../../API/devices";
import TableComponent from "../../utility/Table";
import SearchBar from "../../utility/SearchBar";  
import Pagination from "../../utility/Pagination";  

export default function DeviceInstalledApplicationTab({ deviceId, setFeedback }) {
  const [applicationList, setApplicationList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    fetchApplications(deviceId)
      .then(({ data }) => {
        setApplicationList([...data.allowedApps, ...data.blockedApps]);
        setTotalCount(data.totalCount);
      })
      
      .catch((error) => {
        setFeedback({
          severity: "error",
          message: "There is an issue while fetching the application list.",
          state: true
        });
      });
  }, [deviceId]);

  const filteredList = applicationList.filter(app =>
    app.appName.toLowerCase().includes(searchText.toLowerCase())
  );

  const startIndex = pageNumber * pageSize;
  const endIndex = startIndex + pageSize;
  const paginatedList = filteredList.slice(startIndex, endIndex);

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between", marginBottom: '10px' }}>
        <SearchBar
          setSearchText={setSearchText}
          searchText={searchText}
          placeholder={"Search Applications"}
        />
      </div>
      <TableComponent 
        headings={["appName"]}
        data={paginatedList}
        customColumnNames={{
          appName: "Application List"
        }}
      />
      {totalCount !== 0 && (
        <div className="pagination" style={{ marginTop: '10px' }}>
          <Pagination
            totalCount={filteredList.length} 
            page={pageNumber}
            setPage={setPageNumber}
            rowsPerPage={pageSize}
            setRowsPerPage={setPageSize}
          />
        </div>
      )}
    </div>
  );
}

export default function DashboardIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path d="M3 6H10" stroke="#303030" />
      <path d="M3 15.5H10" stroke="#303030" />
      <path d="M14.0161 5.66826L15.8681 7.34755L21 3" stroke="#303030" />
      <path d="M14 13.5L19 18.5" stroke="#303030" />
      <path d="M19 13.5L13.5 18.5" stroke="#303030" />
    </svg>
  );
}

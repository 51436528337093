import { useState } from "react";
import PolicyList from "./PolicyList";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import CustomTabPanel from "../other/CustomTabPanel";
import PageTitle from "../utility/PageTitle";
import AppBlockingCategories from "../application_blocking/AppCategoryList";
import OrganizationalApps from "../application_blocking/ApplicationList";

function props(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}
function DesktopAppPolicy({ setFeedback }) {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <Box sx={{ width: "100%" }}>
        <PageTitle 
        subheading="Manage Application Categories and Restrict Use of Unwanted Applications in Your Organization"
        />
        <br />
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs">
            <Tab label="All Applications" {...props(0)} />
            <Tab label="Application Category" {...props(1)} />
            <Tab label="Policy" {...props(2)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <OrganizationalApps setFeedback={setFeedback} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <AppBlockingCategories setFeedback={setFeedback} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <PolicyList setFeedback={setFeedback} />
        </CustomTabPanel>
      </Box>
    </div>
  );
}

export default DesktopAppPolicy;

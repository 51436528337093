import "./App.css";
import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from "react-router-dom";
import { useDispatch } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import { isUserLoggedIn } from "./services/index";
import { DrawerAndNavigationBar } from "./components/other";
import {
  Signup,
  ForgotPasswordPage,
  LoginPage,
  EmailSentPage,
  ChangePassword
} from "./components/auth";
import ProtectedRoute from "./components/other/ProtectedRoute";
import { useState } from "react";
import Feedback from "./components/utility/Feedback";
import ClassificationList from "./components/data_classification/ClassificationList";
import PolicyList from "./components/policy/PolicyList";
import GroupList from "./components/groups/GroupList";
import DictionaryRules from "./components/rule/DictionaryRules";
import Dashboard from "./components/dashboard/Dashboard";
import UserList from "./components/end_user/UserList";
import DownloadModulePage from "./components/DownloadModulePage";
import DevicePage from "./components/device/DevicePage";
import AdForm from "./components/user_store/active_directory/AdForm";
import AuthContainer from "./components/auth/AuthContainer";
import PasswordConfiguration from "./components/password_configuration/Configuration";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import ReportingList from "./components/reporting/ReportingList";
import UserGroupList from "./components/groups/UserGroupList";
import DeviceGroupList from "./components/groups/DeviceGroupList";
import EmailReportList from "./components/reporting/EmailReportList";
import DeviceReportList from "./components/reporting/DeviceReportList";
import ApprovalRequestList from "./components/approval_requests/ApprovalRequests";
import AccountPage from "./components/AccountPage";
import SettingsPage from "./components/settings/SettingsPage";
import SmtpSettings from "./components/configurations/SmtpSettings";
import EmailTemplateEditorPage from "./components/email_templates/EmailTemplateEditorPage";
import HttpPolicy from "./components/policy/http_policy/HttpPolicy";
import UsbPolicy from "./components/policy/UsbPolicy";
import PolicyBreachAlertList from "./components/alerts/PolicyBreachAlertList";
import UsbReportList from "./components/reporting_dashboard/UsbReporting";
import HttpReportList from "./components/reporting_dashboard/HttpReporting";
import DesktopAppReportList from "./components/reporting_dashboard/DesktopAppReporting";
import EthernetReportList from "./components/reporting_dashboard/EthernetReporting";
import DeviceMonitoring from "./components/reporting_dashboard/device_monitoring/DeviceMonitoring";
import DesktopAppPolicy from "./components/policy/DesktopAppPolicy";
import OrganizationalDomains from "./components/organizational_domains/OrganizationalDomainList";
import CircularLoader from "./components/assets/CircularLoader";
import PageNotFound from "./components/other/PageNotFound";
import BulkReport from "./components/bulk_upload/BulkReport";
import AgentStatusReport from "./components/reporting_dashboard/AgentStatusReport";
import PrinterReportList from "./components/reporting_dashboard/PrinterReporting";

function App() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const theme = createTheme({
    typography: {
      allVariants: {
        fontFamily: '"DM Sans", sans-serif',
        textTransform: "none",
        fontSize: "14px",
        fontWeight: "500"
      }
    },
    palette: {
      text: {
        secondary: "black"
      }
    }
  });
  const [feedback, setFeedback] = useState({
    severity: "error",
    message: "",
    state: false
  });

  useEffect(() => {
    dispatch(isUserLoggedIn()).then((result) => {
      setLoading(false);
    });
  }, [dispatch]);

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        {loading ? (
          <CircularLoader />
        ) : (
          <Router>
            <Feedback feedback={feedback} setFeedback={setFeedback} />

            <Routes>
              <Route element={<AuthContainer />}>
                <Route
                  path="/login"
                  element={<LoginPage setFeedback={setFeedback} />}
                />
                <Route
                  path="/signup"
                  element={<Signup setFeedback={setFeedback} />}
                />
                <Route
                  path="/forgot-password"
                  element={<ForgotPasswordPage setFeedback={setFeedback} />}
                />
                <Route
                  path="/email-sent"
                  element={<EmailSentPage setFeedback={setFeedback} />}
                />
                <Route
                  path="/customer/:customerId/reset-password/:token"
                  element={<ChangePassword setFeedback={setFeedback} />}
                />
              </Route>
              <Route
                element={
                  <ProtectedRoute>
                    <DrawerAndNavigationBar />
                  </ProtectedRoute>
                }
              >
                <Route path="/" element={<Navigate to="/dashboard" />} />
                <Route
                  path="/my-account"
                  element={<AccountPage setFeedback={setFeedback} />}
                />
                <Route
                  path="/view-users"
                  element={<UserList setFeedback={setFeedback} />}
                />
                <Route
                  path="/view-devices"
                  element={<DevicePage setFeedback={setFeedback} />}
                />
                <Route
                  path="/ad-details"
                  element={<AdForm setFeedback={setFeedback} />}
                />
                <Route path="/download" element={<DownloadModulePage />} />
                <Route
                  path="/classification-list"
                  element={<ClassificationList setFeedback={setFeedback} />}
                />
                <Route
                  path="/rule-list"
                  element={<DictionaryRules setFeedback={setFeedback} />}
                />
                <Route
                  path="/policy-list"
                  element={<PolicyList setFeedback={setFeedback} />}
                />
                <Route
                  path="/groups"
                  element={<GroupList setFeedback={setFeedback} />}
                />
                <Route
                  path="/user-groups"
                  element={<UserGroupList setFeedback={setFeedback} />}
                />
                <Route
                  path="/device-groups"
                  element={<DeviceGroupList setFeedback={setFeedback} />}
                />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route
                  path="/password-configuration"
                  element={<PasswordConfiguration setFeedback={setFeedback} />}
                />
                <Route
                  path="/usb-policy"
                  element={<UsbPolicy setFeedback={setFeedback} />}
                />
                <Route
                  path="/printer-policy"
                  element={<UsbPolicy setFeedback={setFeedback} />}
                />
                <Route
                  path="/ethernet-policy"
                  element={<PolicyList setFeedback={setFeedback} />}
                />
                <Route
                  path="/email-policy"
                  element={<PolicyList setFeedback={setFeedback} />}
                />
                <Route
                  path="/clipboard-policy"
                  element={<PolicyList setFeedback={setFeedback} />}
                />
                <Route
                  path="/screenshot-policy"
                  element={<PolicyList setFeedback={setFeedback} />}
                />
                <Route
                  path="/http-policy"
                  element={<HttpPolicy setFeedback={setFeedback} />}
                />
                <Route
                  path="/app-blocking-policy"
                  element={<DesktopAppPolicy setFeedback={setFeedback} />}
                />
                <Route path="/reporting-list" element={<ReportingList />} />
                <Route
                  path="/reporting/email-reports"
                  element={<EmailReportList />}
                />
                <Route
                  path="/smtp-settings"
                  element={<SmtpSettings setFeedback={setFeedback} />}
                />
                <Route path="/device-reports" element={<DeviceReportList />} />
                <Route
                  path="/approval-requests"
                  element={<ApprovalRequestList setFeedback={setFeedback} />}
                />
                <Route
                  path="/organizational-domains"
                  element={<OrganizationalDomains setFeedback={setFeedback} />}
                />
                <Route
                  path="/settings"
                  element={<SettingsPage setFeedback={setFeedback} />}
                />
                <Route
                  path="/edit-email-template"
                  element={
                    <EmailTemplateEditorPage setFeedback={setFeedback} />
                  }
                />
                <Route
                  path="/policy-breach-alert"
                  element={<PolicyBreachAlertList setFeedback={setFeedback} />}
                />
                <Route
                  path="/reporting/bulk-report"
                  element={<BulkReport setFeedback={setFeedback} />}
                />
                <Route
                  path="/reporting/usb-reporting"
                  element={<UsbReportList />}
                />
                <Route
                  path="/reporting/printer-reporting"
                  element={<PrinterReportList />}
                />
                <Route
                  path="/reporting/http-reporting"
                  element={<HttpReportList />}
                />
                <Route
                  path="/reporting/desktop-apps-reporting"
                  element={<DesktopAppReportList />}
                />
                <Route
                  path="/reporting/ethernet-reporting"
                  element={<EthernetReportList />}
                />
                <Route
                  path="/reporting/device-monitoring"
                  element={<DeviceMonitoring setFeedback={setFeedback}/>}
                />
                <Route
                  path="/reporting/agent-status-report"
                  element={<AgentStatusReport setFeedback={setFeedback} />}
                />
              </Route>

              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </Router>
        )}
      </div>
    </ThemeProvider>
  );
}

export default App;

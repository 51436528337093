import * as React from "react";
import { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { DIALOG_TYPES } from "../../utils/Constants";
import { Checkbox, FormControlLabel, TextField } from "@mui/material";

export default function CustomDialog({
  open,
  setOpen,
  title,
  content,
  dialogType,
  handleConfirm,
  inputField,
  handleCleanup,
  setInputField,
  getAcknowledge
}) {
  const [isAcknowledged, setIsAcknowleged] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setIsAcknowleged(false);
    if (handleCleanup != undefined) {
      handleCleanup();
    }
  };

  return (
    <React.Fragment>
      <Dialog
        className="delete-component"
        open={open}
        onClose={handleClose}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
      >
        <DialogTitle id="dialog-title">
          <span className="dialog-heading">{title}</span>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            style={{ float: "right" }}
            size="small"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            className="dialog-content"
            id="alert-dialog-description"
          >
            {content}
          </DialogContentText>
          {dialogType === DIALOG_TYPES.INPUT ? (
            <>
              <br />
              <TextField
                size="small"
                id="name"
                type="password"
                placeholder="Password"
                className="form-textfield"
                fullWidth
                name="name"
                value={inputField}
                onChange={(e) => setInputField(e.target.value)}
              />
            </>
          ) : null}
          {getAcknowledge ? (
            <FormControlLabel
              label="I acknowledge and confirm the action."
              control={
                <Checkbox
                  checked={isAcknowledged}
                  onChange={(event) => setIsAcknowleged(event.target.checked)}
                />
              }
            />
          ) : null}
        </DialogContent>
        <DialogActions>
          {dialogType === DIALOG_TYPES.ALERT ? (
            <Button onClick={handleClose} autoFocus>
              Ok
            </Button>
          ) : null}
          {dialogType === DIALOG_TYPES.CONFIRM ||
          dialogType === DIALOG_TYPES.INPUT ? (
            <>
              <Button
                onClick={handleClose}
                className="primary-button-outlined"
                variant="outlined"
                startIcon={<CloseIcon />}
              >
                Cancel
              </Button>
              <Button
                onClick={handleConfirm}
                className="primary-button-filled"
                variant="contained"
                startIcon={<CheckIcon />}
                disabled={getAcknowledge && !isAcknowledged}
              >
                Confirm
              </Button>
            </>
          ) : null}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

import React from "react";
import { makeStyles } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import * as Yup from "yup";

import { createCustomer } from "../../API/customer";
import { Link } from "react-router-dom";

function Signup({ setFeedback }) {
  const validate = Yup.object({
    email: Yup.string().email("Email is invalid").required("Email is required"),
    firstName: Yup.string()
      .max(15, "Must be 15 characters or less")
      .required("Required"),
    lastName: Yup.string()
      .max(20, "Must be 20 characters or less")
      .required("Required"),
    password: Yup.string()
      .min(6, "Password must be at least 6 charaters")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Password must match")
      .required("Confirm Password is required")
  });

  function submitForm(values, resetForm) {
    setFeedback({
      severity: "",
      message: "",
      state: false
    });

    createCustomer(values)
      .then((response) => {
        setFeedback({
          severity: "success",
          message: "User data Saved Successfully",
          state: true
        });
      })
      .catch(function (error) {
        setFeedback({
          severity: "error",
          message: error.response.data,
          state: true
        });
      });
  }

  const formik = useFormik({
    initialValues: {
      email: "",
      firstName: "",
      lastName: "",
      password: "",
      confirmPassword: "",
      orgName: ""
    },
    validationSchema: validate,
    onSubmit: (values, { resetForm }) => {
      submitForm(values, resetForm);
    }
  });

  let heading = "Sign Up";

  return (
    <div style={{ paddingTop: 100, flex: 1, overflow: "auto" }}>
      <form className="form" onSubmit={formik.handleSubmit}>
        <h2
          style={{ color: "black" }}
          className="my-4 font-weight-bold .display-4"
        >
          {heading}
        </h2>
        <TextField
          label="Email"
          name="email"
          variant="standard"
          type="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
        <TextField
          label="First Name"
          name="firstName"
          variant="standard"
          type="text"
          value={formik.values.firstName}
          onChange={formik.handleChange}
          error={formik.touched.firstName && Boolean(formik.errors.firstName)}
          helperText={formik.touched.firstName && formik.errors.firstName}
        />
        <TextField
          label="Last Name"
          name="lastName"
          variant="standard"
          type="text"
          value={formik.values.lastName}
          onChange={formik.handleChange}
          error={formik.touched.lastName && Boolean(formik.errors.lastName)}
          helperText={formik.touched.lastName && formik.errors.lastName}
        />
        <TextField
          label="Organization Name"
          name="orgName"
          variant="standard"
          type="text"
          value={formik.values.orgName}
          onChange={formik.handleChange}
          error={formik.touched.orgName && Boolean(formik.errors.orgName)}
          helperText={formik.touched.orgName && formik.errors.orgName}
        />
        <TextField
          label="Password"
          name="password"
          variant="standard"
          type="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
        />
        <TextField
          label="Confirm Password"
          name="confirmPassword"
          variant="standard"
          type="password"
          value={formik.values.confirmPassword}
          onChange={formik.handleChange}
          error={
            formik.touched.confirmPassword &&
            Boolean(formik.errors.confirmPassword)
          }
          helperText={
            formik.touched.confirmPassword && formik.errors.confirmPassword
          }
        />

        <div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div>Already have an account?</div>
            <Link to={"/login"} style={{ paddingLeft: 5 }}>
              Sign in
            </Link>
          </div>
          <button className="btn btn-dark mt-3" type="submit">
            Sign Up
          </button>
        </div>
      </form>
    </div>
  );
}

export default Signup;

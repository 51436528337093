import React from "react";
import "./table-shimmering.css";

const TableShimmering = () => {
  const renderStrips = () => {
    const strips = Array.from({ length: 6 }, (_, index) => (
      <div key={index} className="shimmering-strip"></div>
    ));
    return strips;
  };

  const renderColumns = () => {
    const columns = Array.from({ length: 3 }, (_, index) => (
      <div key={index} className="shimmering-column">
        {renderStrips()}
      </div>
    ));
    return columns;
  };

  return <div className="shimmering-columns-container">{renderColumns()}</div>;
};

export default TableShimmering;

import { properties } from "../properties";
import api from "../services/api";

function fetchAllSiteBlockingCategoriesByCustomerId() {
  return api.get(`${properties.adminService}/domain-category`);
}

function deleteDomainCategory(id) {
  return api.delete(`${properties.adminService}/domain-category/${id}`);
}

function fetchAllSiteBlockingCategoriesByPolicyId(policyId) {
  return api.get(
    `${properties.adminService}/domain-category?policyId=${policyId}`
  );
}

function fetchDomainCategory(id){
  return api.get(`${properties.adminService}/domain-category/${id}`)
}

function updateDomainCategory(id,values){
  return api.put(`${properties.adminService}/domain-category/${id}`, values);
}

function fetchSiteBlockingUrls(
  searchText = null,
  pageSize,
  pageNumber,
  isEnable = null,
  isDefault = null
) {
  let url = `${properties.adminService}/domain?pageSize=${pageSize}&pageNumber=${pageNumber}&`;
  if (isEnable != null && isEnable != "all") url += `isEnable=${isEnable}&`;
  if (isDefault != null && isDefault != "all") url += `isDefault=${isDefault}&`;
  if (searchText != null) url += `searchText=${searchText}`;
  return api.get(url);
}

function deleteSiteBlockingUrl(id) {
  return api.delete(`${properties.adminService}/domain/${id}`);
}

function updateIsEnabled(id, jsonPatch) {
  return api.patch(`${properties.adminService}/domain/${id}`, [
    jsonPatch
  ]);
}

function addSiteBlockingUrls(values) {
  return api.post(`${properties.adminService}/domain`, values);
}

function fetchAllUrls(){
  return api.get(`${properties.adminService}/domain/all`);
}

export {
  fetchAllSiteBlockingCategoriesByCustomerId,
  fetchAllSiteBlockingCategoriesByPolicyId,
  fetchSiteBlockingUrls,
  updateIsEnabled,
  deleteSiteBlockingUrl,
  addSiteBlockingUrls,
  deleteDomainCategory,
  fetchAllUrls,
  fetchDomainCategory,
  updateDomainCategory
};
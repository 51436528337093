import React from "react";
import ReportList from "./ReportList";
export default function PrinterReportList() {
  const headings = [
    "id",
    "deviceName",
    "incidentDttm",
    "policyName",
    "risk",
  ];
  const subheading = "Identify Devices Attempting Print Operations";
  return <ReportList headings={headings} destinationId={8} subheading={subheading}/>;
}
import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Button,
  Toolbar,
  IconButton,
  Stack,
  Divider,
  InputLabel,
  TextField
} from "@mui/material";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { addUser, fetchUser, updateUser } from "../../API/users";
import FormShimmering from "../utility/FormShimmer";
import "./user-list.css";
import CloseIcon from "@mui/icons-material/Close";
import BeenhereOutlinedIcon from "@mui/icons-material/BeenhereOutlined";
import AddIcon from "@mui/icons-material/Add";

function UserForm({
  setFeedback,
  setOpen,
  open,
  selectedUserId,
  setFetchCount,
  updateTableData
}) {
  let formType = selectedUserId ? "Update" : "Add";
  
  const [loading, setLoading] = useState(true);

  const user = useSelector(({ auth }) => auth.currentUser);

  useEffect(() => {
    setLoading(true);

    if (formType === "Update") {
      fetchUser(selectedUserId)
        .then((response) => {
          formik.setValues(response.data);
        })
        .catch((error) => console.log(error.response))
        .finally(() => {
          setLoading(false);
        });
    } else {
      formik.setValues(formik.initialValues);
      setLoading(false);
    }
  }, [selectedUserId, open]);

  const validate = Yup.object({
    firstName: Yup.string()
      .max(20, "Must be 20 characters or less")
      .required("Required"),
    lastName: Yup.string()
      .max(20, "Must be 20 characters or less")
      .required("Required"),
    username: Yup.string()
      .max(50, "Must be 50 characters or less")
      .required("Required"),
    email: Yup.string().email("Email is invalid").required("Email is required")
  });

  const formik = useFormik({
    initialValues: {
      id: "",
      firstName: "",
      lastName: "",
      username: "",
      email: ""
    },
    validationSchema: validate,
    onSubmit: (values, { resetForm }) => {
      submitForm(values, resetForm);
    }
  });

  function submitForm(values, resetForm) {
    let promise;
    if (formType === "Add")
      promise = addUser({
        ...values
      });
    else {
      promise = updateUser(selectedUserId, {
        ...values
      });
    }

    promise
      .then((response) => {
        const message = formType === "Add" ? "added" : "updated";
        setFeedback({
          severity: "success",
          message: `User ${message}  Successfully`,
          state: true
        });
        setFetchCount((fetchCount) => fetchCount + 1);
        setOpen(false);
      })
      .catch(function (error) {
        setFeedback({
          severity: "error",
          message: error.response.data,
          state: true
        });
      });
  }

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div
      className={`drawer-form-wrapper ${
        open ? "drawer-form-open" : "drawer-form-closed"
      }`}
    >
      <Toolbar />
      <br />
      <div className="close-button">
        <IconButton onClick={handleClose} sx={{ padding: 0 }}>
          <CloseIcon />
        </IconButton>
      </div>
      <div className="drawer-form-container">
        {loading ? (
          <FormShimmering />
        ) : (
          <form onSubmit={formik.handleSubmit}>
            <h5>{formType} User</h5>
            <Divider />
            <div className="form-input">
              <InputLabel
                htmlFor="firstName"
                className="form-input-label"
                required
              >
                First Name
              </InputLabel>
              <TextField
                size="small"
                id="firstName"
                placeholder="First Name"
                className="form-textfield"
                fullWidth
                name="firstName"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                error={
                  formik.touched.firstName && Boolean(formik.errors.firstName)
                }
                helperText={formik.touched.firstName && formik.errors.firstName}
              />
            </div>
            <div className="form-input">
              <InputLabel
                htmlFor="lastName"
                className="form-input-label"
                required
              >
                Last Name
              </InputLabel>
              <TextField
                size="small"
                id="lastName"
                placeholder="Last Name"
                className="form-textfield"
                fullWidth
                name="lastName"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                error={
                  formik.touched.lastName && Boolean(formik.errors.lastName)
                }
                helperText={formik.touched.lastName && formik.errors.lastName}
              />
            </div>
            <div className="form-input">
              <InputLabel
                htmlFor="lastName"
                className="form-input-label"
                required
              >
                Email
              </InputLabel>
              <TextField
                size="small"
                id="email"
                placeholder="Email"
                className="form-textfield"
                fullWidth
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </div>

            <div className="form-input">
              <InputLabel
                htmlFor="username"
                className="form-input-label"
                required
              >
                Username
              </InputLabel>
              <TextField
                size="small"
                id="username"
                placeholder="Username"
                className="form-textfield"
                fullWidth
                name="username"
                value={formik.values.username}
                onChange={formik.handleChange}
                error={
                  formik.touched.username && Boolean(formik.errors.username)
                }
                helperText={formik.touched.username && formik.errors.username}
              />
            </div>

            <br />
            <Stack spacing={2} direction="row" justifyContent="flex-end">
              <Button
                className="primary-button-outlined"
                variant="outlined"
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                className="primary-button-filled"
                variant="contained"
                type="submit"
                startIcon={
                  formType == "Update" ? <BeenhereOutlinedIcon /> : <AddIcon />
                }
              >
                {formType}
              </Button>
            </Stack>
          </form>
        )}
      </div>
    </div>
  );
}

export default UserForm;

import axios from "axios";
import CookieService from "./CookieService";
import { properties } from "../properties";
import { updateToken, tokenRefreshingStatus } from "./user/auth/authActions";
import store from "./store";
import { MESSAGES } from "../utils/Constants";

let tokenRefreshCall = undefined;

const api = axios.create();

api.interceptors.request.use(
  (config) => {
    const token = CookieService.get("access_token");
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalConfig = error.config;
    if (
      !originalConfig.url.startsWith(`${properties.gateway}/auth/`) &&
      error.response
    ) {
      if (error.response.status === 401 && error.response.data === "" && !originalConfig._retry) {
        originalConfig._retry = true;

        const state = store.getState();
        try {
          if (!state.auth.isTokenRefreshing) {
            store.dispatch(tokenRefreshingStatus(true));
            tokenRefreshCall = store.dispatch(updateToken());
          }
          await tokenRefreshCall;
          tokenRefreshCall = undefined;
          store.dispatch(tokenRefreshingStatus(false));
          return api(originalConfig);
        } catch (error) {
          return Promise.reject({
            response: {
              status: 401,
              data: MESSAGES.LOGOUT_MESSAGE
            }
          });
        }
      }
    }
    return Promise.reject(error);
  }
);

export default api;

import { properties } from "../properties";
import api from "../services/api";

function registerAd(values) {
  return api.post(`${properties.adminService}/domain/add`, values);
}

function fetchUsersFromAd() {
  return api.get(`${properties.adminService}/domain/getUsersFromLdap`);
}

function getAdDetails(customerId) {
  return api.get(`${properties.adminService}/domain/getAdDetails`);
}

function updateAdDetails(values) {
  return api.post(
    `${properties.adminService}/domain/updateAdDetails`,
    values
  );
}

export { registerAd, fetchUsersFromAd, getAdDetails, updateAdDetails };

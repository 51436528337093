import { useEffect, useState } from "react";

import { fetchDeviceGroupsByDeviceId } from "../../../API/devices";
import { deleteDeviceFromGroup } from "../../../API/group";
import { useNavigate } from "react-router-dom";
import TableComponent from "../../utility/Table";
import CustomDialog from "../../utility/Dialog";
import { DIALOG_TYPES } from "../../../utils/Constants";
import DeleteIcon from '@mui/icons-material/Delete';

export default function DeviceGroupsAndPoliciesTab({ deviceId, setFeedback }) {
  const navigate = useNavigate();

  const [deviceGroups, setDeviceGroups] = useState([]);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [selectedGroupId, setSelectedGroupId] = useState("");

  useEffect(() => {
    fetchDeviceGroupsByDeviceId(deviceId)
      .then((response) => {
        if (response.data) {
          setDeviceGroups(response.data);
        }
      })
      .catch((error) => {
        setFeedback({
          severity: "error",
          message: "There is an issue while fetching the device.",
          state: true
        });
      });
  }, [deviceId]);
  const confirmRemove = (id) => {
    setSelectedGroupId(id);
    setOpenConfirmation(true);
  };

  const handleOpenGroup = (id) => {
    navigate("/device-groups", {
      state: {
        groupId: id
      }
    });
  };

  const removeFromGroup = () => {
    deleteDeviceFromGroup(selectedGroupId, deviceId)
      .then(() => {
        setDeviceGroups(
          deviceGroups.filter(
            (deviceGroup) => deviceGroup.id != selectedGroupId
          )
        );
        setFeedback({
          severity: "success",
          message: "Device removed successfully.",
          state: true
        });
      })
      .catch((error) => {
        setFeedback({
          severity: "error",
          message: error.data,
          state: true
        });
      })
      .finally(() => {
        setOpenConfirmation(false);
      });
  };
  return (
    <>
      <TableComponent
        headings={["id", "name", "policies"]}
        customColumnNames={{
          name: "Group Name",
          policies: "Policies Associated with Group"
        }}
        data={deviceGroups}
        options={{ hideIds: true, maxChips: 3 }}
        styles={{ name: "button", actionStyles:"buttons" }}
        actions={[
          {
            label: "Remove",
            onClickHandler: confirmRemove,
            hasParameter: true,
            color: "error",
            icon:<DeleteIcon/>
          }
        ]}
        buttonActions={{
          name: { onClickHandler: handleOpenGroup }
        }}
      />
      <CustomDialog
        open={openConfirmation}
        setOpen={setOpenConfirmation}
        title="Are you sure you want to remove?"
        content="
        If you remove this device from the group, any policies applied to the device as part of the group association will also be removed."
        handleConfirm={removeFromGroup}
        dialogType={DIALOG_TYPES.CONFIRM}
      />
    </>
  );
}

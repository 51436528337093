import { Alert, Snackbar } from "@mui/material";

function Feedback({ feedback, setFeedback }) {
  function handleClose(event, reason) {
    if (reason === "clickaway") {
      return;
    }

    setFeedback({ ...feedback, state: false, message: "" });
  }

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      open={feedback.state}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <Alert severity={feedback.severity} onClose={handleClose}>
        {feedback.message}
      </Alert>
    </Snackbar>
  );
}

export default Feedback;

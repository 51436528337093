import React from "react";
import ReportList from "./ReportList";

export default function DesktopAppsReportList() {
  const headings = [
    "id",
    "deviceName",
    "incidentDttm",
    "policyName",
    "Blocked Application",
    "risk",
  ];
  const subheading = "Identify Devices Attempting to Access Blocked Applications";
  return <ReportList headings={headings} destinationId={6} subheading={subheading} />;
}
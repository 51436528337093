import React, { useState, useEffect } from "react";
import { ACTION, RISK_LEVEL } from "../../../utils/Constants";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  addHttpPolicy,
  fetchHttpPolicy,
  updateHttpPolicy
} from "../../../API/policy";
import {
  InputLabel,
  TextField,
  Toolbar,
  Divider,
  IconButton,
  Grid,
  RadioGroup,
  Radio,
  FormControlLabel,
  Checkbox,
  Tabs,
  Tab,
  Box
} from "@mui/material";
import CustomTabPanel from "../../other/CustomTabPanel";
import "./http-policy-form.css";
import {
  fetchAndFormatClassifications,
  fetchAndFormatFileCategories,
  convertStringArrayToIntegerArray,
  fetchAndFormatDeviceGroups,
  fetchAndFormatSiteBlockingCategory
} from "../../../utils/Helpers";
import FormShimmering from "../../utility/FormShimmer";
import CloseIcon from "@mui/icons-material/Close";
import FormFooter from "../../utility/FormFooter";
import CheckboxSelectorListWithSearch from "../../utility/CheckBoxSelectorListWithSearch";

function HttpPolicyForm({
  setFeedback,
  setOpen,
  open,
  selectedPolicyId,
  setFetchCount
}) {
  const LIST_HEADINGS = {
    DOMAIN_LIST: "Select Domain Categories",
    FILE_ATTACHMENTS: "Select File Extensions",
    CLASSIFICATION_LIST: "Select Classifications"
  };

  const LIST_TYPES = {
    DOMAIN_LIST: "DOMAIN_LIST",
    FILE_ATTACHMENTS: "FILE_ATTACHMENTS",
    CLASSIFICATION_LIST: "CLASSIFICATION_LIST"
  };

  const [showList, setShowList] = useState("");

  const [loading, setLoading] = useState(true);
  const [tabValue, setTabValue] = useState(0);

  let formType = selectedPolicyId !== null ? "Update" : "Add";

  const destinationId = [3];
  const lastTabValue = 4;

  function resetForm() {
    setTabValue(0);
    setShowList("");
    formik.resetForm();
  }

  useEffect(() => {
    setLoading(true);
    if (open && formType === "Update" && selectedPolicyId) {
      fetchHttpPolicy(selectedPolicyId)
        .then((response) => {
          formik.setValues(response.data);
        })
        .catch((error) => {
          setFeedback({
            severity: "error",
            message: "An error occurred while fetching the policy.",
            state: true
          });
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      formik.setValues(formik.initialValues);
      resetForm();
      setLoading(false);
    }
  }, [formType, selectedPolicyId, open]);

  const validate = Yup.object({
    name: Yup.string()
      .max(40, "Policy name should be 40 characters or less")
      .required("Please enter policy name."),
    siteBlockingCategoryIds: Yup.array()
      .min(1, "Please select at least one domain category.")
      .required("Please select domain categories.")
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      risk: RISK_LEVEL.HIGH,
      classificationAction: "",
      domainAction: ACTION.ALLOW,
      fileAction: "",
      applyClassification: false,
      destinationIds: destinationId,
      classificationIds: [],
      deviceGroupIds: [],
      siteBlockingCategoryIds: [],
      fileCategoryIds: []
    },
    validationSchema: validate,
    onSubmit: (values, { resetForm }) => {
      submitForm(values, resetForm);
    }
  });

  function submitForm(values, resetForm) {
    let promise;

    if (formType === "Add") {
      promise = addHttpPolicy(values);
    } else {
      promise = updateHttpPolicy(selectedPolicyId, values);
    }
    promise
      .then((response) => {
        const message = formType === "Add" ? "added" : "updated";
        setFeedback({
          severity: "success",
          message: `Policy ${message}  Successfully`,
          state: true
        });
        setFetchCount((fetchCount) => fetchCount + 1);
        setOpen(false);
      })
      .catch(function (error) {
        const message = formType === "Add" ? "adding" : "updating";
        setFeedback({
          severity: "error",
          message: `There is an issue while ${message} policy.`,
          state: true
        });
      });
  }

  const handleClose = () => {
    setOpen(false);
  };

  function props(index) {
    return {
      id: `http-tab-${index}`,
      "aria-controls": `http-tabpanel-${index}`
    };
  }

  const handleTabChange = (event, newValue) => {
    if (newValue==undefined) newValue = (tabValue + 1) % (lastTabValue + 1);
    switch (newValue) {
      case 1:
        setShowList(LIST_TYPES.DOMAIN_LIST);
        break;
      case 2:
        setShowList(LIST_TYPES.FILE_ATTACHMENTS);
        break;
      case 3:
        setShowList(LIST_TYPES.CLASSIFICATION_LIST);
        break;
      default:
        setShowList("");
    }
    setTabValue(newValue);
  };

  return (
    <div>
      <div
        className={`drawer-form-wrapper ${
          open ? "drawer-form-open" : "drawer-form-closed"
        }`}
        style={showList ? { width: "65%" } : { width: "50%" }}
      >
        <Toolbar />
        <br />
        <div className="close-button">
          <IconButton onClick={handleClose} sx={{ padding: 0 }}>
            <CloseIcon />
          </IconButton>
        </div>
        {loading ? (
          <FormShimmering />
        ) : (
          <div className="drawer-form-container">
            <h5>{formType} HTTP Policy</h5>
            <Divider />
            <form onSubmit={formik.handleSubmit}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={tabValue}
                  onChange={handleTabChange}
                  aria-label="basic tabs"
                >
                  <Tab label="General" {...props(0)} />
                  <Tab label="Domain Restriction" {...props(1)} />
                  <Tab label="File Restriction" {...props(2)} />
                  <Tab label="Classification" {...props(3)} />
                  <Tab label="Associate Groups" {...props(4)} />
                </Tabs>
              </Box>
              <Grid
                container
                spacing={2}
                className="group-form-container"
                columns={24}
              >
                <Grid item md={showList ? 13 : 24}>
                  <Box sx={{ width: "100%" }}>
                    <CustomTabPanel value={tabValue} index={0}>
                      <div>
                        <InputLabel
                          htmlFor="policy-name"
                          className="form-input-label"
                          required
                        >
                          Policy Name
                        </InputLabel>
                        <TextField
                          placeholder="eg. Developer Policy"
                          size="small"
                          id="policy-name"
                          className="form-textfield"
                          fullWidth
                          name="name"
                          value={formik.values.name}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.name && Boolean(formik.errors.name)
                          }
                        />
                      </div>
                      <div className="form-input">
                        <InputLabel
                          htmlFor="policy-risk"
                          className="form-input-label"
                          required
                        >
                          Risk Level
                        </InputLabel>
                        <RadioGroup
                          id="policy-risk"
                          name="risk"
                          value={formik.values.risk}
                          onChange={formik.handleChange}
                        >
                          <FormControlLabel
                            value={RISK_LEVEL.HIGH}
                            control={<Radio />}
                            label="High"
                          />
                          <div className="radio-button-with-link">
                            <FormControlLabel
                              value={RISK_LEVEL.MEDIUM}
                              control={<Radio />}
                              label="Medium"
                            />
                          </div>
                          <div className="radio-button-with-link">
                            <FormControlLabel
                              value={RISK_LEVEL.LOW}
                              control={<Radio />}
                              label="Low"
                            />
                          </div>
                        </RadioGroup>
                      </div>
                    </CustomTabPanel>
                    <CustomTabPanel value={tabValue} index={1}>
                      <div className="helper-text">
                        <p>
                          <strong style={{ color: "red" }}>* </strong>
                          For each device group, it is recommended to
                          consistently block or allow domains across all
                          policies to prevent conflicts.
                          <br /> (Applying multiple policies with different
                          domain restrictions will result in only the allowed
                          domains being accessible.)
                        </p>
                      </div>
                      <div className="restriction-heading">
                        <InputLabel className="form-input-label" required>
                          Select Domain Restriction
                        </InputLabel>
                      </div>
                      <RadioGroup
                        name="domainAction"
                        value={formik.values.domainAction}
                        onChange={formik.handleChange}
                      >
                        <div className="radio-button-with-link">
                          <FormControlLabel
                            value={ACTION.ALLOW}
                            control={<Radio />}
                            label="Allow Domains"
                          />
                        </div>
                        <div className="radio-button-with-link">
                          <FormControlLabel
                            value={ACTION.BLOCK}
                            control={<Radio />}
                            label="Block Domains"
                          />
                        </div>
                      </RadioGroup>
                      <br />
                      <div className="helper-text">
                        <p>
                          <strong>Allow domains:</strong> Only selected
                          categories will be allowed to access.
                        </p>
                        <p>
                          <strong>Block domains:</strong> Only selected
                          categories will be blocked.
                        </p>
                      </div>
                    </CustomTabPanel>
                    <CustomTabPanel value={tabValue} index={2}>
                      <div>
                        <div className="helper-text">
                          <p>
                            <strong style={{ color: "red" }}>* </strong>
                            {formik.values.domainAction === ACTION.BLOCK
                              ? "File restriction will be applicable on the remaining domains that are not in the previously selected domain categories."
                              : "File restriction will be applicable on the previously selected domain categories."}
                          </p>
                        </div>
                        <div className="restriction-heading">
                          <InputLabel className="form-input-label">
                            Select File Restriction
                          </InputLabel>
                        </div>
                        <RadioGroup
                          name="fileAction"
                          value={formik.values.fileAction}
                          onChange={formik.handleChange}
                        >
                          <FormControlLabel
                            value={ACTION.NONE}
                            control={<Radio />}
                            label="No Restriction"
                          />
                          <div className="radio-button-with-link">
                            <FormControlLabel
                              value={ACTION.ALLOW}
                              control={<Radio />}
                              label="Allow extensions"
                            />
                          </div>
                          <div className="radio-button-with-link">
                            <FormControlLabel
                              value={ACTION.BLOCK}
                              control={<Radio />}
                              label="Block extensions"
                            />
                          </div>
                        </RadioGroup>
                        <br />
                        <div className="helper-text">
                          <p>
                            <strong>No Restriction:</strong> All file extensions
                            will be allowed to upload.
                          </p>
                          <p>
                            <strong>Allow extensions:</strong> Only selected
                            file extensions will be allowed to upload.
                          </p>
                          <p>
                            <strong>Block extensions:</strong> Only selected
                            file extensions will be blocked.
                          </p>
                        </div>
                      </div>
                    </CustomTabPanel>
                    <CustomTabPanel value={tabValue} index={3}>
                      <div className="checkbox-field">
                        <InputLabel htmlFor="checkBody">
                          Apply Classification
                        </InputLabel>
                        <Checkbox
                          name="applyClassification"
                          id="applyClassification"
                          checked={formik.values.applyClassification}
                          onChange={(e) =>
                            formik.setFieldValue(
                              "applyClassification",
                              e.target.checked
                            )
                          }
                        />
                      </div>
                      <div className="helper-text">
                        <p>
                          {formik.values.domainAction === ACTION.BLOCK
                            ? "Classification will be applicable on all the accessible domains."
                            : "Classification will be applicable on the previously selected domain categories."}
                        </p>
                      </div>

                      <div className="form-input">
                        <InputLabel
                          htmlFor="policy-action"
                          className="form-input-label"
                        >
                          Classification Action
                        </InputLabel>
                        <div className="helper-text">
                          Select the action to take when a classification match
                          occurs in the uploaded data.
                        </div>
                        <br />
                        <RadioGroup
                          name="classificationAction"
                          value={formik.values.classificationAction}
                          onChange={formik.handleChange}
                        >
                          <FormControlLabel
                            value={ACTION.BLOCK}
                            control={<Radio />}
                            label="Block"
                          />
                          <div className="radio-button-with-link">
                            <FormControlLabel
                              value={ACTION.LOG}
                              control={<Radio />}
                              label="Log"
                            />
                          </div>
                          <div className="radio-button-with-link">
                            <FormControlLabel
                              value={ACTION.NOTIFY}
                              control={<Radio />}
                              label="Notify"
                            />
                          </div>
                        </RadioGroup>
                      </div>
                    </CustomTabPanel>
                    <CustomTabPanel value={tabValue} index={4}>
                      <div className="helper-text">
                        This policy is applied to the below group(s). You can
                        select groups to apply this policy to them.
                      </div>
                      <br />
                      <CheckboxSelectorListWithSearch
                        heading="Select Device Groups"
                        setFeedback={setFeedback}
                        fetchAndFormatFields={fetchAndFormatDeviceGroups}
                        selectedRecords={formik.values.deviceGroupIds}
                        setSelectedRecords={(values) => {
                          formik.setFieldValue("deviceGroupIds", values);
                        }}
                        options={{
                          showFilters: true,
                          showSearchBar: true
                        }}
                        maxHeight="300px"
                        showAddRecordButton={true}
                        addButtonUrl={"/device-groups"}
                        addButtonText={"Create Group"}
                      />
                    </CustomTabPanel>
                  </Box>
                </Grid>
                {showList ? (
                  <>
                    <Grid md={1} item sx={{ Height: "100%" }}>
                      <Divider orientation="vertical" sx={{ opacity: 1 }} />
                    </Grid>
                    <Grid item md={10} style={{ paddingTop: "32px" }}>
                      {(() => {
                        switch (showList) {
                          case LIST_TYPES.DOMAIN_LIST:
                            return (
                              <div key={"DOMAIN_LIST"}>
                                <CheckboxSelectorListWithSearch
                                  isRequired={true}
                                  heading={LIST_HEADINGS[showList]}
                                  setFeedback={setFeedback}
                                  fetchAndFormatFields={
                                    fetchAndFormatSiteBlockingCategory
                                  }
                                  selectedRecords={
                                    formik.values.siteBlockingCategoryIds
                                  }
                                  setSelectedRecords={(values) => {
                                    formik.setFieldValue(
                                      "siteBlockingCategoryIds",
                                      values
                                    );
                                  }}
                                  options={{
                                    showFilters: true,
                                    showSearchBar: true
                                  }}
                                  showAddRecordButton={true}
                                  addButtonUrl={"/http-policy"}
                                  addButtonText={"Create Category"}
                                />
                              </div>
                            );

                          case LIST_TYPES.CLASSIFICATION_LIST:
                            return (
                              <div key={"CLASSIFICATION_LIST"}>
                                <CheckboxSelectorListWithSearch
                                  heading={LIST_HEADINGS[showList]}
                                  setFeedback={setFeedback}
                                  fetchAndFormatFields={
                                    fetchAndFormatClassifications
                                  }
                                  selectedRecords={
                                    formik.values.classificationIds
                                  }
                                  setSelectedRecords={(values) =>
                                    formik.setFieldValue(
                                      "classificationIds",
                                      values
                                    )
                                  }
                                  options={{
                                    showFilters: true,
                                    showSearchBar: true
                                  }}
                                  showAddRecordButton={true}
                                  addButtonUrl={"/classification-list"}
                                  addButtonText={"Create Classification"}
                                />
                              </div>
                            );
                          case LIST_TYPES.FILE_ATTACHMENTS:
                            return (
                              <div key={"FILE_ATTACHMENTS"}>
                                <CheckboxSelectorListWithSearch
                                  heading={LIST_HEADINGS[showList]}
                                  setFeedback={setFeedback}
                                  fetchAndFormatFields={
                                    fetchAndFormatFileCategories
                                  }
                                  selectedRecords={convertStringArrayToIntegerArray(
                                    formik.values.fileCategoryIds
                                  )}
                                  setSelectedRecords={(values) =>
                                    formik.setFieldValue(
                                      "fileCategoryIds",
                                      values
                                    )
                                  }
                                  options={{
                                    showFilters: true,
                                    showSearchBar: true
                                  }}
                                />
                              </div>
                            );
                          default:
                            return null;
                        }
                      })()}
                    </Grid>
                  </>
                ) : null}
              </Grid>
              <FormFooter
                setOpen={setOpen}
                formType={formType}
                errors={formik.errors}
                touched={formik.touched}
                handleClose={handleClose}
                handleSubmit={formik.handleSubmit}
                tabValue={tabValue}
                handleTabChange={handleTabChange}
                lastTabValue={lastTabValue}
              />
            </form>
          </div>
        )}
      </div>
    </div>
  );
}

export default HttpPolicyForm;

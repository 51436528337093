import GoogleIcon from "../assets/icons/GoogleIcon";
import SlackIcon from "../assets/icons/SlackIcon";
import Office365Icon from "../assets/icons/Office365Icon";
import DomainIcon from "../assets/icons/DomainIcon";

const iconComponentMapping = {
  Google: GoogleIcon,
  Office365: Office365Icon,
  Slack: SlackIcon
};

const getIconComponentByName = (name) => {
  if (iconComponentMapping.hasOwnProperty(name)) {
    return iconComponentMapping[name];
  } else {
    return DomainIcon;
  }
};

export { getIconComponentByName };

import { properties } from "../properties";
import api from "../services/api";

function getRequestApprovalsList(
  pageSize,
  pageNumber,
  searchText,
  status,
  destination,
  startTime,
  endTime
) {
  let url = `${properties.adminService}/approval?pageSize=${pageSize}&pageNumber=${pageNumber}&searchText=${searchText}&`;

  if (status != null && status != "all") url += `status=${status}&`;
  if (startTime && startTime.isValid()){
    url += `startTime=${new Date(startTime).toISOString()}&`;}
  if (endTime && endTime.isValid()) url += `endTime=${new Date(endTime).toISOString()}&`;
  if (destination && destination != "all")
    url += `destinationId=${destination}`;

  return api.get(url);
}

function adminApprovalRequest(requestData) {
  return api.post(`${properties.adminService}/approval`, requestData, {
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function revokeApproval(id) {
  return api.patch(`${properties.adminService}/approval/${id}/status`, {
    headers: {
      "Content-Type": "application/json"
    }
  });
}

export { getRequestApprovalsList, adminApprovalRequest, revokeApproval };

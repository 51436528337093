import React, { useEffect, useState } from "react";
import { Card, CardContent, CardActionArea } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import { fetchEmailTemplateList } from "../../API/emailTemplates";
import { EMAIL_TEMPLATE_NAMES } from "../../utils/Constants";

function EmailTemplatesList({ setFeedback }) {
  const navigate = useNavigate();
  const [templatesList, setTemplatesList] = useState([]);
  useEffect(() => {
    fetchEmailTemplateList()
      .then(({ data }) => {
        setTemplatesList(data);
      })
      .catch((error) => {
        setFeedback({
          severity: "error",
          message:
            "An error occurred while fetching Email Templates. Please try again later.",
          state: true
        });
      });
  }, []);

  const renderEmailTemplate = (emailTemplate) => {
    navigate(
      `/edit-email-template?id=${emailTemplate["id"]}&name=${emailTemplate["name"]}`
    );
  };

  return (
    <Grid container spacing={2}>
      {templatesList.length ? (
        templatesList.map((template,index) => {
          return (
            <Grid item xs={4} key={index}>
              <Card
                variant="outlined"
                onClick={() => renderEmailTemplate(template)}
              >
                <CardActionArea>
                  <CardContent>
                    {EMAIL_TEMPLATE_NAMES[template["name"]]}
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          );
        })
      ) : (
        <div>
          <br />
          Currently, there are no templates available for editing.
        </div>
      )}
    </Grid>
  );
}

export default EmailTemplatesList;

import { Box } from "@mui/material";
import { Typography } from "@mui/material";
import { Button } from "@mui/material";
import { useRef } from "react";
import { agentDownload } from "../../API/agentDownload";
import { DEVICE_TYPE, MESSAGES } from "../../utils/Constants";
import { AGENT_MESSAGES } from "../../utils/Messages";

export default function AgentDownloadButton({ setFeedback }) {
  const anchorRef = useRef(null);

  const onSelectHandler = () => {
    const packageRequestJson = {
      agentVersion: "1.0",
      needUpgrade: false,
      fullAgentParcel: true,
      deviceType: DEVICE_TYPE.COMPANY_OWNED,
    };

    agentDownload(packageRequestJson)
      .then(() => {
        setFeedback({
          severity: "success",
          message: AGENT_MESSAGES.AGENT_DOWNLOAD_SUCCESS,
          state: true,
        });
      })
      .catch(() =>
        setFeedback({
          severity: "error",
          message: MESSAGES.GENERIC_EXCEPTION_MESSAGE,
          state: true,
        })
      );
  };

  return (
    <div>
      <Button
        className="btn-primary-main-add"
        onClick={onSelectHandler}
        ref={anchorRef}
      >
        <Box className="btns-with-icon">
          <loadIcon />
          <Typography className="btns-with-icon-text">Download Module</Typography>
        </Box>
      </Button>
    </div>
  );
}

import { properties } from "../properties";
import api from "../services/api";

function createCustomer(values) {
  return api.post(`${properties.adminService}/customer/create`, values);
}

function getCustomer() {
  return api.get(`${properties.adminService}/customer/fetchCustomer`);
}

function getSmtpSettings() {
  return api.get(`${properties.adminService}/customer/smtp-settings`);
}

function setSmtpSettings(smtpSettings) {
  return api.patch(
    `${properties.adminService}/customer/smtp-settings`,
    smtpSettings
  );
}

function setOrganizationalDomains(data) {
  return api.patch(
    `${properties.adminService}/customer/organizational-domains`,
    data
  );
}

function getOrganizationalDomains() {
  return api.get(`${properties.adminService}/customer/organizational-domains`);
}

function createPasswordResetLink(email) {
  return api.post(`${properties.adminService}/customer/password-reset-link`, {
    email
  });
}

function changePassword(data) {
  return api.put(
    `${properties.adminService}/customer/password`,
    data
  );
}

export {
  createCustomer,
  getCustomer,
  getSmtpSettings,
  setSmtpSettings,
  setOrganizationalDomains,
  getOrganizationalDomains,
  createPasswordResetLink,
  changePassword
};

import CustomDateTimePicker from "./DateTimePicker";

export default function DateTimeFilter({
  startTime,
  endTime,
  setStartTime,
  setEndTime
}) {
  return (
    <div className="date-time-filter">
      <div>Filter by date </div>
      <CustomDateTimePicker label={"From"} time={startTime} onChangeHandler={(val)=>setStartTime(val)} />
      <CustomDateTimePicker label={"To"} time={endTime} onChangeHandler={(val)=>setEndTime(val)} />
    </div>
  );
}

import React, {useEffect} from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import CustomTabPanel from "../other/CustomTabPanel";
import AdForm from "../user_store/active_directory/AdForm";
import EmailTemplatesList from "../email_templates/EmailTemplatesList";
import { useState } from "react";
import PageTitle from "../utility/PageTitle";
import Configuration from "../password_configuration/Configuration";
import SmtpSettings from "../configurations/SmtpSettings";
import { useLocation } from "react-router-dom";

function props(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

function SettingsPage({ setFeedback }) {
  const [value, setValue] = useState(0);

  const { state } = useLocation();

  useEffect(() => {
    if (state?.tabValue) {
      setValue(state.tabValue);
    }
  }, [state]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <PageTitle />
      <br />
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs">
            <Tab label="Active Directory Configurations" {...props(0)} />
            <Tab label="Password Configurations" {...props(1)}/>
            <Tab label="SMTP Configurations" {...props(2)} />
            <Tab label="Customize Email Templates" {...props(3)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <AdForm setFeedback={setFeedback} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1} >
          <Configuration setFeedback={setFeedback} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <SmtpSettings setFeedback={setFeedback} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          <EmailTemplatesList setFeedback={setFeedback} />
        </CustomTabPanel>
      </Box>
    </>
  );
}

export default SettingsPage;

import { properties } from "../properties";
import api from "../services/api";

function fetchAllAppBlockingCategoriesByCustomerId() {
  return api.get(`${properties.adminService}/app-blocking-category`);
}

function fetchAllAppBlockingCategoriesByPolicyId(policyId) {
  return api.get(
    `${properties.adminService}/app-blocking-category?policyId=${policyId}`);
}

export {
  fetchAllAppBlockingCategoriesByCustomerId,
  fetchAllAppBlockingCategoriesByPolicyId
};

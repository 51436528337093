import React, { useState, useEffect } from "react";
import { fetchUsbDetails, deleteUsbDetails } from "../../API/usbBlocking";
import TableComponent from "../utility/Table";
import { Box, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import UsbForm from "./BlockUsbForm";
import SearchBar from "../utility/SearchBar";
import CustomDialog from "../utility/Dialog";
import { DIALOG_TYPES, usbTypeMap } from "../../utils/Constants";
import UsbIcon from "@mui/icons-material/Usb";
import Pagination from "../utility/Pagination";
import { useLocation } from "react-router-dom";

function UsbList({ setFeedback }) {
  const [usbs, setUsbs] = useState(null);
  const [inputText, setInputText] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [fetchCount, setFetchCount] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const location = useLocation();

  const currentUsbType = usbTypeMap[location.pathname]

  useEffect(() => {
    fetchUsbDetails(currentUsbType.type, pageSize, pageNumber, searchText)
      .then(({ data }) => {
        setUsbs(data.usbs);
        setTotalCount(data.totalCount);
      })
      .catch((error) =>
        setFeedback({
          severity: "error",
          message: "There is an issue while fetching usbs.",
          state: true
        })
      );
  }, [fetchCount, pageNumber, searchText, location.pathname]);

  const handleDelete = (id) => {
    setIsDeleteConfirmationOpen(true);
    setSelectedId(id);
  };

  const handleConfirmDelete = () => {
    deleteUsbDetails(selectedId)
      .then(() => {
        setFeedback({
          severity: "success",
          message: "USB deleted successfully",
          state: true
        });
        setFetchCount((fetchCount) => fetchCount + 1);
      })
      .catch((error) => {
        setFeedback({
          severity: "error",
          message: error.response.data,
          state: true
        });
      })
      .finally(() => {
        setIsDeleteConfirmationOpen(false);
        setSelectedId("");
      });
  };

  const handlePageChange = (event, value) => {
    setPageNumber(value);
  };

  const handleOpen = (id = null) => {
    setOpen(true);
    setSelectedId(id);
  };
  const updateTableData = (updatedData) => {
    const updatedUsbs = usbs.map((usbs) => {
      if (usbs.id === updatedData.id) {
        return updatedData;
      }
      return usbs;
    });
    setUsbs(updatedUsbs);
  };

  return (
    <div>
      <div>
        <Button
          className="btn-primary-main-add"
          onClick={() => handleOpen()}
          sx={{ float: "right" }}
        >
          <Box className="btns-with-icon">
            <UsbIcon />
            <Typography className="btns-with-icon-text">
              Add {currentUsbType.title}
            </Typography>
          </Box>
        </Button>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "20px"
        }}
      >
        <SearchBar
          setSearchText={setSearchText}
          searchText={searchText}
          placeholder={"Search"}
        />
      </div>
      <TableComponent
        headings={["id", "name", "productId", "vendorId"]}
        data={usbs}
        options={{ hideIds: true }}
        actions={[
          {
            label: "Edit",
            onClickHandler: handleOpen,
            hasParameter: true
          },
          {
            label: "Delete",
            onClickHandler: handleDelete,
            hasParameter: true
          }
        ]}
        styles={{
          table: { maxHeight: 425 }
        }}
      />
      {totalCount != 0 ? (
        <div className="pagination">
          <Pagination
            totalCount={totalCount}
            page={pageNumber}
            setPage={setPageNumber}
            rowsPerPage={pageSize}
            setRowsPerPage={setPageSize}
          />
        </div>
      ) : null}
      <UsbForm
        open={open}
        setOpen={setOpen}
        setFeedback={setFeedback}
        selectedUsbId={selectedId}
        setFetchCount={setFetchCount}
        updateTableData={updateTableData}
        usbList={usbs}
      />
      <CustomDialog
        open={isDeleteConfirmationOpen}
        title="Confirm Deletion"
        content="Are you sure you want to delete?"
        setOpen={setIsDeleteConfirmationOpen}
        handleConfirm={handleConfirmDelete}
        dialogType={DIALOG_TYPES.CONFIRM}
      />
    </div>
  );
}

export default UsbList;

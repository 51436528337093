import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import CustomTabPanel from "../other/CustomTabPanel";
import UserGroupList from "./UserGroupList";
import DeviceGroupList from "./DeviceGroupList";
import PageTitle from "../utility/PageTitle";

function props(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

export default function GroupList({ setFeedback }) {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <PageTitle />
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Users Group" {...props(0)} />
            <Tab label="Device Group" {...props(1)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <UserGroupList setFeedback={setFeedback} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <DeviceGroupList setFeedback={setFeedback} />
        </CustomTabPanel>
      </Box>
    </>
  );
}

import { Typography } from "@mui/material";
import "./css/summary-card.css";

function SummaryCard({ heading, icon: Icon, count, isLoading }) {
  return (
    <div className={`summary-card summary ${isLoading ? "shimmer" : ""}`}>
      <div className="dlp-incidents">
        <Typography
          className="heading"
          color="text.secondary"
          gutterBottom
        ></Typography>
        <div className="policy-breached"></div>
      </div>
      <div className="rules-breached"></div>
    </div>
  );
}

function SummaryCardShimmering({ isLoading }) {
  const cards = Array.from({ length: 4 }, (_, index) => (
    <SummaryCard key={index} isLoading={isLoading} />
  ));

  return <div className="summary-cards">{cards}</div>;
}

export default SummaryCardShimmering;

import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import CustomTabPanel from "../other/CustomTabPanel";
import EmailReportList from "./EmailReportList";
import DeviceReportList from "./DeviceReportList";

function props(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

export default function ReportingList({ setFeedback }) {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Email Reports" {...props(0)} />
          <Tab label="Device Reports" {...props(1)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <EmailReportList setFeedback={setFeedback} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <DeviceReportList setFeedback={setFeedback} />
      </CustomTabPanel>
    </Box>
  );
}

import { Typography } from "@mui/material";
import PoliciesEnabledIcon from "../assets/icons/PoliciesEnabledIcon";
import "./css/summary-card.css";
import ActiveStatusCircleIcon from "../assets/icons/ActiveStatusCircleIcon";
import InactiveStatusCircleIcon from "../assets/icons/InactiveStatusCircleIcon";
import DeviceIcon from "../assets/icons/DeviceIcon";

function DeviceStatusCard({ active, inactive, onClickHandler }) {
  return (
    <div className="summary-card" onClick={onClickHandler}>
      <div style={{ textAlign: "left" }}>
        <Typography className="heading" color="text.secondary" gutterBottom>
          Device Status
        </Typography>
        <div style={{ display: "flex", alignItems: "center" }}>
          <ActiveStatusCircleIcon />
          <div
            className="count"
            style={{
              stroke: "#CFCDCD",
              paddingRight: 9,
              borderRight: "0.8px solid #CFCDCD",
              paddingLeft: 6
            }}
          >
            {active}
          </div>
          <div style={{ paddingLeft: 9 }}>
            <InactiveStatusCircleIcon />
          </div>
          <div className="count" style={{ paddingLeft: 6 }}>
            {inactive}
          </div>
        </div>
      </div>
      <DeviceIcon style={{ width: 50, height: 50 }} />
    </div>
  );
}

export default DeviceStatusCard;

import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import {
  createStateSyncMiddleware,
  initMessageListener
} from "redux-state-sync";
import rootReducer from "./rootReducer";
import { composeWithDevTools } from "redux-devtools-extension";

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk, createStateSyncMiddleware()))
);
initMessageListener(store);
export default store;

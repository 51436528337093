import { useState, useEffect } from "react";
import TableComponent from "./Table";
import { InputLabel } from "@mui/material";
import SearchBar from "./SearchBar";
import { localSearch } from "./LocalSearch";

export default function SelectorList({
  headings,
  hideIds,
  label,
  selectedRecordId,
  setSelectedRecordId,
  customColumnNames,
  data,
  searchColumnNames
}) {
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  useEffect(() => {
    setFilteredData(localSearch(data, searchColumnNames, searchText));
  }, [searchText]);

  return (
    <>
      <InputLabel className="form-input-label">{label}</InputLabel>
      <SearchBar
        searchText={searchText}
        setSearchText={setSearchText}
        placeholder="Search"
        fullWidth={true}
      />
      <div className="selector-table-wrapper">
        <TableComponent
          styles={{
            table: { maxHeight: "300px" }
          }}
          headings={headings}
          data={filteredData}
          options={{ hideIds: hideIds }}
          multiselect={{
            selectedRecordId,
            setSelectedRecordId,
            isAllSelected,
            setIsAllSelected
          }}
          customColumnNames={customColumnNames}
        />
      </div>
    </>
  );
}

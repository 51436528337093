import { properties } from "../properties";
import api from "../services/api";

function create(values) {
  return api.post(`${properties.adminService}/groups`, values);
}

function fetch(id) {
  return api.get(`${properties.adminService}/groups/${id}`);
}

function update(values) {
  return api.put(`${properties.adminService}/groups/${values.id}`, values);
}

function deleteGroup(id) {
  return api.delete(`${properties.adminService}/groups/${id}`);
}

function fetchAllGroupsByType(type) {
  return api.get(`${properties.adminService}/groups?type=${type}`);
}

function fetchAllGroupsByTypeAndPagination(
  type,
  pageSize,
  pageNumber,
  searchText
) {
  return api.get(
    `${properties.adminService}/groups?type=${type}&pageSize=${pageSize}&pageNumber=${pageNumber}&searchText=${searchText}`
  );
}

function fetchAllUserGroupsByPolicyId(policyId) {
  return api.get(
    `${properties.adminService}/groups?type=USER&policyId=${policyId}`
  );
}
function fetchAllDeviceGroupsByPolicyId(policyId) {
  return api.get(
    `${properties.adminService}/groups?type=DEVICE&policyId=${policyId}`
  );
}
function addUsersToGroup(groupIds, userIds) {
  return api.post(
    `${properties.adminService}/groups/users/add`,
    { groupIds, userIds }
  );
}
function addDevicesToGroup(groupIds, deviceIds) {
  return api.post(
    `${properties.adminService}/groups/devices/add`,
    { groupIds, deviceIds }
  );
}
function deleteDeviceFromGroup(groupId,deviceId) {
  return api.delete(`${properties.adminService}/device-groups/${groupId}/device/${deviceId}`);
}
export {
  create as createGroup,
  fetch as fetchGroup,
  update as updateGroup,
  deleteGroup,
  fetchAllUserGroupsByPolicyId,
  fetchAllGroupsByType,
  fetchAllDeviceGroupsByPolicyId,
  addUsersToGroup,
  addDevicesToGroup,
  fetchAllGroupsByTypeAndPagination,
  deleteDeviceFromGroup
};

import { useState, useEffect } from "react";
import TableComponent from "../utility/Table";
import AppCategoryForm from "./AppCategoryForm";
import CustomDialog from "../utility/Dialog";
import {
  fetchAllAppCategories,
  deleteAppCategory
} from "../../API/appCategory";
import { Button, Box, Typography } from "@mui/material";
import SearchBar from "../utility/SearchBar";
import { DIALOG_TYPES } from "../../utils/Constants";
import AppsIcon from "@mui/icons-material/Apps";
import Pagination from "../utility/Pagination";

function AppBlockingCategories({ setFeedback }) {
  const [openCategory, setOpenCategory] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [data, setData] = useState(null);
  const [fetchCount, setFetchCount] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);

  useEffect(() => {
    fetchAllAppCategories(searchText, pageNumber, pageSize)
      .then((response) => {
        setData(response.data.categories);
        setTotalCount(response.data.totalCount);
      })
      .catch((error) => {
        setFeedback({
          severity: "error",
          message: "There is an issue fetching app categories!",
          state: true
        });
      });
  }, [fetchCount, searchText, pageNumber, pageSize]);

  const handleClickOpen = () => {
    setOpenCategory(true);
    setSelectedId("");
  };

  const handleDelete = (id) => {
    setIsDeleteConfirmationOpen(true);
    setSelectedId(id);
  };

  const handleConfirmDelete = () => {
    deleteAppCategory(selectedId)
      .then((response) => {
        setData(data.filter((category) => category.id !== selectedId));
        setFeedback({
          severity: "success",
          message: "Category deleted successfully",
          state: true
        });
      })
      .catch((error) => {
        setFeedback({
          severity: "error",
          message: "Something went wrong",
          state: true
        });
      })
      .finally(() => {
        setIsDeleteConfirmationOpen(false);
      });
  };

  const handleEdit = (id) => {
    setOpenCategory(true);
    setSelectedId(id);
  };

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <SearchBar
          setSearchText={setSearchText}
          searchText={searchText}
          placeholder={"Search"}
        />
        <Button
          className="btn-primary-main-add"
          style={{ height: 45 }}
          onClick={handleClickOpen}
        >
          <Box className='btns-with-icon'>
            <AppsIcon sx={{ fontSize: "22px" }} />
            <Typography className="btns-with-icon-text">Add App Category</Typography>
          </Box>
        </Button>
      </div>
      <TableComponent
        headings={["id", "name"]}
        data={data}
        customColumnNames={{
          name: "Category Name"
        }}
        options={{ hideIds: true }}
        actions={[
          {
            label: "Edit",
            onClickHandler: handleEdit,
            hasParameter: true
          },
          {
            label: "Delete",
            onClickHandler: handleDelete,
            hasParameter: true
          }
        ]}
        styles={{
          table: { maxHeight: 380 }
        }}
      />
      {totalCount != 0 ? (
        <div className="pagination">
          <Pagination
            totalCount={totalCount}
            page={pageNumber}
            setPage={setPageNumber}
            rowsPerPage={pageSize}
            setRowsPerPage={setPageSize}
          />
        </div>
      ) : null}
      <AppCategoryForm
        openCategory={openCategory}
        setOpenCategory={setOpenCategory}
        setFeedback={setFeedback}
        selectedCategoryId={selectedId}
        setFetchCount={setFetchCount}
      />
      <CustomDialog
        open={isDeleteConfirmationOpen}
        title="Confirm Deletion"
        content="Are you sure you want to delete?"
        setOpen={setIsDeleteConfirmationOpen}
        handleConfirm={handleConfirmDelete}
        dialogType={DIALOG_TYPES.CONFIRM}
      />
    </div>
  );
}

export default AppBlockingCategories;

import { LOGIN_REQUEST, SUCCESS, FAILURE, LOGOUT_REQUEST } from "./authTypes";
import CookieService from "../../CookieService";
import { properties } from "../../../properties";
import api from "../../api";
import { getCustomer } from "../../../API/customer";

const accessTokenExpiresAt = 8 * 60 * 60 * 1000;
const refreshTokenExpiresAt = 6 * 24 * 60 * 60 * 1000;

const calculateExpiryDate = (expiresInMillis) => {
  const expiryDate = new Date();
  expiryDate.setTime(expiryDate.getTime() + expiresInMillis);
  return expiryDate;
};

const createTokenOptions = (expiresInMillis) => {
  return { path: "/", expires: calculateExpiryDate(expiresInMillis) };
};

export const authenticateUser = (email, password) => async (dispatch) => {
  try {
    const response = await api.post(`${properties.gateway}/auth/login`, {
      email: email,
      password: password
    });
    CookieService.set("user_id", response.data.customerId, createTokenOptions(refreshTokenExpiresAt));
    CookieService.set("access_token", response.data.token, createTokenOptions(accessTokenExpiresAt));
    CookieService.set("refresh_token", response.data.refreshToken, createTokenOptions(refreshTokenExpiresAt));
    const userResponse = await getCustomer();
    dispatch(success({ isLoggedIn: true, currentUser: userResponse.data }));
    return response.data;
  } catch (error) {
    dispatch(failure());
    return Promise.reject(error);
  }
};

export const isUserLoggedIn = () => async (dispatch) => {
  const dispatchCurrentUser = (currentUser, isLoggedIn) => {
    dispatch({
      type: "SET_CURRENT_USER",
      payload: { currentUser, isLoggedIn }
    });
  };

  return new Promise((resolve) => {
    const token = CookieService.get("refresh_token");

    if (!token) {
      dispatchCurrentUser({}, false);
      resolve(false);
    } else {
      getCustomer()
        .then((response) => {
          const currentUser = response.data;
          if (currentUser) {
            dispatchCurrentUser(currentUser, true);
            resolve(true);
          } else {
            dispatchCurrentUser({}, false);
            resolve(false);
          }
        })
        .catch((error) => {
          dispatchCurrentUser({}, false);
          resolve(false);
        });
    }
  });
};

export const updateToken = () => async (dispatch) => {
  return new Promise((resolve, reject) => {
    const prevRefreshToken = CookieService.get("refresh_token");
    if (!prevRefreshToken) {
      dispatch(logoutUser());
      reject();
    }
    api
      .post(`${properties.gateway}/auth/refreshtoken`, {
        refreshToken: prevRefreshToken
      })
      .then((response) => {
        const { accessToken, refreshToken } = response.data;
        CookieService.set("access_token", accessToken, createTokenOptions(accessTokenExpiresAt));
        CookieService.set("refresh_token", refreshToken, createTokenOptions(refreshTokenExpiresAt));
        resolve();
      })
      .catch((error) => {
        dispatch(logoutUser());
        reject(error);
      });
  });
};

export const logoutUser = () => async (dispatch) => {
  api
    .post(`${properties.gateway}/auth/signout`, {
      refreshToken: CookieService.get("refresh_token")
    })
    .finally(() => {
      CookieService.remove("access_token");
      CookieService.remove("user_id");
      CookieService.remove("refresh_token");
      dispatch(logoutRequest());
    });
};

export const loginRequest = () => {
  return {
    type: LOGIN_REQUEST
  };
};

const logoutRequest = () => {
  return {
    type: LOGOUT_REQUEST
  };
};

const success = (isLoggedIn) => {
  return {
    type: SUCCESS,
    payload: isLoggedIn
  };
};

const failure = () => {
  return {
    type: FAILURE,
    payload: false
  };
};

export const tokenRefreshingStatus = (status) => {
  return {
    type: "SET_REFRESH_STATUS",
    payload: status
  };
};

export const setAutoRefresh = (refreshStatus) => {
  return {
    type: "SET_AUTO_REFRESH",
    payload: refreshStatus
  }
}
import React, { useState, useEffect } from "react";
import {
  Breadcrumbs,
  Box,
  Typography,
  Divider,
  Tab,
  Tabs
} from "@mui/material";
import { fetchDeviceById } from "../../../API/devices";
import "../device-list.css";
import CustomTabPanel from "../../other/CustomTabPanel";
import DeviceGeneralInfo from "./DeviceGeneralInfo";
import DefaultProfileActionsTab from "./DeviceProfileActionsTab";
import DeviceGroupsAndPoliciesTab from "./DeviceGroupsAndPoliciesTab";
import DeviceInstalledApplicationTab from "./DeviceInstalledApplicationTab";
import DeviceReportList from "../../reporting/DeviceReportList";
import ApprovalRequestList from "../../approval_requests/ApprovalRequests";
import { Link } from "react-router-dom";

export default function DeviceProfile({ setFeedback, deviceId }) {
  const [value, setValue] = useState(0);
  const [deviceData, setDeviceData] = useState(null);
  useEffect(() => {
    fetchDeviceById(deviceId)
      .then((response) => {
        const selectedDevice = response.data;
        if (selectedDevice) {
          setDeviceData(selectedDevice);
        }
      })
      .catch((error) => {
        setFeedback({
          severity: "error",
          message: "There is an issue while fetching the device.",
          state: true
        });
      });
  }, [deviceId]);

  function props(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`
    };
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return deviceData != null ? (
    <div>
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/view-devices" style={{ textDecoration: "none" }}>
          <Typography>Devices</Typography>
        </Link>
        <Typography key="2">{deviceData.deviceName}</Typography>
      </Breadcrumbs>
      <br />
      <Divider />
      <br />
      <DeviceGeneralInfo
        deviceData={deviceData}
        setDeviceData={setDeviceData}
        setFeedback={setFeedback}
      />
      <br />

      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs">
            <Tab label="Actions" {...props(0)} />
            <Tab label="Associated Groups & Policies" {...props(1)} />
            <Tab label="Time Bound Approvals" {...props(2)} />
            <Tab label="Reports" {...props(3)} />
            <Tab label="Installed Applications" {...props(4)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <DefaultProfileActionsTab
            deviceData={deviceData}
            setDeviceData={setDeviceData}
            setFeedback={setFeedback}
          />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <DeviceGroupsAndPoliciesTab
            deviceId={deviceId}
            setFeedback={setFeedback}
          />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <ApprovalRequestList deviceId={deviceId} setFeedback={setFeedback} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          <DeviceReportList deviceId={deviceId} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={4}>
          <DeviceInstalledApplicationTab
            deviceId={deviceId}
            setFeedback={setFeedback}
          />
        </CustomTabPanel>
      </Box>
    </div>
  ) : (
    "Loading.."
  );
}

import { properties } from "../properties";
import api from "../services/api";

function fetchEmailTemplateList() {
  return api.get(
    `${properties.adminService}/email-templates/template-categories`
  );
}

function fetchEmailTemplate(templateId) {
  return api.get(`${properties.adminService}/email-templates/${templateId}`);
}

function createCustomTemplate(data) {
  return api.post(
    `${properties.adminService}/email-templates/custom-email-templates`,
    data
  );
}

function updateCustomEmailTemplate(templateCategoryId, data) {
  return api.patch(
    `${properties.adminService}/email-templates/${templateCategoryId}/template`,
    data
  );
}

function updateIsCustomTemplateUsed(templateCategoryId, data) {
  return api.patch(
    `${properties.adminService}/email-templates/${templateCategoryId}/is-custom-template-used`,
    data
  );
}

export {
  fetchEmailTemplateList,
  fetchEmailTemplate,
  updateCustomEmailTemplate,
  updateIsCustomTemplateUsed,
  createCustomTemplate
};

import { TextField, IconButton, Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";

export default function InputList({ list, setList, placeholder, buttonText }) {
  const handleDeleteElement = (ind) => {
    const updatedList = [...list];
    updatedList.splice(ind, 1);
    setList(updatedList);
  };
  const handleAddElement = () => {
    setList([...list, ""]);
  };
  return (
    <>
      <div>
        {list.map((item, index) => (
          <div className="input-list" key={index}>
            <TextField
              required
              size="small"
              placeholder={placeholder}
              className="form-textfield"
              fullWidth
              value={item}
              onChange={(ele) => {
                const updatedDomainList = [...list];
                updatedDomainList[index] = ele.target.value;
                setList(updatedDomainList);
              }}
            />
            &emsp;
            {list.length > 0 && (
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={() => handleDeleteElement(index)}
              >
                <DeleteIcon />
              </IconButton>
            )}
          </div>
        ))}
      </div>
      <Button
        type="button"
        onClick={() => handleAddElement()}
        startIcon={<AddCircleIcon />}
      >
        {buttonText}
      </Button>
    </>
  );
}

import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Button,
  Toolbar,
  IconButton,
  Stack,
  Divider,
  InputLabel,
  TextField
} from "@mui/material";
import { useEffect } from "react";
import FormShimmering from "../utility/FormShimmer";
import CloseIcon from "@mui/icons-material/Close";
import BeenhereOutlinedIcon from "@mui/icons-material/BeenhereOutlined";
import AddIcon from "@mui/icons-material/Add";
import { AddGlobalBlockedApp } from "../../API/globalBlockedApplications";

function ApplicationForm({
  setFeedback,
  setOpen,
  open,
  selectedApplicationId,
  setFetchCount
}) {
  let formType = "Add";

  if (selectedApplicationId !== "") formType = "Update";
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    formik.setValues(formik.initialValues);
  }, [open]);

  const validate = Yup.object({
    appName: Yup.string()
      .max(50, "Must be 50 characters or less")
      .required("Required")
  });

  const formik = useFormik({
    initialValues: {
      id: "",
      appName: ""
    },
    validationSchema: validate,
    onSubmit: (values, { resetForm }) => {
      submitForm(values, resetForm);
    }
  });

  function submitForm(values, resetForm) {
    AddGlobalBlockedApp(values.appName)
      .then((response) => {
        const message = formType === "Add" ? "added" : "updated";
        setFeedback({
          severity: "success",
          message: `Application ${message} Successfully`,
          state: true
        });
        setFetchCount((fetchCount) => fetchCount + 1);
        setOpen(false);
      })
      .catch(function (error) {
        setFeedback({
          severity: "error",
          message: error.response.data,
          state: true
        });
      });
  }

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div
      className={`drawer-form-wrapper ${
        open ? "drawer-form-open" : "drawer-form-closed"
      }`}
    >
      <Toolbar />
      <br />
      <div className="close-button">
        <IconButton onClick={handleClose} sx={{ padding: 0 }}>
          <CloseIcon />
        </IconButton>
      </div>
      <div className="drawer-form-container">
        {loading ? (
          <FormShimmering />
        ) : (
          <form onSubmit={formik.handleSubmit}>
            <h5>{formType} Application</h5>
            <Divider />

            <div className="form-input">
              <InputLabel
                htmlFor="appName"
                className="form-input-label"
                required
              >
                Application name
              </InputLabel>
              <TextField
                size="small"
                id="appName"
                placeholder="example.exe"
                className="form-textfield"
                fullWidth
                name="appName"
                value={formik.values.appName}
                onChange={formik.handleChange}
                error={formik.touched.appName && Boolean(formik.errors.appName)}
                helperText={formik.touched.appName && formik.errors.appName}
              />
            </div>

            <br />
            <Stack spacing={2} direction="row" justifyContent="flex-end">
              <Button
                className="primary-button-outlined"
                variant="outlined"
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                className="primary-button-filled"
                variant="contained"
                type="submit"
                startIcon={
                  formType == "Update" ? <BeenhereOutlinedIcon /> : <AddIcon />
                }
              >
                {formType}
              </Button>
            </Stack>
          </form>
        )}
      </div>
    </div>
  );
}

export default ApplicationForm;

import { properties } from "../properties";
import api from "../services/api";

function addUsbDetails(values) {
  return api.post(`${properties.adminService}/usb-details`, values);
}

function fetchUsbById(id) {
  return api.get(`${properties.adminService}/usb-details/${id}`);
}

function fetchUsbDetails(type, pageSize, pageNumber, searchText) {
  let url = `${properties.adminService}/usb-details?type=${type}`;
  if (pageSize && pageNumber != undefined) {
    url += `&pageSize=${pageSize}&pageNumber=${pageNumber}`;
  }
  if (searchText) {
    url += `&searchText=${searchText}`;
  }
  return api.get(url);
}

function updateUsbDetails(id, jsonPatch) {
  return api.patch(`${properties.adminService}/usb-details/${id}`, jsonPatch);
}

function deleteUsbDetails(id) {
  return api.delete(`${properties.adminService}/usb-details/${id}`);
}

function fetchUsbsByPolicyId(policyId) {
  return api.get(`${properties.adminService}/usb-details?policyId=${policyId}`);
}

export {
  fetchUsbDetails,
  addUsbDetails,
  fetchUsbById,
  updateUsbDetails,
  deleteUsbDetails,
  fetchUsbsByPolicyId
};

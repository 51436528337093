import React, { useEffect } from "react";
import { Editor } from "@tinymce/tinymce-react";
import tinymce from "tinymce/tinymce";
import "tinymce/models/dom/model";
import "tinymce/themes/silver";
import "tinymce/icons/default";
import "tinymce/skins/ui/oxide/skin.min.css";
import "tinymce/plugins/advlist";
import "tinymce/plugins/anchor";
import "tinymce/plugins/autolink";
import "tinymce/plugins/autoresize";
import "tinymce/plugins/autosave";
import "tinymce/plugins/charmap";
import "tinymce/plugins/code";
import "tinymce/plugins/codesample";
import "tinymce/plugins/directionality";
import "tinymce/plugins/emoticons";
import "tinymce/plugins/fullscreen";
import "tinymce/plugins/help";
import "tinymce/plugins/image";
import "tinymce/plugins/importcss";
import "tinymce/plugins/insertdatetime";
import "tinymce/plugins/link";
import "tinymce/plugins/lists";
import "tinymce/plugins/media";
import "tinymce/plugins/nonbreaking";
import "tinymce/plugins/pagebreak";
import "tinymce/plugins/preview";
import "tinymce/plugins/quickbars";
import "tinymce/plugins/save";
import "tinymce/plugins/searchreplace";
import "tinymce/plugins/table";
import "tinymce/plugins/template";
import "tinymce/plugins/visualblocks";
import "tinymce/plugins/visualchars";
import "tinymce/plugins/wordcount";
import "tinymce/plugins/emoticons/js/emojis";

/* eslint import/no-webpack-loader-syntax: off */
import contentCss from "!!raw-loader!tinymce/skins/content/default/content.min.css";
import contentUiCss from "!!raw-loader!tinymce/skins/ui/oxide/content.min.css";

export default function EmailTemplateEditor({
  prevContent,
  editorRef,
  isCustomTemplateUsed
}) {
  return (
    <>
      {!isCustomTemplateUsed ? (
        <Editor
          disabled={true}
          onInit={(evt, editor) => {
            editorRef.current = editor;
          }}
          initialValue={prevContent}
          init={{
            height: 500,
            menubar: false,
            plugins:
              "preview searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap emoticons accordion",
            toolbar:
              "undo redo | code fullscreen preview | accordion accordionremove | bold italic underline strikethrough | align numlist bullist | link image | table media | lineheight outdent indent | blocks fontfamily fontsize | forecolor backcolor removeformat | charmap emoticons | print | pagebreak anchor codesample | ltr rtl",
            toolbar_mode: "sliding",
            skin: false,
            content_css: false,
            content_style: [contentCss, contentUiCss || ""].join("\n"),
            statusbar: false
          }}
        />
      ) : (
        <>
          <Editor
            onInit={(evt, editor) => {
              editorRef.current = editor;
            }}
            initialValue={prevContent}
            init={{
              height: 500,
              menubar: false,
              plugins:
                "preview searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap emoticons accordion",
              toolbar:
                "undo redo | code fullscreen preview | accordion accordionremove | blocks fontfamily fontsize | bold italic underline strikethrough | align numlist bullist | link image | table media | lineheight outdent indent| forecolor backcolor removeformat | charmap emoticons | print | pagebreak anchor codesample | ltr rtl",
              toolbar_mode: "sliding",
              skin: false,
              content_css: false,
              content_style: [contentCss, contentUiCss || ""].join("\n"),
              statusbar: false
            }}
          />
        </>
      )}
    </>
  );
}

import React, { useEffect, useState } from "react";
import DevicesListPage from "./DeviceList";
import DeviceProfile from "./device_profile/DeviceProfile";
import { useLocation, useNavigate } from "react-router-dom";

export default function DevicePage({ setFeedback }) {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const deviceId = queryParams.get("id");

  return deviceId ? (
    <DeviceProfile setFeedback={setFeedback} deviceId={deviceId} />
  ) : (
    <DevicesListPage setFeedback={setFeedback} />
  );
}

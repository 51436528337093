import { useEffect } from "react";
import TableComponent from "../utility/Table";
import { useState } from "react";
import { getDeviceReportingList } from "../../API/reporting";
import { fetchAllDestinations } from "../../API/destination";
import MultiFilter from "../utility/MultiFilter";
import "./device-reports.css";
import PageTitle from "../utility/PageTitle";
import Pagination from "../utility/Pagination";

export default function DeviceReportList({deviceId}) {
  const [data, setData] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [searchText, setSearchText] = useState(deviceId?deviceId:"");
  const [openRows, setOpenRows] = useState([]);
  const [filterValuesList, setFilterValuesList] = useState({
    Destination: []
  });
  const [filtersSelectedValue, setFiltersSelectedValue] = useState({
    Destination: "all"
  });

  useEffect(() => {
    getDeviceReportingList(filtersSelectedValue.Destination, pageSize, pageNumber, searchText)
      .then(({ data }) => {
        const processedData = data.reportList.map((item) => {
          return {
            ...item,
            incidentDttm: new Date(item.incidentDttm).toLocaleString(),
            id: item.reportId,
            ruleName: item.ruleName == null ? "NA" : item.ruleName,
            policyName: item.policyName == null ? "NA" : item.policyName
          };
        });
        setTotalCount(data.totalItems);
        setData(processedData);
      })
      .catch((error) => console.log(error));
  }, [pageNumber, filtersSelectedValue, searchText,pageSize]);

  useEffect(() => {
    fetchAllDestinations()
      .then((response) => {
        const listWithoutEmail = response.data.filter((item) => item.id !== 2);
        const temp = listWithoutEmail.map((item) => ({
          filterId: item.id,
          filterValue: item.name
        }));
        setFilterValuesList((prevFilterValuesList) => ({
          ...prevFilterValuesList,
          Destination: temp
        }));
      })
      .catch((error) => console.log(error));
  }, []);

  const headings = [
    "id",
    "deviceName",
    "destinationName",
    "ruleName",
    "policyName",
    "risk",
    "incidentDttm"
  ];

  return (
    <div>
      {deviceId ? null : (
        <>
          <PageTitle />
          <br />
        </>
      )}
      <div
        style={{
          display: "flex",
          marginBottom: "20px",
          justifyContent: "space-between"
        }}
      >
        <MultiFilter
          filterValuesList={filterValuesList}
          filtersSelectedValue={filtersSelectedValue}
          setFiltersSelectedValue={setFiltersSelectedValue}
          showSearchBar={deviceId?false:true}
          setSearchText={setSearchText}
          searchText={searchText}
          placeholder="Search by Device Id"
          styles={{display:"flex"}}
        />
      </div>
      <TableComponent
        headings={headings}
        data={data}
        collapsibleDetails={{
          openRows,
          setOpenRows
        }}
        styles={{
          table: { maxHeight: 484 }
        }}
        customColumnNames={{
          id: "ID",
          incidentDttm: "Incident Time"
        }}
        options={{ hideIds: true }}
      />
      {totalCount != 0 ? (
        <div className="pagination">
          <Pagination
            totalCount={totalCount}
            page={pageNumber}
            setPage={setPageNumber}
            rowsPerPage={pageSize}
            setRowsPerPage={setPageSize}
          />
        </div>
      ) : null}
    </div>
  );
}

import { Typography } from "@mui/material";
import "./css/summary-card.css";

function SummaryCard({ heading, icon: Icon, count, onClickHandler }) {
  return (
    <div className="summary-card" onClick={onClickHandler}>
      <div className="left-section">
        <Typography className="heading" gutterBottom>
          {heading}
        </Typography>
        <div className="count">{count}</div>
      </div>
      <Icon className="icon" />
    </div>
  );
}

export default SummaryCard;

import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import "./active-filter.css";
import AssignFieldsDropdown from "../utility/AssignFieldsDropdown";
import { addDevicesToGroup, addUsersToGroup } from "../../API/group";
import { useLocation } from "react-router-dom";

function BulkAction({
  bulkActions,
  fetchAndFormatFields,
  fieldPlaceholder,
  fieldLabel,
  onSearch,
  setCurrentFields,
  selectedRecordId,
  setFeedback,
  handleBulkActionChange,
  resetComponent,
  setResetComponent
}) {
  const [deviceOrUserGroupIds, setDeviceOrUserGroupIds] = useState([]);
  const [currentSelectedBulkAction, setCurrentSelectedBulkAction] =
    useState("");
  const location = useLocation();
  const handleChangeSelectedBulkAction = (event) => {
    const action = event.target.value;
    setCurrentSelectedBulkAction(action);
    if (handleBulkActionChange) {
      handleBulkActionChange(action);
    }
  };

  useEffect(() => {
    if (resetComponent===true) {
      setResetComponent(false);
      setCurrentSelectedBulkAction("");
    }
  }, [resetComponent]);

  const handleClickGroup = () => {
    if (location.pathname == "/view-devices") {
      if (deviceOrUserGroupIds.length === 0) {
        setFeedback({
          severity: "error",
          message: "Please select at least one device group.",
          state: true
        });
        return;
      }
      const deviceIds = [];
      selectedRecordId.forEach((fieldId) => deviceIds.push(fieldId));
      const groupIds = deviceOrUserGroupIds;

      addDevicesToGroup(groupIds, deviceIds)
        .then(() => {
          const message = "added";
          setFeedback({
            severity: "success",
            message: `Devices ${message} successfully`,
            state: true
          });
        })
        .catch((error) => {
          setFeedback({
            severity: "error",
            message: "Something went wrong",
            state: true
          });
        });
    } else if (location.pathname == "/view-users") {
      if (deviceOrUserGroupIds.length === 0) {
        setFeedback({
          severity: "error",
          message: "Please select at least one user group.",
          state: true
        });
        return;
      }
      const userIds = Array.from(selectedRecordId);
      const groupIds = deviceOrUserGroupIds;

      addUsersToGroup(groupIds, userIds)
        .then(() => {
          const message = "added";

          setFeedback({
            severity: "success",
            message: `Users ${message} successfully`,
            state: true
          });
        })
        .catch((error) => {
          setFeedback({
            severity: "error",
            message: "Something went wrong",
            state: true
          });
        });
    }
  };

  return (
    <>
      <div style={{ display: "flex" }}>
        <div className="multi-filter-component" style={{ display: "flex" }}>
          <FormControl
            size="small"
            sx={{ width: "145px", marginRight: "10px" }}
            disabled={selectedRecordId.length === 0}
          >
            <InputLabel id="apply-filter-input-label">Bulk Action</InputLabel>
            <Select
              labelId="apply-filter-input-label"
              id="apply-filter-select"
              label="Bulk Action"
              value={currentSelectedBulkAction}
              onChange={handleChangeSelectedBulkAction}
              defaultValue=""
              disabled={selectedRecordId.length === 0}
              sx={{ textAlign: "left" }}
            >
              {bulkActions.map((key, index) => (
                <MenuItem value={key} key={index}>{key}</MenuItem>
              ))}
            </Select>
          </FormControl>
          {selectedRecordId.length > 0 && 
            currentSelectedBulkAction == "Assign To Group" && (
              <div style={{ display: "flex" }}>
                <div style={{ width: "250px", marginRight: "5px" }}>
                  <AssignFieldsDropdown
                    setFieldIds={setDeviceOrUserGroupIds}
                    fetchAndFormatFields={fetchAndFormatFields}
                    fieldPlaceholder={fieldPlaceholder}
                    fieldLabel={fieldLabel}
                    onSearch={onSearch}
                    setCurrentFields={setCurrentFields}
                  />
                </div>
                <div>
                  <Button variant="outlined" onClick={handleClickGroup}>
                    Add to Groups
                  </Button>
                </div>
              </div>
            )}
        </div>
      </div>
    </>
  );
}
export default BulkAction;

import React from "react";
import { Box, Typography, Button } from "@mui/material";
import NotFoundImage from "../assets/NotFoundImage.svg";

const PageNotFound = () => {
  return (
    <Box 
      display="flex" 
      flexDirection="column" 
      alignItems="center" 
      justifyContent="center" 
      height="80vh"
    >
      <Box mb={4}>
        <img src={NotFoundImage} alt="Page Not Found" />
      </Box>
      <Typography mb={2} mt={-5} style={{ fontSize: "23px"}}>
        Oops! The page you’re looking for doesn’t exist.
      </Typography>
      <Typography mb={3} style={{ fontSize: "16px" }}>
        It may have been moved or deleted. Please check the URL, or click the
        button below to return to the dashboard.
      </Typography>
      <Button
        className="primary-button-filled"
        variant="contained"
        href="/"
        sx={{
          padding: "12px 28px",
          borderRadius: "26px !important",
        }}
      >
        Go to Dashboard
      </Button>
    </Box>
  );
};

export default PageNotFound;

import { useEffect } from "react";
import TableComponent from "../utility/Table";
import { useState } from "react";
import { getRequestApprovalsList, revokeApproval } from "../../API/approval";
import { fetchAllDestinations } from "../../API/destination";
import "./approvalRequest.css";
import { APPROVAL_STATUS, DESTINATION_ID_TO_NAME } from "../../utils/Constants";
import PageTitle from "../utility/PageTitle";
import MultiFilter from "../utility/MultiFilter";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import PendingIcon from "@mui/icons-material/Pending";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import Pagination from "../utility/Pagination";
import { formatDateTimeString } from "../../utils/Helpers";
import TimeBoundApprovalFrom from "./TimeBoundApprovalForm";
import { Button } from "@mui/material";
import CalendarCheckIcon from "../assets/icons/CalenderCheckIcon";

export default function ApprovalRequestList({ deviceId, setFeedback }) {
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [devicedata, setDeviceData] = useState([]);
  const [isApprovalDialogOpen, setIsApprovalDialogOpen] = useState(false);
  const [openRows, setOpenRows] = useState([]);
  const [startTimeFilter, setStartTimeFilter] = useState();
  const [endTimeFilter, setEndTimeFilter] = useState();
  const [fetchCount, setFetchCount] = useState();

  const [filterValuesList, setFilterValuesList] = useState({
    Status: [
      {
        filterValue: APPROVAL_STATUS.ACTIVE,
        filterId: APPROVAL_STATUS.ACTIVE
      },
      {
        filterValue: APPROVAL_STATUS.REVOKED,
        filterId: APPROVAL_STATUS.REVOKED
      },
      {
        filterValue: APPROVAL_STATUS.UPCOMING,
        filterId: APPROVAL_STATUS.UPCOMING
      },
      {
        filterValue: APPROVAL_STATUS.EXPIRED,
        filterId: APPROVAL_STATUS.EXPIRED
      }
    ],
    Destination: []
  });

  const [filtersSelectedValue, setFiltersSelectedValue] = useState({
    Version: "all",
    Status: "all"
  });
  const [searchText, setSearchText] = useState(deviceId ? deviceId : "");

  useEffect(() => {
    fetchAllDestinations()
      .then((response) => {
        // Excluding destination email
        const data = response.data
          .filter(({ id }) => id !== 2)
          .map(({ id, name }) => ({ filterId: id, filterValue: name }));

        setFilterValuesList((prevFilterValuesList) => ({
          ...prevFilterValuesList,
          Destination: data
        }));
      })
      .catch((error) => {
        setFeedback({
          message: "There is an issue while fetching destinations.",
          severity: "error",
          state: true
        });
      });
  }, []);

  useEffect(() => {
    getRequestApprovalsList(
      pageSize,
      pageNumber,
      searchText,
      filtersSelectedValue.Status,
      filtersSelectedValue.Destination,
      startTimeFilter,
      endTimeFilter
    )
      .then(({ data: { approvalList, totalCount } }) => {
        setTotalCount(totalCount);
        const processedData = approvalList?.map((item, index) => {
          return {
            ...item,
            startTime: formatDateTimeString(item.startTime + "Z"),
            endTime: formatDateTimeString(item.endTime + "Z"),
            destination: DESTINATION_ID_TO_NAME[item.destinationId],
            details: {
              reason: item.reason,
              "Request Time": formatDateTimeString(item.creationDttm + "Z"),
              "Notified Email": item.recipient,
              ...(!deviceId ? { "Device Id": item.deviceId } : {})
            }
          };
        });

        const deviceMapWithDates = {};

        approvalList?.forEach((item) => {
          deviceMapWithDates[item.id] = { ...item };
        });

        setDeviceData(deviceMapWithDates);
        setData(processedData ? processedData : []);
      })
      .catch((error) => {
        setFeedback({
          message: error.response.data,
          severity: "error",
          state: true
        });
      });
  }, [
    pageNumber,
    fetchCount,
    filtersSelectedValue,
    searchText,
    pageSize,
    startTimeFilter,
    endTimeFilter
  ]);

  const handleRevoke = (id) => {
    revokeApproval(id)
      .then(() => {
        setData(
          data.map((item) => {
            if (item.id === id) item.status = APPROVAL_STATUS.REVOKED;
            return item;
          })
        );
        setFeedback({
          severity: "success",
          message: "Revoked Successfully.",
          state: true
        });
      })
      .catch((error) => {
        setFeedback({
          message: error.response.data,
          severity: "error",
          state: true
        });
      });
  };

  const getActionList = (id) => {
    let actions = [];
    if (
      devicedata[id] &&
      (devicedata[id].status == APPROVAL_STATUS.ACTIVE ||
        devicedata[id].status == APPROVAL_STATUS.UPCOMING)
    ) {
      actions = [
        {
          label: "Revoke",
          onClickHandler: handleRevoke,
          hasParameter: true
        }
      ];
    }
    return actions;
  };

  const chipsStyles = {
    status: {
      Active: {
        icon: (
          <CheckCircleIcon style={{ color: "#15803D", fontSize: "medium" }} />
        ),
        style: {
          color: "#15803D",
          backgroundColor: "#DCFCE7",
          borderRadius: "5px",
          fontWeight: "bold"
        }
      },
      Revoked: {
        icon: <CancelIcon style={{ color: "#B91C1C", fontSize: "medium" }} />,
        style: {
          color: "#B91C1C",
          backgroundColor: "#FEE2DC",
          borderRadius: "5px",
          fontWeight: "bold"
        }
      },
      Upcoming: {
        icon: <PendingIcon style={{ color: "#7B341E", fontSize: "medium" }} />,
        style: {
          color: "#7B341E",
          backgroundColor: "#FEEBC8",
          borderRadius: "5px",
          fontWeight: "bold"
        }
      },
      Expired: {
        icon: (
          <DoDisturbIcon style={{ color: "#7B341E", fontSize: "medium" }} />
        ),
        style: {
          color: "#374151",
          backgroundColor: "#F3F4F6",
          borderRadius: "5px",
          fontWeight: "bold"
        }
      }
    }
  };

  return (
    <div>
      {!deviceId ? (
        <div className="main-title-heading-container">
          <PageTitle subheading="Track and monitor the history of all approval activities" />
          <span>
            <Button
              variant="contained"
              onClick={() => setIsApprovalDialogOpen(true)}
              startIcon={<CalendarCheckIcon />}
              className="primary-button-filled"
            >
              Create Approval
            </Button>
          </span>
        </div>
      ) : null}
      <div
        style={{
          display: "flex",
          marginBottom: "20px",
          justifyContent: "space-between"
        }}
      >
        <MultiFilter
          filterValuesList={filterValuesList}
          filtersSelectedValue={filtersSelectedValue}
          setFiltersSelectedValue={setFiltersSelectedValue}
          showSearchBar={deviceId ? false : true}
          setSearchText={setSearchText}
          searchText={searchText}
          placeholder="Search"
          showTimeFilter={true}
          startTime={startTimeFilter}
          endTime={endTimeFilter}
          setStartTime={setStartTimeFilter}
          setEndTime={setEndTimeFilter}
        />
        {deviceId ? (
          <div>
            <Button
              variant="contained"
              onClick={() => setIsApprovalDialogOpen(true)}
              startIcon={<CalendarCheckIcon />}
              className="primary-button-filled"
            >
              Create Approval
            </Button>
          </div>
        ) : null}
      </div>

      <TableComponent
        headings={[
          "id",
          ...(deviceId ? [] : ["deviceName"]),
          "destination",
          "startTime",
          "endTime",
          "status"
        ]}
        styles={{ status: "chip" }}
        chipsStyles={chipsStyles}
        actions={[]}
        getActionListHandler={getActionList}
        customColumnNames={{
          deviceName: "Device Name",
          startTime: "Start Time",
          endTime: "End Time"
        }}
        data={data}
        options={{ hideIds: true }}
        collapsibleDetails={{ openRows, setOpenRows }}
      />
      {totalCount != 0 ? (
        <div className="pagination">
          <Pagination
            totalCount={totalCount}
            page={pageNumber}
            setPage={setPageNumber}
            rowsPerPage={pageSize}
            setRowsPerPage={setPageSize}
          />
        </div>
      ) : null}
      <TimeBoundApprovalFrom
        deviceId={deviceId}
        setFeedback={setFeedback}
        isApprovalDialogOpen={isApprovalDialogOpen}
        setIsApprovalDialogOpen={setIsApprovalDialogOpen}
        setFetchCount={setFetchCount}
      />
    </div>
  );
}

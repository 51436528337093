import {
  IconButton,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Chip
} from "@mui/material";

import DeleteIcon from "@mui/icons-material/Delete";
import FaviconFromDomain from "../assets/icons/FaviconFromDomain";

export default function SelectedList({
  setFeedback,
  data,
  field,
  selectedRecordId,
  setSelectedRecordId,
  icon,
  iconStyles,
  primaryText,
  secondaryText,
  newRecordList,
  setNewRecordList,
  showFavicon
}) {
  const removeFromList = (id) => {
    setSelectedRecordId(selectedRecordId.filter((value) => id !== value));
  };
  const removeFromNewList = (record) => {
    setNewRecordList(newRecordList.filter((value) => record !== value));
  };
  return (
    <List className="selected-list-wrapper" dense>
      {newRecordList?.map((newRecord) => {
        return (
          <>
            <Divider />
            <ListItem
              key={newRecord}
              sx={{ paddingLeft: "unset" }}
              secondaryAction={
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => {
                    removeFromNewList(newRecord);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              }
            >
              {icon ? (
                <ListItemAvatar className="list-item-avtar">
                  <Avatar alt="Icon" sx={iconStyles}>
                    {showFavicon ? (
                      <FaviconFromDomain domain={newRecord} />
                    ) : (
                       icon 
                    )}
                  </Avatar>
                </ListItemAvatar>
              ) : null}
              <ListItemText
                primary={<div className="list-item-text">{newRecord}</div>}
              />
            </ListItem>
          </>
        );
      }).reverse()}
      {selectedRecordId.map((id) => {
        let item = data.find((obj) => obj[field] == id);
        if (item) {
          return (
            <>
              <Divider />
              <ListItem
                key={id}
                sx={{ paddingLeft: "unset" }}
                secondaryAction={
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => {
                      removeFromList(id);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                }
              >
                {icon ? (
                  <ListItemAvatar className="list-item-avtar">
                    <Avatar alt="Icon" sx={iconStyles}>
                    {showFavicon ? (
                      <FaviconFromDomain domain={item[primaryText]} />
                    ) : (
                       icon 
                    )}
                    </Avatar>
                  </ListItemAvatar>
                ) : null}
                <ListItemText
                  primary={primaryText ? item[primaryText] : null}
                  secondary={secondaryText ? item[secondaryText] : null}
                />
              </ListItem>
            </>
          );
        }
      }).reverse()}
    </List>
  );
}

import { properties } from "../properties";
import api from "../services/api";

function fetchPasswordPolicyConfiguration() {
  return api.get(
    `${properties.adminService}/customer/password-policy-configurations`
  );
}

function updatePasswordPolicyConfigurationUsingPatch(jsonPatch) {
  return api.patch(
    `${properties.adminService}/customer/password-policy-configurations`,
    jsonPatch,
    {
      headers: {
        "Content-Type": "application/json-patch+json"
      }
    }
  );
}

export {
  fetchPasswordPolicyConfiguration,
  updatePasswordPolicyConfigurationUsingPatch
};

import React from "react";
import "./password-configuration.css";
export default function PasswordConfiguration({ config, setConfig }) {
  return (
    <div>
      <br />
      <h6 style={{ textAlign: "left", fontWeight: "bold" }}>
        Password Pattern
      </h6>
      <br />
      <div style={{ display: "flex" }}>
        <p className="textfield">Minimum Length</p>
        <input
          className="input"
          type="text"
          pattern="\d*"
          inputMode="numeric"
          value={config.password.passwordPattern.minLength || ""}
          onChange={(e) => {
            let updatedConfig = config;
            config.password.passwordPattern.minLength = e.target.value.replace(
              /\D/g,
              ""
            );
            setConfig({
              ...updatedConfig
            });
          }}
        />
      </div>
      <br />
      <div style={{ display: "flex" }}>
        <p className="repeats">Number of Repeats Allowed</p>
        <input
          className="input"
          type="text"
          pattern="\d*"
          inputMode="numeric"
          value={config.password.numberOfRepeatsAllowed || ""}
          onChange={(e) => {
            let updatedConfig = config;
            config.password.numberOfRepeatsAllowed = e.target.value.replace(
              /\D/g,
              ""
            );
            setConfig({
              ...updatedConfig
            });
          }}
        />
      </div>
      <p className="info">
        This is the number of times you <br />
        are allowed to repeat the password <br /> pattern.
      </p>
      <br />
      <div style={{ display: "flex" }}>
        <p className="textfield">Password Expiry</p>
        <input
          className="input"
          type="text"
          pattern="\d*"
          inputMode="numeric"
          value={config.password.expiryDays || ""}
          onChange={(e) => {
            let updatedConfig = config;
            config.password.expiryDays = e.target.value;
            setConfig({
              ...updatedConfig
            });
          }}
        />
      </div>

      <p className="info">Password expiration in days</p>
    </div>
  );
}

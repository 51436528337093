import { properties } from "../properties";
import api from "../services/api";

function create(values) {
  return api.post(`${properties.adminService}/classifications`, values);
}

function fetch(id) {
  return api.get(`${properties.adminService}/classifications/${id}`);
}

function update(values) {
  return api.put(
    `${properties.adminService}/classifications/${values.id}`,
    values
  );
}

function deleteClassification(id) {
  return api.delete(`${properties.adminService}/classifications/${id}`);
}

function fetchAllClassificationsByCustomerId() {
  return api.get(`${properties.adminService}/classifications`);
}
function fetchAllClassificationsByCustomerIdWithPagination(
  pageSize,
  pageNumber,
  searchText
) {
  return api.get(
    `${properties.adminService}/classifications?pageSize=${pageSize}&pageNumber=${pageNumber}&searchText=${searchText}`
  );
}

function fetchAllClassificationsByPolicyId(policyId) {
  return api.get(
    `${properties.adminService}/classifications/fetchAll?policyId=${policyId}`
  );
}

function updateIsEnabled(id, jsonPatch) {
  return api.patch(
    `${properties.adminService}/classifications/${id}`,
    [jsonPatch]
  );
}

export {
  create as createClassification,
  fetch as fetchClassification,
  update as updateClassification,
  deleteClassification,
  fetchAllClassificationsByCustomerId,
  fetchAllClassificationsByPolicyId,
  updateIsEnabled as updateIsEnabledForClassification,
  fetchAllClassificationsByCustomerIdWithPagination
};

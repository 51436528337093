import React from "react";
import { TextField, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

function SearchBar({ setSearchText, searchText, placeholder, fullWidth, onEnterAction, styles }) {

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      onEnterAction();
    }
  }
  return (
    <div className="search-box" style={styles}>
      <TextField
        fullWidth={fullWidth}
        size="small"
        type="text"
        placeholder={placeholder}
        value={searchText}
        onChange={(e) => setSearchText((e.target.value.trim()))}
        onKeyDown={handleKeyDown}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          )
        }}
      />
    </div>
  );
}
export default SearchBar;

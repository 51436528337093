import { useState, useEffect } from "react";
import TableComponent from "../../../utility/Table";
import { Button, Typography, Box } from "@mui/material";
import "./block-url.css";
import BlockCategoryForm from "./BlockUrlForm";
import {
  fetchSiteBlockingUrls,
  deleteSiteBlockingUrl,
  fetchAllSiteBlockingCategoriesByCustomerId
} from "../../../../API/httpBlocking";
import { updateIsEnabled } from "../../../../API/httpBlocking";
import { DIALOG_TYPES, JOB_NAMES } from "../../../../utils/Constants";
import CustomDialog from "../../../utility/Dialog";
import AddLinkIcon from "@mui/icons-material/AddLink";
import Pagination from "../../../utility/Pagination";
import MultiFilter from "../../../utility/MultiFilter";
import { UploadFile } from "@mui/icons-material";
import BulkUploadForm from "../../../bulk_upload/BulkUploadForm";

function BlockingCategories({ setFeedback }) {
  const [data, setData] = useState(null);
  const [categoriesList, setCategoriesList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [searchText, setSearchText] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isEnabled, setIsEnabled] = useState("all");
  const [openForm, setOpenForm] = useState(false);
  const [categoryFetchCount, setCategoryFetchCount] = useState(0);
  const [urlIdForDelete, SetUrlIdForDelete] = useState("");
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);
  const [filterValuesList, setFilterValuesList] = useState({
    Type: [
      { filterValue: "Default", filterId: true },
      { filterValue: "Custom", filterId: false }
    ],
    Status: [
      { filterValue: "Enabled", filterId: true },
      { filterValue: "Disabled", filterId: false }
    ]
  });
  const [filtersSelectedValue, setFiltersSelectedValue] = useState({
    Type: "all",
    Status: "all"
  });
  const [bulkFormOpen, setBulkFormOpen] = useState(false);

  useEffect(() => {
    fetchSiteBlockingUrls(
      searchText,
      pageSize,
      pageNumber,
      filtersSelectedValue.Status,
      filtersSelectedValue.Type
    )
      .then((response) => {
        setTotalCount(response.data.totalCount);
        setData(response.data.domains);
      })
      .catch((error) => {
        setFeedback({
          severity: "error",
          message: "Couldn't fetch the URLs list.",
          state: true
        });
      });
  }, [
    pageNumber,
    searchText,
    isEnabled,
    categoryFetchCount,
    filtersSelectedValue,
    pageSize
  ]);

  useEffect(() => {
    fetchAllSiteBlockingCategoriesByCustomerId()
      .then((response) => {
        setCategoriesList(response.data);
      })
      .catch((error) => {
        setFeedback({
          severity: "error",
          message: "Couldn't fetch the categories list.",
          state: true
        });
      });
  }, [categoryFetchCount]);

  const handleUrlDelete = (id) => {
    setIsDeleteConfirmationOpen(true);
    SetUrlIdForDelete(id);
  };

  const handleConfirmDelete = () => {
    deleteSiteBlockingUrl(urlIdForDelete)
      .then((response) => {
        const updatedData = data.filter((url) => url.id !== urlIdForDelete);
        setData(updatedData);
        setFeedback({
          severity: "success",
          message: "URL deleted successfully",
          state: true
        });
      })
      .catch((error) => {
        setFeedback({
          severity: "error",
          message: "There is an issue while deleting the url.",
          state: true
        });
      })
      .finally(() => {
        setIsDeleteConfirmationOpen(false);
        SetUrlIdForDelete("");
      });
  };

  const handleBlockMoreUrls = () => {
    setOpenForm(true);
    setSelectedCategory({});
  };

  const clearFilters = () => {
    setSearchText("");
    setIsEnabled("all");
  };

  const handleToggle = (id, event) => {
    const isChecked = event.target.checked;
    const message = isChecked
      ? "The domain has been whitelisted."
      : "The domain is removed from whitelist.";
    const jsonPatch = {
      op: "replace",
      path: "/isEnabled",
      value: isChecked
    };
    updateIsEnabled(id, jsonPatch)
      .then(() => {
        setData(
          data.map((url) => {
            if (url.id === id) url.isEnabled = isChecked;
            return url;
          })
        );
        setFeedback({
          severity: "success",
          message: message,
          state: true
        });
      })
      .catch((error) => {
        setFeedback({
          severity: "error",
          message: "Something went wrong",
          state: true
        });
      });
  };

  const chipOnClickHandler = (categoryId) => {
    setSelectedCategory(
      categoriesList.find((category) => category.id === categoryId)
    );
    setOpenForm(true);
  };

  const checkIsUrlEnabled = (id) => {
    return data.find((url) => url.id === id).isEnabled;
  };

  const headings = ["id", "url", "categories", "IsEnabled"];

  return (
    <div>
      <div style={{ float: "right", marginBottom: "15px" }}>
        <Button
          className="primary-button-outlined"
          onClick={() => setBulkFormOpen(true)}
          startIcon={<UploadFile />}
          sx={{ marginRight: 2 }}
        >
          Bulk Upload
        </Button>
        <Button
          className="btn-primary-main-add"
          variant="contained"
          onClick={handleBlockMoreUrls}
          startIcon={<AddLinkIcon />}
        >
          Add Domain
        </Button>
      </div>
      <div className="blockcategories">
        <div style={{ display: "flex" }}>
          <MultiFilter
            filterValuesList={filterValuesList}
            filtersSelectedValue={filtersSelectedValue}
            setFiltersSelectedValue={setFiltersSelectedValue}
            showSearchBar={true}
            setSearchText={setSearchText}
            searchText={searchText}
            placeholder="Search by Domain"
          />
        </div>
      </div>
      <BlockCategoryForm
        openForm={openForm}
        setOpenForm={setOpenForm}
        setFeedback={setFeedback}
        categoriesList={categoriesList}
        setCategoryFetchCount={setCategoryFetchCount}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
      />
      <TableComponent
        headings={headings}
        data={data}
        options={{
          hideIds: true,
          maxChips: 3,
          containsDefault: true,
          showFavicon: true,
          defaultColumn: "url"
        }}
        styles={{
          url: "text-with-favicon"
        }}
        actions={[
          {
            label: "Delete",
            onClickHandler: handleUrlDelete,
            hasParameter: true
          }
        ]}
        toggleAction={{
          toggleStatusChecker: checkIsUrlEnabled,
          onChangeHandler: handleToggle
        }}
        chipOnClickHandler={chipOnClickHandler}
        customColumnNames={{
          url: "Domain",
          id: "ID",
          IsEnabled: "Whitelisted"
        }}
      />
      <BulkUploadForm
        setFeedback={setFeedback}
        open={bulkFormOpen}
        setOpen={setBulkFormOpen}
        jobName={JOB_NAMES.IMPORT_DOMAIN_AND_CATEGORIES}
      />
      
      <CustomDialog
        open={isDeleteConfirmationOpen}
        title={" Are you sure you want to delete?"}
        content={
          "This will delete the domain from associated categories and policies."
        }
        setOpen={setIsDeleteConfirmationOpen}
        handleConfirm={handleConfirmDelete}
        dialogType={DIALOG_TYPES.CONFIRM}
      />
      {totalCount != 0 ? (
        <div className="pagination">
          <Pagination
            totalCount={totalCount}
            page={pageNumber}
            setPage={setPageNumber}
            rowsPerPage={pageSize}
            setRowsPerPage={setPageSize}
          />
        </div>
      ) : null}
    </div>
  );
}
export default BlockingCategories;

import React from "react";
import "./password-configuration.css";
export default function PasswordConfiguration({ config, setConfig }) {
  return (
    <div>
      <h6 style={{ textAlign: "left", fontWeight: "bold" }}>Pin Pattern</h6>
      <div style={{ display: "flex" }}>
        <input
          type="checkbox"
          style={{ marginRight: "15px", transform: "scale(1.5)" }}
          checked={config.pin.pinPattern.requireLowerCase === true}
          onChange={(e) => {
            let updatedConfig = config;
            config.pin.pinPattern.requireLowerCase = e.target.checked;
            setConfig({
              ...updatedConfig
            });
          }}
        />
        <p className="checkboxes">
          Require at least one lower case letter (a-z)
        </p>
      </div>

      <div style={{ display: "flex" }}>
        <input
          type="checkbox"
          style={{ marginRight: "15px", transform: "scale(1.5)" }}
          checked={config.pin.pinPattern.requireUpperCase}
          onChange={(e) => {
            let updatedConfig = config;
            config.pin.pinPattern.requireUpperCase = e.target.checked;
            setConfig({
              ...updatedConfig
            });
          }}
        />
        <p className="checkboxes">
          Require at least one upper case letter (A-Z)
        </p>
      </div>
      <div style={{ display: "flex" }}>
        <input
          type="checkbox"
          style={{ marginRight: "15px", transform: "scale(1.5)" }}
          checked={config.pin.pinPattern.requireDigits}
          onChange={(e) => {
            let updatedConfig = config;
            config.pin.pinPattern.requireDigits = e.target.checked;
            setConfig({
              ...updatedConfig
            });
          }}
        />
        <p className="checkboxes">Require at least one number (0-9)</p>
      </div>

      <div style={{ display: "flex" }}>
        <input
          className="checkbox"
          type="checkbox"
          style={{ marginRight: "15px", transform: "scale(1.5)" }}
          checked={config.pin.pinPattern.requireSpecialCharacters}
          onChange={(e) => {
            let updatedConfig = config;
            config.pin.pinPattern.requireSpecialCharacters = e.target.checked;
            setConfig({
              ...updatedConfig
            });
          }}
        />
        <p className="checkboxes">
          Require at least one special character (@%+/’!#$?:(){}[]~-_.)
        </p>
      </div>

      <br />
      <div style={{ display: "flex" }}>
        <p className="textfields">Maximum Length</p>
        <input
          className="input"
          type="text"
          pattern="\d*"
          inputMode="numeric"
          value={config.pin.pinPattern.maxLength || ""}
          onChange={(e) => {
            let updatedConfig = config;
            config.pin.pinPattern.maxLength = e.target.value.replace(/\D/g, "");
            setConfig({
              ...updatedConfig
            });
          }}
        />
      </div>
      <br />
      <div style={{ display: "flex" }}>
        <p className="textfield">Minimum Length</p>
        <input
          className="input"
          type="text"
          pattern="\d*"
          inputMode="numeric"
          value={config.pin.pinPattern.minLength || ""}
          onChange={(e) => {
            let updatedConfig = config;
            config.pin.pinPattern.minLength = e.target.value.replace(/\D/g, "");
            setConfig({
              ...updatedConfig
            });
          }}
        />
      </div>
      <br />
      <div style={{ display: "flex" }}>
        <p className="repeats">Number of Repeats Allowed</p>
        <input
          className="input"
          type="text"
          pattern="\d*"
          inputMode="numeric"
          value={config.pin.numberOfRepeatsAllowed || ""}
          onChange={(e) => {
            let updatedConfig = config;
            config.pin.numberOfRepeatsAllowed = e.target.value;
            setConfig({
              ...updatedConfig
            });
          }}
        />
      </div>
      <p className="info">
        This is the number of times you <br />
        are allowed to repeat the password <br /> pattern.
      </p>
      <br />
      <div style={{ display: "flex" }}>
        <p style={{ textAlign: "left", marginRight: "180px" }}>Pin Expiry</p>
        <input
          className="input"
          type="text"
          pattern="\d*"
          inputMode="numeric"
          value={config.pin.expiryDays || ""}
          onChange={(e) => {
            let updatedConfig = config;
            config.pin.expiryDays = e.target.value;
            setConfig({
              ...updatedConfig
            });
          }}
        />
      </div>
      <p className="info">Password expiration in days</p>
    </div>
  );
}

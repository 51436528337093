import { useEffect, useState } from "react";
import TableComponent from "../utility/Table";
import ClassificationForm from "./ClassificationForm";
import {
  Switch as MuiSwitch,
  TableCell,
  Button,
  Box,
  Typography
} from "@mui/material";
import { styled } from "@mui/system";
import {
  deleteClassification,
  fetchAllClassificationsByCustomerIdWithPagination,
  updateIsEnabledForClassification
} from "../../API/classification";
import { useSelector } from "react-redux";
import PageTitle from "../utility/PageTitle";
import SearchBar from "../utility/SearchBar";
import { DIALOG_TYPES } from "../../utils/Constants";
import CustomDialog from "../utility/Dialog";
import DeviceHubIcon from "@mui/icons-material/DeviceHub";
import Pagination from "../utility/Pagination";

const label = { inputProps: { "aria-label": "Switch demo" } };

function ClassificationList({ setFeedback }) {
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(false);
  const [data, setData] = useState(null);
  const [fetchCount, setFetchCount] = useState(0);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);
  const user = useSelector(({ auth }) => auth.currentUser);
  const [searchText, setSearchText] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    fetchAllClassificationsByCustomerIdWithPagination(
      pageSize,
      pageNumber,
      searchText
    )
      .then((response) => {
        const processedData = response.data.classificationList.map((item) => {
          return {
            ...item,
            lastUpdated: new Date(item.lastUpdated).toLocaleString()
          };
        });
        setData(processedData);
        setTotalCount(response.data.totalCount);
      })
      .catch((error) => {
        setFeedback({
          severity: "error",
          message: "There is an issue while fetching classification!",
          state: true
        });
      });
  }, [fetchCount, pageNumber, searchText, pageSize]);

  const handleClickOpen = () => {
    setOpen(true);
    setSelectedId(null);
  };
  const handleDelete = (id) => {
    setSelectedId(id);
    setIsDeleteConfirmationOpen(true);
  };
  const handleConfirmDelete = () => {
    deleteClassification(selectedId)
      .then(() => {
        setData(
          data.filter((classification) => classification.id !== selectedId)
        );
        setFeedback({
          severity: "success",
          message: "Classification deleted successfully",
          state: true
        });
      })
      .catch(() => {
        setFeedback({
          severity: "error",
          message: "Something went wrong",
          state: true
        });
      })
      .finally(() => {
        setIsDeleteConfirmationOpen(false);
        setSelectedId(null);
      });
  };

  const handleView = (id) => {
    setOpen(true);
    setSelectedId(id);
  };

  function handleUpdateActiveStatus(id, event) {
    const jsonPatch = {
      op: "replace",
      path: "/isActive",
      value: event.target.checked
    };
    const isChecked = event.target.checked;
    let message = "";
    if (isChecked) message = "Activated";
    else message = "Deactivated";
    updateIsEnabledForClassification(id, jsonPatch)
      .then((response) => {
        setData(
          data.map((classification) => {
            if (classification.id === id) classification.isActive = isChecked;
            return classification;
          })
        );
        setFeedback({
          severity: "success",
          message: `Classification ${message}`,
          state: true
        });
      })
      .catch((error) =>
        setFeedback({
          severity: "error",
          message: "Something went wrong",
          state: true
        })
      );
  }

  const Switch = styled((props) => (
    <MuiSwitch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 24,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 3,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#0d6efd" : "#0d6efd",
          opacity: 1,
          border: 0
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5
        }
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff"
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600]
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3
      }
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 17,
      height: 17
    },
    "& .MuiSwitch-track": {
      borderRadius: "15px",
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500
      })
    }
  }));

  const headings = [
    "id",
    "name",
    "description",
    "lastUpdated"
    // "IsEnabled",
  ];

  return (
    <div>
      <div className="main-title-heading-container">
        <PageTitle subheading="Establish classifications to categorize sensitive data" />
        <div style={{ display: "flex", justifyContent: "right" }}>
          <Button
            className="btn-primary-main-add"
            style={{ height: 45 }}
            onClick={handleClickOpen}
          >
            <Box className="btns-with-icon">
              <DeviceHubIcon />
              <Typography className="btns-with-icon-text">
                {" "}
                Add Classification{" "}
              </Typography>
            </Box>
          </Button>
        </div>
      </div>
      <div style={{ display: "flex" }}>
        <SearchBar
          setSearchText={setSearchText}
          searchText={searchText}
          placeholder={"Search by Name"}
        />
      </div>
      <TableComponent
        headings={headings}
        data={data}
        actions={[
          {
            label: "Edit",
            onClickHandler: handleView,
            hasParameter: true
          },
          {
            label: "Delete",
            onClickHandler: handleDelete,
            hasParameter: true
          }
        ]}
        options={{ hideIds: true }}
        styles={{
          table: { maxHeight: 480 }
        }}
      />
      {totalCount != 0 ? (
        <div className="pagination">
          <Pagination
            totalCount={totalCount}
            page={pageNumber}
            setPage={setPageNumber}
            rowsPerPage={pageSize}
            setRowsPerPage={setPageSize}
          />
        </div>
      ) : null}
      <ClassificationForm
        open={open}
        setOpen={setOpen}
        setFeedback={setFeedback}
        selectedClassificationId={selectedId}
        setFetchCount={setFetchCount}
      />
      <CustomDialog
        open={isDeleteConfirmationOpen}
        title="Confirm Deletion"
        content="Are you sure you want to delete?"
        setOpen={setIsDeleteConfirmationOpen}
        handleConfirm={handleConfirmDelete}
        dialogType={DIALOG_TYPES.CONFIRM}
      />
    </div>
  );
}

export default ClassificationList;

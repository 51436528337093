import { properties } from "../properties";
import api from "../services/api";

function getPaginatedStatusReports({
  agentStatus,
  searchText,
  pageSize,
  pageNumber,
  startDttm,
  endDttm
}) {
  let url = `${properties.reportingService}/agent-status-report?searchText=${searchText}&pageSize=${pageSize}&pageNumber=${pageNumber}`;

  if (startDttm && startDttm.isValid()) {
    url += `&startDttm=${new Date(startDttm).toISOString()}`;
  }
  if (endDttm && endDttm.isValid()) {
    url += `&endDttm=${new Date(endDttm).toISOString()}`;
  }
  if (agentStatus) {
    url += `&agentStatus=${agentStatus}`;
  }
  

  return api.get(url);
}

export { getPaginatedStatusReports };

import React from "react";
import { Chip, Divider, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import { routeHeadings } from "../../utils/data";

function PageTitle({subheading}) {
  const location = useLocation();
  return (
    <div className="page-title">
    <Typography
      variant="h6"
      component="div"
      sx={{
        flexGrow: 1,
        textAlign: "left",
        color: "#303030",
        fontSize: "22px",
        fontWeight: "600"
      }}
    >
      {routeHeadings[location.pathname]}
      {location.pathname === "/clipboard-policy" || location.pathname === "/screenshot-policy" ? (
        <Chip
          label="BETA"
          size="small"
          sx={{ fontFamily: "DM Sans", fontSize: "11px", margin: 1, marginBottom:3 }}
        />
      ) : null}
    </Typography>
    <div className="sub-heading">{subheading}</div>
    </div>
  );
}

export default PageTitle;

import {
  Button,
  Stack,
  Chip,
  List,
  ListItem,
  ListItemText,
  ListItemIcon
} from "@mui/material";
import "./organizational-domains.css";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useEffect, useState } from "react";
import { getIconComponentByName } from "./OrganizationalDomainIcons";
import LanguageIcon from "@mui/icons-material/Language";

export default function CustomAccordian({
  domain,
  isDefault,
  setOpenForm,
  setSelectedOrganizationDomain,
  setIsDefault,
  setIsDeleteConfirmationOpen
}) {
  const [domainList, setDomainList] = useState([]);

  useEffect(() => {
    if (domain.domains) setDomainList(domain.domains);
  }, [domain]);

  const IconComponent = getIconComponentByName(domain.name);

  const handleEdit = () => {
    setIsDefault(isDefault);
    setSelectedOrganizationDomain(domain);
    setOpenForm(true);
  };
  const handleDelete = () => {
    setSelectedOrganizationDomain(domain);
    setIsDeleteConfirmationOpen(true);
  };

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <div className="summary-head">
          <IconComponent />
          &emsp;
          <div className="summary-text">
            <span className="summary-head">
              {domain.name} &nbsp;
              {isDefault ? (
                <Chip
                  label="Default"
                  color="primary"
                  sx={{ fontSize: "0.7125rem", height: "20px" }}
                  variant="outlined"
                />
              ) : null}
            </span>
            {!isDefault ? (<span className="helper-text">URL : {domain.url}</span>) :null }
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div>
          {domainList.length > 0 ? (
            <div>
              <div>Allowed Domains :</div>
              <List dense>
                {domainList.map((domain, index) => (
                  <ListItem key={index}>
                    <ListItemIcon>
                      <LanguageIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary={domain} />
                  </ListItem>
                ))}
              </List> 
            </div>
          ) : (
            <div className="helper-text">No Domains Added</div>
          )}
        </div>
        <br />
        <Stack spacing={2} direction="row" justifyContent="flex-start">
          <Button
            className="primary-button-filled"
            variant="contained"
            onClick={handleEdit}
            size="small"
          >
            Edit
          </Button>
          {!isDefault ? (
            <Button
              variant="outlined"
              color="error"
              size="small"
              onClick={handleDelete}
            >
              Delete
            </Button>
          ) : null}
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
}

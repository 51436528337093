export default function RedCircle({style}) {
  return (
    <svg
      width="14px"
      height="14px"
      viewBox="0 0 128 128"
      aria-hidden="true"
      role="img"
      preserveAspectRatio="xMidYMid meet"
      fill="#000000"
      style={style}
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        <circle cx="63.93" cy="64" r="60" fill="#c33"></circle>
      </g>
    </svg>
  );
}

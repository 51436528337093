import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";

export default function CustomDateTimePicker({ label, time, onChangeHandler, error }) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateTimePicker
        label={label}
        value={time ? dayjs(time) : null}
        onChange={onChangeHandler}
        className="date-time-picker"
        slotProps={{
          actionBar: {
            actions: ["clear", "accept"]
          },
          textField: {
            error: error
          },
        }}
      />
    </LocalizationProvider>
  );
}

import { TableCell, TableRow, styled } from "@mui/material";
import { capitalizefirstLetter } from "../../utils/Helpers";
import "./table.css";
import "./row-details.css";

export default function RowDetailsComponent({
  row,
  openRows,
  numberOfColumns
}) {
  return (
    <>
      {openRows.includes(row.id) ? (
        <TableRow>
          <TableCell colSpan={numberOfColumns + 1}>
            <div className="additionaltable">
              {row.details && Object.keys(row.details).length > 0 ? (
                <>
                  <div className="additional-details">
                      {Object.entries(row.details).map(
                        ([key, value], index) => (
                          <div key={index} style={{ marginBottom: "5px" }} className="detail-block">
                            <span className="detail-heading">
                              {capitalizefirstLetter(key)} :{" "}
                            </span>
                            {value}
                          </div>
                        )
                      )}
                  </div>
                </>
              ) : (
                <p className="no-additional-details">
                  No additional details available.
                </p>
              )}
            </div>
          </TableCell>
        </TableRow>
      ) : null}
    </>
  );
}

export default function WindowsIcon() {
    return (
      <svg height="100px" width="100px" viewBox="0 0 512 512" fill="#000000">
        <g strokeWidth="0"></g>
        <g strokeLinecap="round" strokeLinejoin="round"></g>
        <g>
          <circle style={{ fill: "#1976d2" }} cx="256" cy="256" r="256"></circle>
          <g>
            <path
              style={{ fill: "#fff" }}
              d="M232,246.516c0-38.996,0-77.988,0-116.984c-40,6.324-72.536,12.624-112,18.944 c0,39.78,0,59.672,0,99.456C159.464,247.472,192,246.98,232,246.516z"
            ></path>
            <path
              style={{ fill: "#fff" }}
              d="M248,246.284c40-0.48,88.536-0.936,128-1.42c0-45.78,0-91.564,0-137.344 c-39.464,6.324-88,12.62-128,18.948C248,166.408,248,206.344,248,246.284z"
            ></path>
            <path
              style={{ fill: "#fff" }}
              d="M249.6,265.72c-0.016,39.936,0,79.876,0,119.816c39.46,6.324,86.928,12.624,126.4,18.944 c0-45.78,0-91.568,0-137.344C336.536,266.668,289.072,266.192,249.6,265.72z"
            ></path>
            <path
              style={{ fill: "#fff" }}
              d="M232,265.484c-40-0.476-72.536-0.944-112-1.42c0,39.784,0,59.672,0,99.456 c39.464,6.328,72,12.62,112,18.944C232,343.472,232,304.48,232,265.484z"
            ></path>
          </g>
        </g>
      </svg>
    );
  }

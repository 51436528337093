import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useState, useEffect } from "react";
import {
  fetchPasswordPolicyConfiguration,
  updatePasswordPolicyConfigurationUsingPatch
} from "../../API/passwordConfiguration";
import "./password-configuration.css";
import PasswordConfig from "./PasswordConfiguration";
import PinConfig from "./PinConfiguration";
import PageTitle from "../utility/PageTitle";

export default function PasswordConfiguration({ setFeedback }) {
  const [config, setConfig] = useState({
    password: {
      passwordPattern: {
        maxLength: 0,
        minLength: 0
      },
      numberOfRepeatsAllowed: 0,
      expiryDays: 0
    },
    pin: {
      pinPattern: {
        requireDigits: true,
        requireUpperCase: true,
        requireLowerCase: true,
        requireSpecialCharacters: true,
        maxLength: 0,
        minLength: 0
      },
      numberOfRepeatsAllowed: 0,
      expiryDays: 0
    }
  });
  useEffect(() => {
    fetchPasswordPolicyConfiguration()
      .then((response) => {
        setConfig(response.data);
      })
      .catch((error) => {
        console.error("Error fetching password policy configuration:", error);
      });
  }, []);

  const updateConfiguration = () => {
    updatePasswordPolicyConfigurationUsingPatch(config)
      .then((response) => {
        setFeedback({
          severity: "success",
          message: "Password configuration updated successfully.",
          state: true
        });
      })
      .catch((error) => {
        setFeedback({
          severity: "error",
          message: "Error updating password configuration. Please try again.",
          state: true
        });
      });
  };

  return (
    <div>
      {/* <PageTitle /> */}
      <PasswordConfig config={config} setConfig={setConfig} />
      <hr style={{ margin: "30px 0px", border: "0.5px solid #ccc" }} />
      <PinConfig config={config} setConfig={setConfig} />
      <br />
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start"
        }}
      >
        <Button
          variant="contained"
          className="primary-button-filled"
          onClick={updateConfiguration}
        >
          Update
        </Button>
      </Box>
    </div>
  );
}

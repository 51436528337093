import * as React from "react";
import Button from "@mui/material/Button";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { CRITERIA_TYPE } from "../../utils/Constants";
import {
  IconButton,
  Toolbar,
  Divider,
  InputLabel,
  TextField,
  Stack,
  Select,
  MenuItem,
  Box
} from "@mui/material";
import { useEffect } from "react";
import { useRef } from "react";
import * as Yup from "yup";
import { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { createRule, fetchRule, updateRule } from "../../API/rule";
import FormShimmering from "../utility/FormShimmer";
import "./rule.css";
import CloseIcon from "@mui/icons-material/Close";
import BeenhereOutlinedIcon from "@mui/icons-material/BeenhereOutlined";
import AddIcon from "@mui/icons-material/Add";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import DeviceHubIcon from "@mui/icons-material/DeviceHub";

function RuleForm({
  openRule,
  setOpenRule,
  setFeedback,
  customRuleList,
  setCustomRuleList,
  selectedRule,
  selectedRuleId,
  setFetchCount,
  isDefault
}) {
  const [criteriaList, setCriteriaList] = useState([]);
  const [loading, setLoading] = useState(true);
  let formType;

  if (customRuleList !== undefined) formType = "Save";
  else if (selectedRuleId) formType = "Update";
  else formType = "Add";

  const handleClose = () => {
    setOpenRule(false);
    setCriteriaList([
      {
        criteriaType: "",
        criteriaValue: ""
      }
    ]);
  };

  const handleAddElement = () => {
    setCriteriaList([
      ...criteriaList,
      {
        criteriaType: "",
        criteriaValue: ""
      }
    ]);
  };

  const handleDeleteElement = (ind) => {
    const updatedCriteriaList = [...criteriaList];
    updatedCriteriaList.splice(ind, 1);
    setCriteriaList(updatedCriteriaList);
  };

  const validate = Yup.object({
    name: Yup.string().required("Required")
  });

  useEffect(() => {
    setLoading(true);
    if (openRule && formType === "Update") {
      fetchRule(selectedRuleId, isDefault)
        .then((response) => {
          const { criteriaList, ...formikValues } = response.data;
          formik.setValues(formikValues);
          setCriteriaList(criteriaList);
        })
        .catch((error) =>
          setFeedback({
            severity: "error",
            message: `There is an issue while fetching rule.`,
            state: true
          })
        )
        .finally(() => {
          setLoading(false);
        });
    } else if (selectedRule) {
      formik.setValues(selectedRule);
      setCriteriaList(selectedRule.criteriaList);
      setLoading(false);
    } else {
      formik.resetForm();
      setCriteriaList([
        {
          criteriaType: "",
          criteriaValue: ""
        }
      ]);
      setLoading(false);
    }
  }, [formType, selectedRule, selectedRuleId, openRule]);

  function submitForm(values, resetForm) {
    if (customRuleList != undefined) {
      if (!selectedRule) {
        setCustomRuleList([
          ...customRuleList,
          { ...values, criteriaList: criteriaList, isDictionaryRule: false }
        ]);
      } else if (selectedRule.id === "") {
        setCustomRuleList(
          customRuleList.map((customRule) => {
            if (customRule.name === selectedRule.name)
              return {
                ...values,
                criteriaList: criteriaList,
                isDictionaryRule: false
              };
            else return customRule;
          })
        );
      } else {
        setCustomRuleList(
          customRuleList.map((customRule) => {
            if (customRule.id === selectedRule.id)
              return {
                ...values,
                criteriaList: criteriaList,
                isDictionaryRule: false
              };
            else return customRule;
          })
        );
      }
    } else {
      const formValues = {
        ...values,
        criteriaList: criteriaList,
        isDictionaryRule: true
      };
      let promise;
      if (formType === "Add") {
        promise = createRule(formValues);
      } else {
        promise = updateRule(formValues);
      }
      promise
        .then((response) => {
          setFeedback({
            severity: "success",
            message: `Rule added successfully`,
            state: true
          });
          setFetchCount((fetchCount) => fetchCount + 1);
        })
        .catch((error) =>
          setFeedback({
            severity: "error",
            message: `Something Went Wrong`,
            state: true
          })
        );
    }
    setOpenRule(false);
  }

  const formik = useFormik({
    initialValues: {
      id: "",
      name: ""
    },
    validationSchema: validate,
    onSubmit: (values, { resetForm }) => {
      submitForm(values, resetForm);
    }
  });

  return (
    <div
      className={`drawer-form-wrapper ${
        openRule ? "drawer-form-open" : "drawer-form-closed"
      }`}
    >
      <Toolbar />
      <br />
      {customRuleList !== undefined && (
        <div className="drawer-form-container">
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              underline="hover"
              key="1"
              color="inherit"
              onClick={handleClose}
            >
              <Box className="btns-with-icon">
                <DeviceHubIcon />
                <Typography className="btns-with-icon-text">
                  Add Classification
                </Typography>
              </Box>
            </Link>
            <Typography key="2" color="#9e9e9e">
              Save Rule
            </Typography>
          </Breadcrumbs>
        </div>
      )}
      <div className="close-button">
        <IconButton onClick={handleClose} sx={{ padding: 0 }}>
          <CloseIcon />
        </IconButton>
      </div>
      <div className="drawer-form-container">
        {loading ? (
          <FormShimmering />
        ) : (
          <form onSubmit={formik.handleSubmit}>
            <h5>{isDefault ? "" : formType} Rule</h5>
            <Divider />
            <div className="form-input">
              <InputLabel
                htmlFor="rule-name"
                className="form-input-label"
                required
              >
                Rule Name
              </InputLabel>
              <TextField
                slotProps={{
                  input: {
                    readOnly: isDefault,
                  },
                }}
                size="small"
                id="rule-name"
                placeholder="Name"
                className="form-textfield"
                fullWidth
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
            </div>
            <div>
              <InputLabel className="form-input-label">Criteria</InputLabel>
              {criteriaList.map((criteria, ind) => (
                <div className="criteria-list" key={ind}>
                  <Select
                    inputProps={{ readOnly: isDefault }}
                    displayEmpty
                    size="small"
                    name="criteriaType"
                    value={criteriaList[ind].criteriaType || ""}
                    onChange={(ele) => {
                      const updatedCriteriaList = [...criteriaList];
                      updatedCriteriaList[ind].criteriaType = ele.target.value;
                      setCriteriaList(updatedCriteriaList);
                    }}
                    sx={{ minWidth: "110px" }}
                  >
                    <MenuItem value="" disabled hidden>
                      <span className="placeholder-style">Type</span>
                    </MenuItem>
                    <MenuItem value={CRITERIA_TYPE.KEYWORD}>Keyword</MenuItem>
                    <MenuItem value={CRITERIA_TYPE.REGEX}>Regex</MenuItem>
                  </Select>
                  &emsp;
                  <TextField
                    slotProps={{
                      input: {
                        readOnly: isDefault,
                      },
                    }}
                    required
                    size="small"
                    placeholder="Criteria Value"
                    className="form-textfield"
                    fullWidth
                    name="criteriaValue"
                    value={criteria.criteriaValue}
                    onChange={(ele) => {
                      const updatedCriteriaList = [...criteriaList];
                      updatedCriteriaList[ind].criteriaValue = ele.target.value;
                      setCriteriaList(updatedCriteriaList);
                    }}
                  />
                  &emsp;
                  {criteriaList.length > 1 &&
                    !isDefault ? (
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => handleDeleteElement(ind)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    ) : null}
                </div>
              ))}
            </div> 
            <br />
            {!isDefault ? (
              <>
                <Button
                  type="button"
                  onClick={handleAddElement}
                  startIcon={<AddCircleIcon />}
                >
                  Add Criteria
                </Button>

                <br />
                <Stack spacing={2} direction="row" justifyContent="flex-end">
                  <Button
                    className="primary-button-outlined"
                    variant="outlined"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="primary-button-filled"
                    variant="contained"
                    type="submit"
                    startIcon={
                      formType == "Update" ? (
                        <BeenhereOutlinedIcon />
                      ) : (
                        <AddIcon />
                      )
                    }
                  >
                    {formType} Rule
                  </Button>
                </Stack>
              </>
            ) : null}
          </form>
        )}
      </div>
    </div>
  );
}

export default RuleForm;

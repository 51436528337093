import { properties } from "../properties";
import api from "../services/api";

function fetchAllAlertsWithFilter(pageSize, pageNumber, searchText) {
  let url = `${properties.adminService}/policy-breach-alert?pageSize=${pageSize}&pageNumber=${pageNumber}`;
  if (searchText) {
    url += `&searchText=${searchText}`;
  }
  return api.get(url);
}

function deleteAlert(id) {
  return api.delete(`${properties.adminService}/policy-breach-alert/${id}`);
}

function createAlert(data) {
  return api.post(`${properties.adminService}/policy-breach-alert`, data);
}

function fetchAlert(id) {
  return api.get(`${properties.adminService}/policy-breach-alert/${id}`);
}

function updateAlert(id,data) {
  return api.put(`${properties.adminService}/policy-breach-alert/${id}`, data);
}

export {
  fetchAllAlertsWithFilter,
  deleteAlert,
  createAlert,
  fetchAlert,
  updateAlert
};

import { properties } from "../properties";
import api from "../services/api";

function fetchReports(job) {
    let url = `${properties.adminService}/bulk-operation?job=${job}`;
    return api.get(url);
  }

function uploadFile(data,job){
    let url = `${properties.adminService}/bulk-operation?job=${job}`;
    return api.post(url, data, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
    });
}

function downloadSampleCsv(job){
    let url = `${properties.adminService}/bulk-operation/sample?job=${job}`;
    return api.get(url,{
        responseType: "blob",
      });
}

export {
    fetchReports,
    uploadFile,
    downloadSampleCsv
}
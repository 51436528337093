import React, { useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { getTopRulesBreached } from "../../API/reporting";
import { useState } from "react";

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip);

export function BarChartWrapper({ data1 }) {
  const [barGraphValuesAndLabels, setBarGraphValuesAndLabels] = useState({
    labels: [
      "Email address",
      "Phone number",
      "Credit card no.",
      "Health insurance no.",
      "Social security no."
    ],
    datasets: [{ data: [0, 0, 0, 0, 0] }]
  });

  useEffect(() => {
    getTopRulesBreached(5)
      .then((response) => setBarGraphValuesAndLabelsData(response.data))
      .catch((error) => console.log(error));
  }, []);

  function setBarGraphValuesAndLabelsData(data) {
    let valueArray = barGraphValuesAndLabels.datasets[0].data;
    let labelArray = barGraphValuesAndLabels.labels;
    let labelArrayDictionary = {};
    labelArray.forEach((item, i) => (labelArrayDictionary[item] = i));

    data.forEach((item, i) => {
      if (item.name in labelArrayDictionary) {
        valueArray[labelArrayDictionary[item.name]] = item.value;
      } else {
        valueArray[i] = item.value;
        labelArray[i] = item.name;
      }
    });
    setBarGraphValuesAndLabels({
      labels: labelArray,
      datasets: [
        {
          data: valueArray,
          backgroundColor: ["#727CF5"]
        }
      ]
    });
  }

  return <Bar data={barGraphValuesAndLabels} />;
}

import React, { useState, useEffect } from "react";
import TableComponent from "../utility/Table";
import {
  fetchGlobalBlockedApplication,
  deleteGlobalBlockedApplication
} from "../../API/globalBlockedApplications";
import CustomDialog from "../utility/Dialog";
import SearchBar from "../utility/SearchBar";
import { Button, Box, Typography } from "@mui/material";
import { DIALOG_TYPES } from "../../utils/Constants";
import ApplicationForm from "./ApplicationForm";
import AppsIcon from "@mui/icons-material/Apps";
import Pagination from "../utility/Pagination";

export default function OrganizationalApps({ setFeedback }) {
  const [data, setData] = useState([]);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [searchText, setSearchText] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [open, setOpen] = useState(false);
  const [fetchCount, setFetchCount] = useState(0);

  const ADMIN = "Admin";
  const AGENT = "Agent";

  const handleDelete = (id) => {
    setIsDeleteConfirmationOpen(true);
    setSelectedId(id);
  };

  const handleClickOpen = () => {
    setOpen(true);
    setSelectedId("");
  };

  const handleConfirmDelete = () => {
    deleteGlobalBlockedApplication(selectedId)
      .then(function (response) {
        setData(data.filter((app) => app.id !== selectedId));
        setFeedback({
          severity: "success",
          message: "App removed successfully",
          state: true
        });
      })
      .catch(function (error) {
        setFeedback({
          severity: "error",
          message: error.response.data,
          state: true
        });
      })
      .finally(() => {
        setIsDeleteConfirmationOpen(false);
        setSelectedId("");
      });
  };

  const processData = (data) => {
    return data.map(({ isAddedByAdmin, ...rest }) => {
      return { isAddedByAdmin: isAddedByAdmin ? ADMIN : AGENT, ...rest };
    });
  };

  useEffect(() => {
    fetchGlobalBlockedApplication(searchText, pageSize, pageNumber)
      .then((response) => {
        setData(processData(response.data.appList));
        setTotalCount(response.data.totalCount);
      })
      .catch((error) => {
        setFeedback({
          severity: "error",
          message: "There is an issue fetching application list.",
          state: true
        });
      });
  }, [searchText, pageNumber, fetchCount, pageSize]);

  useEffect(() => {
    setPageNumber(0);
  }, [searchText]);

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <SearchBar
          setSearchText={setSearchText}
          searchText={searchText}
          placeholder={"Search"}
        />
        <Button
          className="btn-primary-main-add"
          style={{ height: 45 }}
          onClick={handleClickOpen}
        >
          <Box className='btns-with-icon'>
            <AppsIcon sx={{ fontSize: "23px" }} />
            <Typography className="btns-with-icon-text">Add App</Typography>
          </Box>
        </Button>
      </div>
      <TableComponent
        headings={["id", "name", "isAddedByAdmin"]}
        data={data}
        customColumnNames={{
          name: "Application Name",
          isAddedByAdmin: "Added By"
        }}
        options={{ hideIds: true }}
        actions={[
          {
            label: "Delete",
            onClickHandler: handleDelete,
            hasParameter: true
          }
        ]}
        styles={{
          table: { maxHeight: 425 }
        }}
      />
      {totalCount != 0 ? (
        <div className="pagination">
          <Pagination
            totalCount={totalCount}
            page={pageNumber}
            setPage={setPageNumber}
            rowsPerPage={pageSize}
            setRowsPerPage={setPageSize}
          />
        </div>
      ) : null}
      <CustomDialog
        open={isDeleteConfirmationOpen}
        title="Confirm Deletion"
        content="Are you sure you want to delete?"
        setOpen={setIsDeleteConfirmationOpen}
        handleConfirm={handleConfirmDelete}
        dialogType={DIALOG_TYPES.CONFIRM}
      />
      <ApplicationForm
        open={open}
        setOpen={setOpen}
        setFeedback={setFeedback}
        selectedApplicationId={selectedId}
        setFetchCount={setFetchCount}
      />
    </div>
  );
}

import { properties } from "../properties";
import api from "../services/api";

function fetchAllDestinations() {
  return api.get(`${properties.adminService}/destinations`);
}

function fetchAllDestinationsByPolicyId(policyId) {
  return api.get(
    `${properties.adminService}/destinations/fetchAll?policyId=${policyId}`
  );
}

export { fetchAllDestinations, fetchAllDestinationsByPolicyId };

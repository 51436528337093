export default function DesktopWithMobileIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <g clipPath="url(#clip0_94_1256)">
        <path
          d="M19.1669 7.90812H17.5414V3.54805C17.5414 2.72676 16.8732 2.05859 16.0519 2.05859H1.48945C0.668164 2.05859 0 2.72676 0 3.54805V12.6218C0 13.4431 0.668164 14.1112 1.48945 14.1112H8.48605V15.9577H4.95586C4.79867 15.9577 4.67121 16.0851 4.67121 16.2423C4.67121 16.3996 4.79863 16.527 4.95586 16.527H12.5855C12.7427 16.527 12.8702 16.3996 12.8702 16.2423C12.8702 16.0851 12.7427 15.9577 12.5855 15.9577H9.05531V14.1112H13.8319V17.1081C13.8319 17.5675 14.2056 17.9412 14.665 17.9412H19.167C19.6263 17.9412 20 17.5675 20 17.1081V8.74121C20 8.28184 19.6263 7.90812 19.1669 7.90812ZM13.8319 13.542H1.48945C0.98207 13.542 0.569297 13.1293 0.569297 12.6219V12.4934H13.8319L13.8319 13.542ZM13.8319 8.74121V11.9241H0.569258V3.54805C0.569258 3.04063 0.982031 2.62785 1.48941 2.62785H16.0519C16.5593 2.62785 16.9721 3.04063 16.9721 3.54805V7.90812H14.665C14.2056 7.90812 13.8319 8.28184 13.8319 8.74121ZM19.4307 17.1081C19.4307 17.2536 19.3124 17.372 19.1669 17.372H14.665C14.5195 17.372 14.4011 17.2536 14.4011 17.1081V16.1591H19.4307V17.1081ZM19.4307 15.5899H14.4011V8.74121C14.4011 8.59574 14.5195 8.47738 14.665 8.47738H19.1669C19.3124 8.47738 19.4307 8.59574 19.4307 8.74121V15.5899ZM16.3752 17.0502H17.3844C17.5416 17.0502 17.6691 16.9228 17.6691 16.7655C17.6691 16.6084 17.5416 16.4809 17.3844 16.4809H16.3752C16.218 16.4809 16.0905 16.6083 16.0905 16.7655C16.0906 16.9228 16.218 17.0502 16.3752 17.0502ZM17.3844 8.76664H16.3752C16.218 8.76664 16.0905 8.89406 16.0905 9.05129C16.0905 9.20848 16.218 9.33594 16.3752 9.33594H17.3844C17.5416 9.33594 17.6691 9.20852 17.6691 9.05129C17.6691 8.89406 17.5416 8.76664 17.3844 8.76664Z"
          fill="#303030"
        />
      </g>
      <defs>
        <clipPath id="clip0_94_1256">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

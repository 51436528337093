import { properties } from "../properties";
import api from "../services/api";

function fetchAllFileCategories() {
  return api.get(`${properties.adminService}/file-category`);
}

function fetchAllFileCategoriesByPolicyId(policyId) {
  return api.get(
    `${properties.adminService}/file-category/fetchAll?policyId=${policyId}`
  );
}

export { fetchAllFileCategories, fetchAllFileCategoriesByPolicyId };

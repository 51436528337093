import { properties } from "../properties";
import api from "../services/api";

function create(values) {
  return api.post(`${properties.adminService}/app-blocking-category`, values);
}

function fetchAppCategory(id) {
  return api.get(`${properties.adminService}/app-blocking-category/${id}`);
}

function fetchAllAppCategories(searchText, pageNumber, pageSize) {
  let url = `${properties.adminService}/app-blocking-category?`;
  if (pageSize && pageNumber!=undefined) {
    url += `pageSize=${pageSize}&pageNumber=${pageNumber}`;
  }
  if (searchText) {
    url += `&searchText=${searchText}`;
  }
  return api.get(url);
}

function updateAppCategory(values) {
  return api.put(
    `${properties.adminService}/app-blocking-category/${values.id}`,
    values
  );
}

function deleteAppCategory(id) {
  return api.delete(`${properties.adminService}/app-blocking-category/${id}`);
}

export {
  create as createAppCategory,
  fetchAppCategory,
  fetchAllAppCategories,
  updateAppCategory,
  deleteAppCategory
};

import * as React from "react";
import {
  Button,
  Toolbar,
  IconButton,
  Stack,
  Divider,
  InputLabel,
  TextField,
  Grid
} from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import CloseIcon from "@mui/icons-material/Close";
import {
  createAppCategory,
  fetchAppCategory,
  updateAppCategory
} from "../../API/appCategory";
import GroupFormShimmering from "../utility/GroupFormShimmer";
import BeenhereOutlinedIcon from "@mui/icons-material/BeenhereOutlined";
import AddIcon from "@mui/icons-material/Add";
import { fetchGlobalBlockedApplication } from "../../API/globalBlockedApplications";
import SelectedList from "../utility/SelectedList";
import SelectorList from "../utility/SelectorTableWithSearch";

function AppCategoryForm({
  openCategory,
  setOpenCategory,
  setFeedback,
  selectedCategoryId,
  setFetchCount
}) {
  const [applicationList, setApplicationList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedRecordId, setSelectedRecordId] = useState([]);

  let formType;

  if (selectedCategoryId !== "") formType = "Update";
  else formType = "Add";

  const handleClose = () => {
    setOpenCategory(false);
    setSelectedRecordId([]);
  };

  const validate = Yup.object({
    name: Yup.string().required("Required")
  });

  useEffect(() => {
    setLoading(true);
    if (formType === "Update") {
      fetchAppCategory(selectedCategoryId)
        .then((response) => {
          const { appIds, ...formikValues } = response.data;
          formik.setValues(formikValues);
          setSelectedRecordId(appIds);
        })
        .catch((error) => console.log(error.response))
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
      formik.setValues(formik.initialValues);
      setSelectedRecordId([]);
    }
  }, [selectedCategoryId, formType]);

  useEffect(() => {
    fetchGlobalBlockedApplication().then((response) => {
      setApplicationList(response.data.appList);
    });
  }, []);

  function submitForm(values, resetForm) {
    let promise;
    const formValues = { ...values, appIds: selectedRecordId };
    if (formType === "Add") {
      promise = createAppCategory(formValues);
    } else {
      promise = updateAppCategory({ ...formValues, id: selectedCategoryId });
    }

    promise
      .then((response) => {
        setFeedback({
          severity: "success",
          message: `Category added successfully`,
          state: true
        });
        setFetchCount((fetchCount) => fetchCount + 1);
        setOpenCategory(false);
      })
      .catch((error) =>
        setFeedback({
          severity: "error",
          message: error.response.data,
          state: true
        })
      );
  }

  const formik = useFormik({
    initialValues: {
      name: ""
    },
    validationSchema: validate,
    onSubmit: (values, { resetForm }) => {
      submitForm(values, resetForm);
    }
  });

  return (
    <div>
      <div
        className={`drawer-form-wrapper ${
          openCategory ? "drawer-form-open" : "drawer-form-closed"
        } group-form`}
      >
        <Toolbar />
        <br />
        <div className="close-button">
          <IconButton onClick={handleClose} sx={{ padding: 0 }}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className="drawer-form-container">
          {loading ? (
            <GroupFormShimmering />
          ) : (
            <form onSubmit={formik.handleSubmit}>
              <h5>{formType} Application Category</h5>
              <Divider />
              <Grid
                container
                spacing={2}
                className="group-form-container"
                columns={24}
              >
                <Grid item md={12}>
                  <div>
                    <div className="form-input">
                      <InputLabel
                        htmlFor="name"
                        className="form-input-label"
                        required
                      >
                        Category Name
                      </InputLabel>
                      <TextField
                        size="small"
                        id="name"
                        placeholder="Example"
                        className="form-textfield"
                        fullWidth
                        name="name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.name && Boolean(formik.errors.name)
                        }
                        helperText={formik.touched.name && formik.errors.name}
                      />
                    </div>
                    <SelectorList
                      headings={["id", "name"]}
                      label={"Select Applications"}
                      hideIds={true}
                      data={applicationList}
                      selectedRecordId={selectedRecordId}
                      setSelectedRecordId={setSelectedRecordId}
                      customColumnNames={{ name: "Application Name" }}
                      searchColumnNames={["name"]}
                    />
                  </div>
                </Grid>
                <Grid md={1} item sx={{ Height: "100%" }}>
                  <Divider orientation="vertical" sx={{ opacity: 1 }} />
                </Grid>
                <Grid item md={11}>
                  <br />
                  <h6>Selected ({selectedRecordId.length})</h6>
                  <SelectedList
                    data={applicationList}
                    field={"id"}
                    selectedRecordId={selectedRecordId}
                    setSelectedRecordId={setSelectedRecordId}
                    primaryText={"name"}
                  />
                </Grid>
              </Grid>
              <Divider sx={{ opacity: 1 }} />
              <br />
              <Stack spacing={2} direction="row" justifyContent="flex-end">
                <Button
                  className="primary-button-outlined"
                  variant="outlined"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  className="primary-button-filled"
                  variant="contained"
                  type="submit"
                  startIcon={
                    formType == "Update" ? (
                      <BeenhereOutlinedIcon />
                    ) : (
                      <AddIcon />
                    )
                  }
                >
                  {formType}
                </Button>
              </Stack>
            </form>
          )}
        </div>
      </div>
    </div>
  );
}

export default AppCategoryForm;

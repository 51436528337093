import { stringToColor } from "../../utils/Helpers";

function stringAvatar(email, firstInitial, lastInitial, size = 40) {
  firstInitial = firstInitial.toUpperCase();
  lastInitial = lastInitial.toUpperCase();
  return {
    sx: {
      bgcolor: stringToColor(email),
      height: size,
      width: size,
      fontSize: size / 2 - 3,
      fontWeight: "500",
      border: "1px solid rgba(191 191 191 / 1)"
    },
    children: firstInitial + lastInitial
  };
}

export default stringAvatar;

import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import "./active-filter.css";
import SearchBar from "./SearchBar";
import { capitalizeWords } from "../../utils/Helpers";
import DateTimeFilter from "../utility/DateTimeFilter";

const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5)
}));

function MultiFilter({
  filterValuesList,
  filtersSelectedValue,
  setFiltersSelectedValue,
  showSearchBar,
  setSearchText,
  searchText,
  placeholder,
  styles,
  showTimeFilter,
  startTime,
  endTime,
  setStartTime,
  setEndTime
}) {
  const [showActiveFilters, setShowActiveFilters] = useState(false);
  const [currentSelectedFilter, setCurrentSelectedFilter] = useState("");
  useEffect(() => {
    for (const [key, value] of Object.entries(filtersSelectedValue)) {
      if (value != "all") {
        setShowActiveFilters(true);
        break;
      }
    }
  }, [filtersSelectedValue]);

  const handleChangeSelectedFilter = (event) => {
    setCurrentSelectedFilter(event.target.value);
  };

  const updateSelectedFilterValue = (event) => {
    const selectedValue = event.target.value;
    setFiltersSelectedValue((prevState) => ({
      ...prevState,
      [currentSelectedFilter]: selectedValue
    }));
  };

  useEffect(() => {
    setShowActiveFilters(
      Object.values(filtersSelectedValue).some((value) => value !== "all")
    );
  }, [filtersSelectedValue]);

  const handleDeleteChipOnFilterRemove = (chipToDelete) => () => {
    setFiltersSelectedValue((prevState) => ({
      ...prevState,
      [chipToDelete]: "all"
    }));
    setCurrentSelectedFilter("");
    setShowActiveFilters(false);
  };

  const handleDeleteAllChips = () => {
    setFiltersSelectedValue((prevState) => {
      let newState = { ...prevState };
      Object.keys(newState).forEach((key) => {
        newState[key] = "all";
      });
      return newState;
    });
    setCurrentSelectedFilter("");
    setShowActiveFilters(false);
  };

  const mappedSelectedFiltersValue = Object.entries(filtersSelectedValue).map(
    ([key, value]) => {
      const filterList = filterValuesList[key];
      if (!filterList) return null;
      const filterItem = filterList.find((item) => item.filterId === value);
      return value !== "all" ? (
        <ListItem key={key}>
          <Chip
            label={`${capitalizeWords(key)}: ${
              filterItem ? filterItem.filterValue : value
            }`}
            onDelete={handleDeleteChipOnFilterRemove(key)}
            size="small"
          />
        </ListItem>
      ) : null;
    }
  );

  const currentSelectedFilterItems =
    filterValuesList[currentSelectedFilter] || [];
  const filterKeysList = Object.keys(filterValuesList);

  return (
    <>
      <div style={styles}>
        <div style={{ display: "flex" }}>
          {showSearchBar ? (
            <SearchBar
              setSearchText={setSearchText}
              searchText={searchText}
              placeholder={placeholder}
              styles={{ marginRight: "10px" }}
            />
          ) : null}
          <div className="multi-filter-component" style={{ display: "flex" }}>
            <FormControl
              size="small"
              sx={{ width: "145px", marginRight: "10px" }}
            >
              <InputLabel id="apply-filter-input-label">
                Apply filter on
              </InputLabel>
              <Select
                labelId="apply-filter-input-label"
                id="apply-filter-select"
                label="Apply filter on"
                value={currentSelectedFilter}
                onChange={handleChangeSelectedFilter}
                defaultValue=""
                sx={{ textAlign: "left" }}
              >
                {filterKeysList.map((key, index) => (
                  <MenuItem value={key} key={key}>
                    {capitalizeWords(key)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl
              size="small"
              sx={{ width: "145px" }}
              disabled={currentSelectedFilter === ""}
            >
              <InputLabel id="choose-filter-input-label">
                Choose filter
              </InputLabel>
              <Select
                labelId="choose-filter-input-label"
                id="choose-filter-select"
                label="Choose filter"
                onChange={updateSelectedFilterValue}
                value={filtersSelectedValue[currentSelectedFilter] || ""}
                disabled={currentSelectedFilter === ""}
                defaultValue=""
                sx={{ textAlign: "left" }}
              >
                {currentSelectedFilterItems.map(({ filterValue, filterId }) => (
                  <MenuItem value={filterId} key={filterId}>
                    {filterValue}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div>
          {showTimeFilter ? (
              <DateTimeFilter
                startTime={startTime}
                endTime={endTime}
                setStartTime={setStartTime}
                setEndTime={setEndTime}
              />
            ) : null} 
          </div>
        </div>
        {showActiveFilters && (
          <div className="active-filter-component">
            <div className="active-filter-text">Active Filters:</div>
            <Paper
              sx={{
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
                listStyle: "none",
                p: 0.5,
                m: 0,
                boxShadow: "unset"
              }}
              component="ul"
            >
              {mappedSelectedFiltersValue}
              <Button
                variant="text"
                sx={{ fontWeight: "600", fontSize: "11px" }}
                onClick={handleDeleteAllChips}
              >
                Clear All
              </Button>
            </Paper>
          </div>
        )}
      </div>
    </>
  );
}

export default MultiFilter;

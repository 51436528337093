import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { capitalizefirstLetter } from "../../utils/Helpers";

function Filter({ filters, filterType, filterValue, setFilterValue, styles }) {
  return (
    <FormControl size="small" sx={styles} >
      <InputLabel id={filterType}>
        {capitalizefirstLetter(filterType)}
      </InputLabel>
      <Select
        labelId={filterType}
        id={filterType}
        value={filterValue}
        label={capitalizefirstLetter(filterType)}
        onChange={(e) => setFilterValue(e.target.value)}
      >
        <MenuItem value="">Show All</MenuItem>
        {filters.map(({ filterValue, filterId }) => (
          <MenuItem value={filterId}>{filterValue}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default Filter;

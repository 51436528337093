const isMatch = (text, searchText) => {
  if (text === null || text === undefined) {
    return false;
  }
  text = text.toString();
  return text.toLowerCase().includes(searchText.toLowerCase());
};

const localSearch = (data, searchColumnNames, searchText) => {
  if (searchText === "") {
    return data;
  }
  return data.filter((item) => {
    if (searchColumnNames) {
      for (const key in searchColumnNames) {
        if (isMatch(item[searchColumnNames[key]], searchText)) {
          return true;
        }
      }
    } else {
      for (const key in item) {
        if (isMatch(item[key], searchText)) {
          return true;
        }
      }
    }
    return false;
  });
};

export { localSearch };

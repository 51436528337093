import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Button,
  Toolbar,
  IconButton,
  Stack,
  Divider,
  InputLabel
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Download, UploadFile } from "@mui/icons-material";
import { downloadSampleCsv, uploadFile } from "../../API/bulkUpload";
import { Link } from "react-router-dom";

function BulkUploadForm({ setFeedback, setOpen, open, jobName }) {
  function downloadSample() {
    downloadSampleCsv(jobName)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", jobName + "_SAMPLE.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch((e) => {
        setFeedback({
          severity: "error",
          message: "Sample file not available.",
          state: true
        });
      });
  }

  const validate = Yup.object({
    file: Yup.mixed()
      .required("Please select a file")
      .test("fileSize", "File too large. Max file size : 5mb", (value) => {
        const maxSize = 5 * 1024 * 1024;
        return value && value.size <= maxSize;
      })
  });

  const formik = useFormik({
    initialValues: {
      file: null
    },
    validationSchema: validate,
    onSubmit: (values) => {
      submitForm(values);
    }
  });

  function submitForm(values) {
    uploadFile(values, jobName)
      .then((response) => {
        setFeedback({
          severity: "success",
          message: (
            <div>
              The upload has started. &nbsp;
              <Link to="/reporting/bulk-report">Click here</Link>
              {" "}to monitor the upload status.
            </div>
          ),
          state: true
        });
        setOpen(false);
      })
      .catch(function (error) {
        setFeedback({
          severity: "error",
          message: "There is an issue while uploading the file.",
          state: true
        });
      });
  }

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div
      className={`drawer-form-wrapper ${
        open ? "drawer-form-open" : "drawer-form-closed"
      }`}
    >
      <Toolbar />
      <br />
      <div className="close-button">
        <IconButton onClick={handleClose} sx={{ padding: 0 }}>
          <CloseIcon />
        </IconButton>
      </div>
      <div className="drawer-form-container">
        <form onSubmit={formik.handleSubmit}>
          <h5>Bulk Upload</h5>
          <Divider />
          <br />
          <p>
            To view the status of previous uploads,{" "}
            <Link to="/reporting/bulk-report">click here</Link>.
          </p>

          <div className="form-input">
            <InputLabel htmlFor="file" className="form-input-label" required>
              Select File
            </InputLabel>
            <input
              name="file"
              type="file"
              accept="text/csv"
              onChange={(event) =>
                formik.setFieldValue("file", event.target.files[0])
              }
            />
            <div className="error">
              {formik.touched.file && formik.errors.file}
            </div>
          </div>
          <p>
            We support comma separated .csv file format. Please refer to Sample
            CSV file available for download below.
          </p>
          <Button
            onClick={downloadSample}
            sx={{ cursor: "pointer" }}
            startIcon={<Download />}
          >
            Download Sample file
          </Button>
          <br />
          <Stack spacing={2} direction="row" justifyContent="flex-end">
            <Button
              className="primary-button-outlined"
              variant="outlined"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              className="primary-button-filled"
              variant="contained"
              type="submit"
              startIcon={<UploadFile />}
            >
              Upload
            </Button>
          </Stack>
        </form>
      </div>
    </div>
  );
}

export default BulkUploadForm;

import { properties } from "../properties";
import api from "../services/api";

function fetchAllOrganizationDomain() {
    return api.get(`${properties.adminService}/organizational-domain`);
  }

function deleteOrganizationDomain(id) {
  return api.delete(`${properties.adminService}/organizational-domain/${id}`);
}

function createOrganizationDomain(data) {
  return api.post(`${properties.adminService}/organizational-domain`, data);
}

function updateOrganizationDomain(id,data) {
  return api.put(`${properties.adminService}/organizational-domain/${id}`, data);
}

export {
  deleteOrganizationDomain,
  createOrganizationDomain,
  fetchAllOrganizationDomain,
  updateOrganizationDomain
};

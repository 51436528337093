import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import './loader.css';

export default function CircularLoader() {

  return (
    <div className='loader-container'>
        <CircularProgress color="inherit" />
    </div>
  );
}
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import Button from "@mui/material/Button";
import {
  IconButton,
  Toolbar,
  Divider,
  InputLabel,
  Stack,
  TextField,
  Grid,
  InputAdornment
} from "@mui/material";
import { createGroup, fetchGroup, updateGroup } from "../../API/group";
import FormShimmering from "../utility/FormShimmer";
import "./device-group.css";
import CloseIcon from "@mui/icons-material/Close";
import BeenhereOutlinedIcon from "@mui/icons-material/BeenhereOutlined";
import AddIcon from "@mui/icons-material/Add";
import PersonIcon from "@mui/icons-material/Person";
import WindowsIcon from "../assets/icons/WindowsIcon";
import SelectorList from "../utility/SelectorTableWithSearch";
import SelectedList from "../utility/SelectedList";
import {
  fetchAllDevicesByDeviceGroupId,
  fetchDevices
} from "../../API/devices";
import {
  fetchAllUsersByUserGroupId,
  getAllUsersWithFilter
} from "../../API/users";

function GroupForm({
  open,
  setOpen,
  setFeedback,
  selectedGroupId,
  setFetchCount,
  groupType
}) {
  const [loading, setLoading] = useState(true);
  const [selectedRecordId, setSelectedRecordId] = useState([]);
  const [data, setData] = useState([]);

  const user = useSelector(({ auth }) => auth.currentUser);

  let formType = selectedGroupId === null ? "Add" : "Update";

  useEffect(() => {
    setLoading(true);
    if (selectedGroupId) {
      fetchGroup(selectedGroupId)
        .then((response) => {
          formik.setValues(response.data);
        })
        .catch((error) => {
          setFeedback({
            severity: "error",
            message: "There is an issue fetching group.",
            state: true
          });
        })
        .finally(() => {
          setLoading(false);
        });

      if (groupType === "USER") {
        fetchAllUsersByUserGroupId(selectedGroupId)
          .then((response) => {
            const userIds = response.data.map((item) => item.endUserId);
            setSelectedRecordId(userIds);
          })
          .catch((error) => {
            setFeedback({
              severity: "error",
              message: "There is an issue fetching users for group.",
              state: true
            });
          });
      } else {
        fetchAllDevicesByDeviceGroupId(selectedGroupId)
          .then((response) => {
            const groupIds = response.data.map((item) => item.id);
            setSelectedRecordId(groupIds);
          })
          .catch((error) => {
            setFeedback({
              severity: "error",
              message: "There is an issue fetching devices for group.",
              state: true
            });
          });
      }
    } else {
      formik.setValues(formik.initialValues);
      setSelectedRecordId([]);
      setLoading(false);
    }
  }, [selectedGroupId, groupType, open]);

  useEffect(() => {
    if (groupType === "USER") {
      getAllUsersWithFilter()
        .then(({ data }) => {
          setData(
            data.map(({ endUserId, ...rest }) => {
              return { id: endUserId, ...rest };
            })
          );
        })
        .catch((error) => {
          setFeedback({
            severity: "error",
            message: "There is an issue fetching all users.",
            state: true
          });
        });
    } else {
      fetchDevices()
        .then(({ data }) => {
          setData(data);
        })
        .catch((error) => {
          setFeedback({
            severity: "error",
            message: "There is an issue fetching devices.",
            state: true
          });
        });
    }
  }, []);

  const handleClose = () => {
    setOpen(false);
    setSelectedRecordId([]);
  };

  const validate = Yup.object({
    name: Yup.string().required("Required")
  });

  function submitForm(values, resetForm) {
    let promise;
    const fieldKey = groupType === "USER" ? "userIds" : "deviceIds";
    if (formType === "Add") {
      promise = createGroup({
        ...values,
        [fieldKey]: selectedRecordId.map((fieldId) => fieldId.toString()),
        type: groupType
      });
    } else {
      promise = updateGroup({
        ...values,
        [fieldKey]: selectedRecordId.map((fieldId) => fieldId.toString()),
        type: groupType
      });
    }

    promise
      .then((response) => {
        const message = formType === "Add" ? "added" : "updated";
        setFeedback({
          severity: "success",
          message: `Group ${message} successfully`,
          state: true
        });
        setFetchCount((fetchCount) => fetchCount + 1);
        setOpen(false);
      })
      .catch(function (error) {
        setFeedback({
          severity: "error",
          message: "There is an issue while processing your request.",
          state: true
        });
      });
  }

  const formik = useFormik({
    initialValues: {
      id: selectedGroupId,
      name: "",
      action: ""
    },
    validationSchema: validate,
    onSubmit: (values, { resetForm }) => {
      submitForm(values, resetForm);
    }
  });

  return (
    <div
      className={`drawer-form-wrapper ${
        open ? "drawer-form-open" : "drawer-form-closed"
      } group-form`}
    >
      <Toolbar />
      <br />
      <div className="close-button">
        <IconButton onClick={handleClose} sx={{ padding: 0 }}>
          <CloseIcon />
        </IconButton>
      </div>
      {loading ? (
        <FormShimmering />
      ) : (
        <div className="drawer-form-container">
          <h5>{formType} Group</h5>
          <Divider sx={{ opacity: 1 }} />
          <form onSubmit={formik.handleSubmit}>
            <Grid
              container
              spacing={2}
              className="group-form-container"
              columns={24}
            >
              <Grid item md={13}>
                <div>
                  <div className="form-input">
                    <InputLabel
                      htmlFor="group-name"
                      className="form-input-label"
                      required
                    >
                      Group Name
                    </InputLabel>
                    <TextField
                      size="small"
                      id="group-name"
                      placeholder="Name"
                      className="form-textfield"
                      fullWidth
                      name="name"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      helperText={formik.touched.name && formik.errors.name}
                    />
                  </div>
                  <SelectorList
                    headings={
                      groupType === "USER"
                        ? ["id", "email", "username"]
                        : ["id", "deviceName", "displayName"]
                    }
                    label={
                      groupType === "USER" ? "Select Users" : "Select Devices"
                    }
                    hideIds={groupType === "USER"}
                    data={data}
                    selectedRecordId={selectedRecordId}
                    setSelectedRecordId={setSelectedRecordId}
                    searchColumnNames={
                      groupType === "USER"
                        ? ["email", "username"]
                        : ["id", "deviceName"]
                    }
                  />
                </div>
              </Grid>
              <Grid md={1} item sx={{ Height: "100%" }}>
                <Divider orientation="vertical" sx={{ opacity: 1 }} />
              </Grid>
              <Grid item md={10}>
                <br />
                <h6>Selected ({selectedRecordId.length})</h6>
                <SelectedList
                  data={data}
                  field="id"
                  selectedRecordId={selectedRecordId}
                  setSelectedRecordId={setSelectedRecordId}
                  primaryText={groupType === "USER" ? "email" : "deviceName"}
                  secondaryText={groupType === "USER" ? "username" : "id"}
                  icon={
                    groupType === "USER" ? <PersonIcon /> : <WindowsIcon />
                  }
                  iconStyles = {{bgcolor: "rgba(25, 118, 210, 1)"}}
                />
              </Grid>
            </Grid>
            <Divider sx={{ opacity: 1 }} />
            <br />
            <Stack spacing={2} direction="row" justifyContent="flex-end">
              <Button
                className="primary-button-outlined"
                variant="outlined"
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                className="primary-button-filled"
                variant="contained"
                type="submit"
                startIcon={
                  formType == "Update" ? <BeenhereOutlinedIcon /> : <AddIcon />
                }
              >
                {formType}
              </Button>
            </Stack>
          </form>
        </div>
      )}
    </div>
  );
}

export default GroupForm;

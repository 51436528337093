import React, { useState } from "react";
import { DIALOG_TYPES, REMOTE_LOCK_STATUS } from "../../../utils/Constants";
import CustomDialog from "../../utility/Dialog";
import { applyRemoteLockToDevice } from "../../../API/devices";

export default function RemoteLockWithConfirmation({
  confirmRemoteLock,
  setConfirmRemoteLock,
  deviceId,
  setDeviceData,
  setFeedback
}) {
  const handleRemoteLockApplied = () => {
    applyRemoteLockToDevice(deviceId)
      .then((response) => {
        setFeedback({
          severity: "success",
          message: "Device locked successfully",
          state: true
        });
        setConfirmRemoteLock(false);

        const remoteLockAppliedTimestamp = new Date();
        if (setDeviceData !== undefined) {
          setDeviceData((prevDeviceData) => ({
            ...prevDeviceData,
            remoteLockAppliedTimestamp,
            remoteLockStatus: REMOTE_LOCK_STATUS.APPLIED
          }));
        }
      })
      .catch((error) => {
        setFeedback({
          severity: "error",
          message: "Something went wrong while locking the device",
          state: true
        });
      });
  };

  return (
    <>
      <CustomDialog
        open={confirmRemoteLock}
        setOpen={setConfirmRemoteLock}
        title="Confirm Remote Lock"
        content="Are you sure you want to remotely lock this device? If confirmed, the device will be locked and its password will be reset."
        handleConfirm={handleRemoteLockApplied}
        dialogType={DIALOG_TYPES.CONFIRM}
      />
    </>
  );
}

import { properties } from "../properties";
import api from "../services/api";

function getDeviceActivity({
  deviceGroupId,
  searchText,
  pageSize,
  pageNumber,
  startDttm,
  endDttm
}) {
  let url = `${properties.adminService}/device/activity?searchText=${searchText}&pageSize=${pageSize}&pageNumber=${pageNumber}&deviceGroupId=${deviceGroupId}`;

  if (startDttm && startDttm.isValid()) {
    url += `&startDttm=${new Date(startDttm).toISOString()}`;
  }
  if (endDttm && endDttm.isValid()) {
    url += `&endDttm=${new Date(endDttm).toISOString()}`;
  }

  return api.get(url);
}

function getDeviceEvents(deviceId,startDttm,endDttm){
  let url = `${properties.reportingService}/device-activity/session/activities?deviceId=${deviceId}`;

  if (startDttm && startDttm.isValid()) {
    url += `&startDttm=${new Date(startDttm).toISOString()}`;
  }
  if (endDttm && endDttm.isValid()) {
    url += `&endDttm=${new Date(endDttm).toISOString()}`;
  }

  return api.get(url);
}

export { getDeviceActivity, getDeviceEvents };

import { properties } from "../properties";
import api from "../services/api";
import { REMOTE_WIPE_STATUS } from "../utils/Constants";

function fetchDevices() {
  return api.get(`${properties.adminService}/device`);
}

function fetchDevicesWithPagination(
  pageSize,
  pageNumber,
  searchText,
  version = null,
  status = null
) {
  let url = `${properties.adminService}/device?`;
  if (version != null && version != "all") url += `version=${version}&`;
  if (status != null && status != "all") url += `status=${status}&`;
  url += `pageSize=${pageSize}&pageNumber=${pageNumber}&searchText=${searchText}`;
  return api.get(url);
}

function deleteDevice(deviceId) {
  return api.delete(`${properties.adminService}/device/${deviceId}`);
}
function updateDeviceUsingPatch(deviceId, jsonPatch) {
  return api.patch(`${properties.adminService}/device/${deviceId}`, jsonPatch, {
    headers: {
      "Content-Type": "application/json-patch+json"
    }
  });
}
function fetchDeviceById(deviceId) {
  return api.get(`${properties.adminService}/device/${deviceId}`);
}
function fetchAllDevicesByDeviceGroupId(deviceGroupId) {
  return api.get(
    `${properties.adminService}/device/getall?deviceGroupId=${deviceGroupId}`
  );
}
/**
 *
 * @param {boolean|null} isDeviceActive - true if we need active device count,
 * false if we need inactive device count,
 * defaults to null and gives count of all devices
 * @returns {Promise<number>} The number of devices with isDeviceActive filter.
 */
function getDeviceCount(isDeviceActive = null) {
  return api.get(
    `${properties.adminService}/device/count${
      isDeviceActive != null ? `?isDeviceActive=${isDeviceActive}` : ""
    }`
  );
}
function applyRemoteLockToDevice(deviceId) {
  return api.post(
    `${properties.adminService}/device/${deviceId}/remote-lock-request`,
    {
      headers: {
        "Content-Type": "application/json"
      }
    }
  );
}
function fetchApplications(deviceId) {
  return api.get(
    `${properties.adminService}/device/${deviceId}/application-list`
  );
}
function updateBlockedStatus(deviceId, allowedApps, blockedApps) {
  return api.patch(
    `${properties.adminService}/device/${deviceId}/application-list`,
    { allowedApps, blockedApps }
  );
}
function fetchDeviceGroupsByDeviceId(deviceId) {
  return api.get(`${properties.adminService}/device/${deviceId}/groups`);
}
function fetchAgentVersions() {
  return api.get(`${properties.adminService}/device/agent-version`);
}

function togglecanRemoveAgent(groupId, isChecked) {
  return api.put(`${properties.adminService}/device-groups/${groupId}/can-remove-agent`,isChecked,
    {
      headers: {
        "Content-Type": "application/json"
      }
    }
  );
}

function getDevicePassword(deviceId, customerPassword) {
  return api.get(`${properties.adminService}/device/${deviceId}/password`, {
    headers: {
      "Customer-Password": customerPassword
    }
  });
}

function applyRemoteWipeToDevice(deviceId) {
  return api.post(
    `${properties.adminService}/device/${deviceId}/remote-wipe-admin`,
    {
      headers: {
        "Content-Type": "application/json"
      }
    }
  );
}

export {
  fetchDevices,
  fetchDevicesWithPagination,
  deleteDevice,
  getDeviceCount,
  updateDeviceUsingPatch,
  fetchDeviceById,
  fetchAllDevicesByDeviceGroupId,
  applyRemoteLockToDevice,
  fetchApplications,
  updateBlockedStatus,
  fetchDeviceGroupsByDeviceId,
  fetchAgentVersions,
  getDevicePassword,
  togglecanRemoveAgent,
  applyRemoteWipeToDevice
};

export default function DomainIcon() {
  return (
    <svg
      fill="#3858e9"
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      width="30px"
      height="30px"
      viewBox="0 0 416.70 416.70"
      stroke="#3858e9"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="#CCCCCC"
        strokeWidth="0.8334059999999999"
      ></g>
      <g id="SVGRepo_iconCarrier">
        {" "}
        <g>
          {" "}
          <g>
            {" "}
            <g>
              {" "}
              <path d="M143.531,158.403h-0.764c-2.803,0-5.289,1.796-6.17,4.455l-11.943,36.043l-11.998-36.051 c-0.885-2.656-3.369-4.447-6.168-4.447h-1.1c-2.803,0-5.289,1.796-6.17,4.455l-11.943,36.043L75.277,162.85 c-0.885-2.656-3.369-4.447-6.168-4.447h-0.764c-2.096,0-4.063,1.01-5.283,2.713s-1.545,3.891-0.873,5.875l18.432,54.355 c0.893,2.637,3.369,4.411,6.154,4.411h0.426c0.029,0,0.059-0.005,0.088-0.006c0.029,0.001,0.059,0.006,0.088,0.006h0.344 c2.789,0,5.27-1.78,6.16-4.426l12.084-35.878l12.027,35.869c0.889,2.646,3.369,4.435,6.164,4.435h0.424 c0.029,0,0.059-0.005,0.088-0.006c0.029,0.001,0.059,0.006,0.088,0.006h0.344c2.785,0,5.262-1.774,6.156-4.411l18.43-54.355 c0.674-1.984,0.35-4.172-0.871-5.875S145.626,158.403,143.531,158.403z"></path>{" "}
              <path d="M233.688,158.403h-0.765c-2.803,0-5.288,1.796-6.17,4.455l-11.942,36.043l-11.999-36.051 c-0.885-2.656-3.369-4.447-6.168-4.447h-1.1c-2.803,0-5.289,1.796-6.17,4.455l-11.943,36.043l-11.998-36.051 c-0.885-2.656-3.369-4.447-6.168-4.447h-0.764c-2.096,0-4.063,1.01-5.283,2.713c-1.22,1.703-1.545,3.891-0.873,5.875 l18.432,54.355c0.893,2.637,3.369,4.411,6.154,4.411h0.426c0.029,0,0.059-0.005,0.088-0.006c0.029,0.001,0.059,0.006,0.088,0.006 h0.344c2.789,0,5.27-1.78,6.16-4.426l12.084-35.878l12.027,35.869c0.889,2.646,3.37,4.435,6.165,4.435h0.425 c0.028,0,0.059-0.005,0.088-0.006c0.028,0.001,0.059,0.006,0.088,0.006h0.344c2.785,0,5.262-1.774,6.156-4.411l18.43-54.355 c0.674-1.984,0.351-4.172-0.871-5.875S235.783,158.403,233.688,158.403z"></path>{" "}
              <path d="M323.844,158.403h-0.766c-2.803,0-5.287,1.796-6.17,4.455l-11.941,36.043l-11.998-36.051 c-0.885-2.656-3.369-4.447-6.168-4.447h-1.102c-2.803,0-5.289,1.796-6.17,4.455l-11.941,36.043l-11.999-36.051 c-0.885-2.656-3.368-4.447-6.168-4.447h-0.764c-2.098,0-4.063,1.01-5.283,2.713s-1.545,3.891-0.873,5.875l18.433,54.355 c0.894,2.637,3.369,4.411,6.154,4.411h0.426c0.029,0,0.06-0.005,0.088-0.006c0.029,0.001,0.06,0.006,0.089,0.006h0.344 c2.789,0,5.27-1.78,6.16-4.426l12.084-35.878l12.026,35.869c0.89,2.646,3.369,4.435,6.164,4.435h0.424 c0.029,0,0.06-0.005,0.089-0.006c0.028,0.001,0.059,0.006,0.088,0.006h0.344c2.785,0,5.262-1.774,6.156-4.411l18.43-54.355 c0.674-1.984,0.35-4.172-0.871-5.875C327.906,159.413,325.939,158.403,323.844,158.403z"></path>{" "}
              <path d="M28.283,348.743V105.026h335.621v139.452c6.733-5.787,15.211-8.953,24.182-8.953c1.381,0,2.748,0.082,4.102,0.229V44.311 c0-12.996-10.571-23.568-23.563-23.568H23.568C10.57,20.743,0,31.315,0,44.311v309.147c0,12.994,10.57,23.568,23.568,23.568 h224.848c-5.428-8.517-9.375-18.062-11.441-28.283H28.283L28.283,348.743z M338.025,60.319c0-4.807,3.895-8.703,8.702-8.703 h8.703c4.806,0,8.701,3.896,8.701,8.703v9.863c0,4.805-3.896,8.701-8.701,8.701h-8.703c-4.809,0-8.702-3.896-8.702-8.701V60.319z M297.561,60.319c0-4.807,3.896-8.703,8.701-8.703h8.703c4.808,0,8.701,3.896,8.701,8.703v9.863c0,4.805-3.896,8.701-8.701,8.701 h-8.703c-4.805,0-8.701-3.896-8.701-8.701V60.319z M257.094,60.319c0-4.807,3.896-8.703,8.703-8.703h8.701 c4.805,0,8.701,3.896,8.701,8.703v9.863c0,4.805-3.896,8.701-8.701,8.701h-8.701c-4.807,0-8.703-3.896-8.703-8.701V60.319z"></path>{" "}
              <path d="M416.703,285.536c0-4.586-1.785-8.896-5.029-12.141L398.83,260.55c-3.242-3.241-7.553-5.026-12.137-5.026 s-8.896,1.785-12.14,5.028l-19.604,19.601c-10.469-7.506-23.303-11.928-37.168-11.928c-35.273,0-63.868,28.596-63.868,63.868 c0,35.271,28.595,63.867,63.868,63.867c35.272,0,63.867-28.598,63.867-63.867c0-1.431-0.052-2.849-0.146-4.255l30.166-30.166 C414.916,294.432,416.703,290.12,416.703,285.536z M397.734,289.352l-61.427,61.428c-1.295,1.295-3.051,2.021-4.881,2.021h-0.002 c-1.834,0-3.588-0.729-4.883-2.022l-28.467-28.51c-2.689-2.695-2.688-7.064,0.006-9.759l5.479-5.479 c2.693-2.693,7.06-2.695,9.755-0.006l18.104,18.057l51.021-51.02c1.295-1.295,3.051-2.021,4.881-2.021s3.586,0.728,4.881,2.021 l5.529,5.53C400.43,282.288,400.43,286.657,397.734,289.352z"></path>{" "}
            </g>{" "}
          </g>{" "}
        </g>{" "}
      </g>
    </svg>
  );
}

import React from "react";
import ReportList from "./ReportList";

export default function HttpReportList() {
  const headings = [
    "id",
    "deviceName",
    "incidentDttm",
    "policyName",
    "ruleName",
    "risk",
  ];
  const subheading = "Identify Devices Attempting to Upload Sensitive Data or Access Unauthorized Sites";
  return <ReportList headings={headings} destinationId={3} subheading={subheading} />;
}

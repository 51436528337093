import { properties } from "../properties";
import api from "../services/api";

function fetchGlobalBlockedApplication(searchText, pageSize, pageNumber) {
  let url = `${properties.adminService}/global-application?`;
  if (pageSize && pageNumber!=undefined) {
    url += `pageSize=${pageSize}&pageNumber=${pageNumber}`;
  }
  if (searchText) {
    url += `&searchText=${searchText}`;
  }
  return api.get(url);
}

function deleteGlobalBlockedApplication(id) {
  return api.delete(`${properties.adminService}/global-application/${id}`);
}

function AddGlobalBlockedApp(appName) {
  return api.post(`${properties.adminService}/global-application`, appName, {
    headers: {
      "Content-Type": "text/plain"
    }
  });
}

function fetchApplicationFromCategoryId(categoryId) {
  return api.get(
    `${properties.adminService}/global-application?categoryId=${categoryId}`
  );
}

export {
  fetchGlobalBlockedApplication,
  deleteGlobalBlockedApplication,
  AddGlobalBlockedApp,
  fetchApplicationFromCategoryId
};

export default function NoRecordsFound({height,width}) {
  return (
    <svg
      width={width ? width : "270"}
      height={height ? height : "270"}
      viewBox="0 0 300 300"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M144.597 263C207.978 263 259.359 212.184 259.359 149.5C259.359 86.8157 207.978 36 144.597 36C81.2156 36 29.835 86.8157 29.835 149.5C29.835 212.184 81.2156 263 144.597 263Z"
        fill="url(#paint0_linear_365_7306)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M243.613 221.851C244.658 221.483 245.759 220.993 246.608 220.284C247.616 219.442 248.025 218.357 248.301 217.215C248.654 215.748 248.795 214.185 249.224 212.716C249.383 212.17 249.688 211.964 249.819 211.872C250.15 211.641 250.485 211.579 250.8 211.602C251.173 211.629 251.686 211.769 252.023 212.387C252.071 212.476 252.134 212.611 252.176 212.796C252.206 212.931 252.226 213.355 252.259 213.53C252.34 213.96 252.408 214.391 252.471 214.823C252.684 216.264 252.806 217.487 253.476 218.81C254.385 220.607 255.296 221.706 256.532 222.193C257.727 222.663 259.156 222.575 260.981 222.206C261.155 222.164 261.327 222.128 261.497 222.099C262.301 221.96 263.071 222.483 263.229 223.276C263.387 224.068 262.875 224.84 262.078 225.014C261.911 225.051 261.747 225.085 261.585 225.117C259.118 225.721 256.263 227.876 254.603 229.763C254.092 230.345 253.343 231.972 252.579 233.01C252.015 233.776 251.382 234.28 250.85 234.459C250.493 234.579 250.193 234.56 249.945 234.5C249.584 234.412 249.285 234.219 249.055 233.911C248.93 233.743 248.814 233.518 248.759 233.23C248.732 233.092 248.729 232.74 248.73 232.58C248.574 232.051 248.384 231.534 248.245 230.999C247.914 229.724 247.264 228.917 246.492 227.85C245.77 226.852 244.995 226.225 243.858 225.725C243.711 225.689 242.517 225.4 242.096 225.234C241.481 224.991 241.187 224.584 241.081 224.365C240.9 223.993 240.881 223.667 240.917 223.396C240.971 222.996 241.153 222.654 241.475 222.378C241.674 222.206 241.972 222.039 242.371 221.957C242.68 221.894 243.498 221.857 243.613 221.851ZM250.612 219.773C250.667 219.895 250.726 220.018 250.788 220.141C252.12 222.772 253.61 224.241 255.42 224.954L255.481 224.977C254.27 225.866 253.173 226.86 252.335 227.813C251.99 228.205 251.534 229.02 251.04 229.856C250.592 228.415 249.859 227.396 248.937 226.121C248.232 225.148 247.494 224.415 246.587 223.818C247.291 223.461 247.962 223.043 248.547 222.554C249.521 221.74 250.165 220.797 250.612 219.773Z"
        fill="#E3E3E3"
      />
      <ellipse
        cx="14.4352"
        cy="127.382"
        rx="5.43521"
        ry="5.37545"
        fill="#E3E3E3"
      />
      <rect
        x="91.6318"
        y="94.8867"
        width="197.556"
        height="70.4335"
        rx="24"
        fill="black"
      />
      <rect
        x="173.181"
        y="119.134"
        width="66.6179"
        height="9.23718"
        rx="4.61859"
        fill="#0065FF"
      />
      <rect
        x="108.861"
        y="132.99"
        width="131.274"
        height="9.23718"
        rx="4.61859"
        fill="white"
      />
      <ellipse
        cx="263.919"
        cy="130.681"
        rx="11.4858"
        ry="11.5465"
        fill="#CEE5FF"
      />
      <rect
        x="9"
        y="153.774"
        width="197.556"
        height="70.4335"
        rx="24"
        fill="#CEE5FF"
      />
      <rect
        x="58.3892"
        y="178.021"
        width="66.6179"
        height="9.23718"
        rx="4.61859"
        fill="black"
      />
      <rect
        x="58.3892"
        y="191.877"
        width="131.274"
        height="9.23719"
        rx="4.61859"
        fill="white"
      />
      <ellipse
        cx="37.7146"
        cy="189.568"
        rx="11.4858"
        ry="11.5465"
        fill="#0065FF"
      />
      <defs>
        <linearGradient
          id="paint0_linear_365_7306"
          x1="143.712"
          y1="-0.958387"
          x2="146.012"
          y2="397.637"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F2F2F2" />
          <stop offset="1" stopColor="#EFEFEF" />
        </linearGradient>
      </defs>
    </svg>
  );
}

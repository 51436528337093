import React, { useEffect, useState } from "react";
import Popper from "@mui/material/Popper";
import Fade from "@mui/material/Fade";
import Paper from "@mui/material/Paper";
import Chip from "@mui/material/Chip";

const CustomBadge = ({ row, heading, maxChips, onClickHandler }) => {
  if (!maxChips) maxChips = 1;
  const [anchorEl, setAnchorEl] = useState(null);
  const [popperOpen, setPopperOpen] = useState(false);

  const viewMore = (event) => {
    if (row[heading].length > maxChips) {
      setAnchorEl(event.currentTarget);
      setPopperOpen(popperOpen ? null : row[heading]);
    }
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popperOpen && !anchorEl.contains(event.target)) {
        setPopperOpen(null);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [popperOpen, anchorEl]);

  const styles = {
    backgroundColor: "#ebf1ff",
    color: "#62625f",
    fontWeight: "500",
    paddingLeft: "3px",
    paddingRight: "3px",
    borderRadius: "5px"
  };

  return (
    <>
      <Popper
        open={Boolean(popperOpen)}
        anchorEl={anchorEl}
        placement="right"
        modifiers={[
          {
            offset: {
              enabled: true,
              offset: "10, 8"
            }
          }
        ]}
        transition
        style={{ zIndex: 9999 }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper className="all-chips">
              {row[heading].map((field) => (
                <Chip
                  key={field.id}
                  label={field.name}
                  style={{
                    marginTop: 4,
                    marginBottom: 10,
                    ...styles
                  }}
                  size="small"
                  variant="filled"
                  onClick={() => onClickHandler(field.id)}
                />
              ))}
            </Paper>
          </Fade>
        )}
      </Popper>
      <div className="chips">
        {row[heading].slice(0, maxChips).map((item, index) => (
          <Chip
            key={index}
            label={item.name}
            size="small"
            variant="filled"
            sx={{
              marginRight: ".3rem",
              ...styles
            }}
            onClick={() => onClickHandler(item.id)}
            style={{
              cursor:
                onClickHandler === undefined
                  ? "default"
                  : "pointer"
            }}

          />
        ))}
        {row[heading].length > maxChips ? (
          <Chip
            label={`+${row[heading].length - maxChips}`}
            size="small"
            variant="filed"
            onClick={viewMore}
            sx={styles}
          />
        ) : null}
      </div>
    </>
  );
};

export default CustomBadge;
